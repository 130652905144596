import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Session} from '../services/session';
import {PersonaModuleApi} from "../persona/persona-module-api";
import {Router} from "@angular/router";

@Injectable()
export class CustomerModuleApi {

  constructor(private session: Session, private personaModuleApi: PersonaModuleApi, private router: Router) {
  }

  /**
   * wie sollen die Termine dargestellt werden
   * @return Observable<string> list|day
   */
  public getAppointmentLayout$(): Observable<string> {
    return this.session.customerLayout$;
  }

  /**
   * wie sollen die Termine dargestellt werden
   * @return string list|day
   *
   * TODO: irgendwie, wenn es geht weg bekommen und nur noch mit dem observable arbeiten
   */
  public getAppointmentLayout(): string {
    return this.session.getCustomerAppointmentLayout();
  }

  /**
   *
   * @param layout possible values: list|day
   */
  public setAppointmentLayout(layout: string) {
    this.session.setCustomerAppointmentLayout(layout);
  }

  login(token: string) {
    this.session.login(token);
    this.personaModuleApi.reloadPersonas();
    if (this.session.hasDeepLink()) {
      this.handleDeepLink();
    } else {
      this.handleServiceProvider();
    }
  }

  private handleDeepLink() {
    let deepLink = this.session.deepLink;
    this.session.removeDeepLink();
    this.router.navigateByUrl(deepLink);
  }

  private handleServiceProvider() {
    if (this.session.hasServiceProdiver()) {
      this.router.navigate(["/wods"]);
    } else {
      this.router.navigate(["/service-provider-select"]);
    }
  }

}
