import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {CustomerActions} from "./action-types";
import {catchError, map, mergeMap} from "rxjs/operators";
import {ApiActions} from "../../actions/action-types";
import {EMPTY, of} from "rxjs";
import {UserApi} from "../../services/user-api.service";

@Injectable()
export class CustomerEffects {
  constructor(private actions$: Actions, private userApi: UserApi) {
  }

  loadNotificationSettings = createEffect(() => this.actions$.pipe(
    ofType(CustomerActions.loadNotificationSettings),
    mergeMap(() => {
      return this.userApi.getNotificationSettings().pipe(
        map((configurations) => ApiActions.notificationSettingsLoaded({configurations: configurations})),
        catchError(() => EMPTY)
      )
    })
  ));

  changeNotificationSetting = createEffect(() => this.actions$.pipe(
    ofType(CustomerActions.changeNotificationSetting),
    mergeMap((action) => {
      return this.userApi.updateNotificationSetting(action.key, action.mail, action.telegram).pipe(
        map(() => ApiActions.notificationSettingChanged({key: action.key, mail: action.mail, telegram: action.telegram})),
        catchError(() => EMPTY)
      )
    })
  ));

  changeName = createEffect(() => this.actions$.pipe(
    ofType(CustomerActions.changeName),
    mergeMap((action) => {
      return this.userApi.changeName(action.personaId, action.firstname, action.lastname).pipe(
        map(() => ApiActions.changeNameSuccess({firstname: action.firstname, lastname: action.lastname})),
        catchError((error) => of(ApiActions.changeNameError({error: error})))
      )
    })
  ));

  loadName = createEffect(() => this.actions$.pipe(
    ofType(CustomerActions.loadName),
    mergeMap((action) => {
      return this.userApi.loadName(action.personaId).pipe(
        map(it => ApiActions.loadNameSuccess({firstname: it.firstname, lastname: it.lastname})),
        catchError((error) => of(ApiActions.loadNameError({error: error})))
      )
    })
  ));

  loadFeeds = createEffect(() => this.actions$.pipe(
    ofType(
      CustomerActions.loadFeeds,
      ApiActions.resetIcalFeedSuccess
    ),
    mergeMap(() => {
      return this.userApi.loadFeeds().pipe(
        map(feeds => ApiActions.loadFeedsSuccess({feeds: feeds})),
        catchError((error) => of(ApiActions.loadFeedsError({error: error})))
      )
    })
  ));

  resetIcalFeed = createEffect(() => this.actions$.pipe(
    ofType(CustomerActions.resetIcalFeed),
    mergeMap(() => {
      return this.userApi.resetIcalFeed().pipe(
        map(() => ApiActions.resetIcalFeedSuccess()),
        catchError((error) => of(ApiActions.resetIcalFeedError({error: error})))
      )
    })
  ));


}
