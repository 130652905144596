import * as Sentry from "@sentry/browser";

export class ApiError {
  /**
   * Fehlercodes nach dem Muster ala error.subscription.no_contract
   */
  _code: string;

  constructor(private code: string) {
    this._code = code;
  }

  public getDisplayMessage(): string {
    return this._code2Message();
  }

  private _code2Message() {
    switch (this._code) {
      case "error.subscription":
        return "Anmeldung nicht möglich. (unbekannter Grund)";
      case "error.subscription.no_contract":
        return "Du hast keinen gültigen Vertrag.";
      case "error.subscription.customer_not_assigned":
        return "Keine Kundenbeziehung";
      case "error.subscription.before_opening":
        return "Anmeldung nicht möglich, da Anmeldezeit noch nicht gestartet ist.";
      case "error.subscription.after_closing":
        return "Anmeldung nicht möglich, da Anmeldezeit abgelaufen ist.";
      case "error.subscription.too_many_participants":
        return "Anmeldung nicht möglich, da Termin bereits voll";
      case "error.unsubscription.not_subscribed":
        return "Abmeldung nicht möglich, da Du nicht angemeldet bist.";
      case "error.unsubscription.before_opening":
        return "Abmeldung nicht möglich, Anmeldezeit noch gar nicht gestartet";
      case "error.unsubscription.after_closing":
        return "Abmeldung nicht möglich, da die Frist bereits abgelaufen ist.";
      case "error.wodlist.loading":
        return "Beim laden der Termine ist ein Fehler aufgetreten.";
      case "session.invalid":
        return "Deine Session ist ungültig oder abgelaufen. Bitte melde Dich erneut an.";
      case "authentication.data.wrong":
        return "Deine Zugangsdaten sind falsch.";
      case "network.communication.error":
        return "Bei der Kommunikation mit dem Server trat ein unbekannter Fehler auf.";
      case "error.subscription.no_contingent":
        return "Das Kontingent ist aufgebraucht, so dass Du diesen Termin nicht mehr buchen kannst.";
      case "error.subscription.timeslot_canceled":
        return "Der Termin ist bereits abgesagt.";
      case "error.subscription.temporary_disabled":
        return "Die Möglichkeit für Terminbuchungen wurde vorübergehend vom Anbieter deaktiviert.";

      case "error.waiting_list.already_subscribed":
        return "Du befindest Dich bereits auf der Warteliste.";
      case "error.waiting_list.already_participant":
        return "Du bist bereits Teilnehmer an dem Termin.";
      case "error.waiting_list.not_subscribed":
        return "Du bist nicht auf der Warteliste.";

      case "error.password_change.token.too_old":
        return "Dieser Link zum ändern des Passwort ist leider nicht mehr gültig.";
      case "error.password_change.token.invalid":
        return "Dieser Link zum ändern des Passwort ist ungültig.";
      case "error.password_change.previous_password.wrong":
        return "Das aktuelle Passwort stimmt nicht.";
      case "error.password_change.new_password.invalid":
        return "Dein neues Passwort darf nicht leer sein.";

      case "error.email_change":
        return "Allgemeiner Fehler beim Ändern der E-Mail-Adresse";
      case "error.email_change.email_taken":
        return "Diese E-Mail-Adresse wird bereits von anderem Nutzer verwendet.";
      case "error.email_change.email_invalid":
        return "Die E-Mail-Adresse ist ungültig."
      case "error.email_change.token.invalid":
        return "Das Token ist ungültig.";
      case "error.email_change.token.too_old":
        return "Das Token ist zu alt.";

      case "error.email_confirmation.attributes.missing_mandatory":
        return "Du hast mindestens ein Pflichtfeld nicht angegeben.";

      case "error.providers.notfound":
        return "Der gewünschte Dienstleister wurde nicht gefunden.";
      case "error.providers.loading":
        return "Beim laden der verfügbaren Dienstleister trat ein Fehler auf.";

      case "error.attributes.invalid_value":
        return "Du hast mindestens ein ungültigen Wert eingegeben."
      case "error.attributes.mandatory_attribute_missing":
        return "Du hast mindestens ein Pflichtfeld nicht angegeben."

       case "error.delete_persona.in_use":
        return "Die Persona kann nicht gelöscht werden, das sie noch aktuelle Buchungen hat."
      case "error.delete_persona.last_persona":
        return "Die Persona kann nicht gelöscht werden, da sie die einzige für diesen Account ist."

      case "error.delete_account.subscriptions_present":
        return "Du hast noch offene Buchungen. Bitte storniere diese, damit Du dein Konto löschen kannst."
      case "error.delete_account.wrong_password":
        return "Das Passwort ist falsch. Bitte versuche es erneut."

      case "error.instructor.missing_name":
        return "Um Dich als Trainer anmelden zu können, musst Du in den Einstellungen deinen Namen hinterlegen.";
      case "error.instructor.instructor_limit_reached":
        return "Die maximale Anzahl an Trainern für diesen Termin wurde bereits erreicht.";
      case "error.instructor.timeslot_canceled":
        return "Der Termin wurde bereits abgesagt.";
      case "error.instructor.no_instructor":
        return "Du bist kein Trainer für diesen Anbieter.";

      default:
        this.sendSentryEvent("der terminus-error-code wurde nicht behandelt.", this._code);
        return "Es trat ein unbekannter Fehler auf. (" + this._code + ")";
    }
  }

  private sendSentryEvent(message: string, code: string) {
    Sentry.setExtra("message", message)
    Sentry.setExtra("header.x-terminus-error", code)
    Sentry.captureException(new Error(`message: ${message}`));
  }

}
