<div class="section">
  <div class="header">Konto löschen</div>
  <div class="body">
    <div>
      Hiermit löscht Du Dein Kundenkonto bei Zeitfrei. Danach ist keine weitere Anmeldung mit Deinem Zugang möglich.
    </div>

    <div>
      Eventuelle Vertragsbeziehungen oder Namensnennungen bei Dienstleistern/Vereinen aufgrund zurückliegender Terminbuchungen bleiben bestehen.
    </div>

    <div>
      Der Vorgang kann nicht rückgängig gemacht werden, Du kannst aber jederzeit wieder ein neues Konto anlegen.
    </div>

    <form [formGroup]="form">
      <mat-form-field appearance="outline">
        <mat-label>Passwort</mat-label>
        <input matInput type="password" formControlName="password" required>
      </mat-form-field>
    </form>

    <div class="actions">
      <button mat-stroked-button color="primary" routerLink=".." >Abbrechen</button>
      <button mat-stroked-button color="warn" (click)="removeAccount()" [disabled]="form.invalid">Konto löschen</button>
    </div>
  </div>
</div>

