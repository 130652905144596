import {Component, OnInit} from '@angular/core';
import {CustomerModuleApi} from "../../customer/customer-module-api";
import {Observable} from "rxjs";
import {Store} from "@ngxs/store";
import {AppointmentActions} from "../state/appointment.actions";
import * as moment from "moment";
import {AppointmentState} from "../state/appointment.state";
import DaySelected = AppointmentActions.DaySelected;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public layout$: Observable<string>;
  showSectionSelection$: Observable<boolean>;

  constructor(private store: Store, private customerModuleApi: CustomerModuleApi) {
    this.layout$ = this.customerModuleApi.getAppointmentLayout$();
    this.showSectionSelection$ = this.store.select(AppointmentState.needSectionSelection);
  }

  ngOnInit(): void {
    this.store.dispatch(new DaySelected(moment()))
  }
}
