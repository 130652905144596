import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {UserApi} from "../services/user-api.service";
import {filter, map} from "rxjs/operators";
import {ApiError} from "../services/error";
import {AppActions} from "../actions/action-types";
import {AppState} from "../actions";
import {Store} from "@ngrx/store";
import {MessageService} from "../shared/message.service";

@Component({
  selector: 'app-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.scss']
})
export class PasswordChangeComponent {
  form: FormGroup;
  token: string;
  hide = true;

  private formData: { password: string };
  constructor(fb: FormBuilder, private _router: Router, private api: UserApi,
              private messageService: MessageService, private _route: ActivatedRoute, private store: Store<AppState>) {
    this.form = fb.group(
      {
        password: ['', [Validators.required]]
      }
    )

    this.form.valueChanges
      .pipe(
        filter(() => this.form.valid && this.token != ''),
        map(value => {
          return {password: value.password}
        }),
      )
      .subscribe(
        formData => {
          this.formData = formData;
        }
      );

    this._route.paramMap.subscribe(params => {
      this.token = params.get('token');
    });
  }

  submit() {
    this.api.changePasswordWithToken(this.formData.password, this.token)
      .subscribe(
        () => {
          this.onSuccess();
        },
        (error: ApiError) => {
          this.onFailure(error);
        }
      );
  }

  onSuccess() {
    this.messageService.showSuccess("Dein Passwort wurde erfolgreich geändert.");
    this._router.navigate(["/login"]);
  }

  onFailure(error: ApiError) {
    this.store.dispatch(AppActions.logout());
    this.messageService.showError(error.getDisplayMessage());
  }
}
