import {Component, Input} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {UserAttribute} from "../../../../../model/user-attribute";

@Component({
  selector: 'attribute-boolean',
  templateUrl: './attribute-boolean.component.html',
  styleUrls: ['./attribute-boolean.component.scss']
})
export class AttributeBooleanComponent {
  @Input() attribute: UserAttribute;
  @Input() form: FormGroup;
  @Input() disabled: boolean
  @Input() readonly: boolean
  value: string;
}
