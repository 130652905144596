<ng-container *ngIf="!readonly">
  <div [formGroup]="form">
    <mat-form-field appearance="outline">
      <mat-label>{{attribute.name}}</mat-label>
      <input matInput [formControlName]="attribute.identifier" [required]="attribute.mandatory">
    </mat-form-field>
  </div>
</ng-container>

<ng-container *ngIf="readonly">
  <div class="line">
    <div class="label">{{attribute.name}}</div>
    <div class="value"></div>
  </div>
</ng-container>
