import {Session} from "./session";
import {Router} from "@angular/router";
import {HttpClient, HttpErrorResponse, HttpHeaders} from "@angular/common/http";
import {throwError} from "rxjs";
import {ApiError} from "./error";
import * as Sentry from "@sentry/browser";
import {AppState} from "../actions";
import {Store} from "@ngrx/store";
import {ApiActions} from "../actions/action-types";

export class TerminusApi {
  constructor(protected http: HttpClient, protected session: Session,
              protected router: Router, protected store: Store<AppState>) {
  }

  getHeaders() {
    let headers = new HttpHeaders();
    headers = headers
      .append("X-Auth-Token", this.session.getApiToken())
      .append("X-Requested-With", "XMLHttpRequest");
    return headers;
  }

  getApiToken(): string {
      return this.session.getApiToken();
  }

  handleError(error: HttpErrorResponse, isLoginRequest = false) {
    if (error.status == 401) {
      if (isLoginRequest == false) {
        this.store.dispatch(ApiActions.invalidSession())
        return throwError(new ApiError("session.invalid"));
      } else {
        this.store.dispatch(ApiActions.wrongAuthenticationData())
        return throwError(new ApiError("authentication.data.wrong"));
      }
    }

    if ((error.status == 418) || error.headers.has("X-terminus-error") || error.headers.has("x-terminus-error")) {
      let code = error.headers.get("X-terminus-error");
      return throwError(new ApiError(code));
    }

    this.sendSentryEvent("network.communication.error", error);
    return throwError(new ApiError("network.communication.error"));
  }

  private sendSentryEvent(message: string, error: HttpErrorResponse) {
    Sentry.setExtra("message", message)
    Sentry.setExtra("error.message", error.message)
    Sentry.setExtra("error.statusText", error.statusText)
    Sentry.setExtra("error.status", error.status)
    Sentry.setExtra("header.x-terminus-error", error.headers.get("x-terminus-error"))
    Sentry.captureException(new Error(`message: ${error.message}`));
  }
}
