<ng-container *ngIf="!(showSectionSelection$ | async)">
  <app-teaser></app-teaser>
  <ns-wod-list *ngIf="(layout$ | async) == 'list'"></ns-wod-list>
  <app-day-view *ngIf="(layout$ | async) == 'day'"></app-day-view>
</ng-container>

<ng-container *ngIf="showSectionSelection$ | async">
<!--  {{COMMON.SECTION | translate}} auswählen-->
  <app-section-list></app-section-list>
</ng-container>
