import {Component, OnInit} from '@angular/core';
import {NewsService} from "../news.service";
import {NewsMessage} from "../model/newsMessage";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  news: NewsMessage[];

  constructor(private newsService: NewsService) { }

  ngOnInit(): void {
    this.news = this.newsService.getMessages();
    this.newsService.markAllMessagesAsRead();
  }
}
