import {Component, OnDestroy, OnInit} from "@angular/core";

import {Observable, Subscription} from "rxjs";
import {map} from "rxjs/internal/operators";
import {Wod} from "../model/wod";
import {FormControl} from "@angular/forms";
import {AppointmentActions} from "../state/appointment.actions";
import LoadResources = AppointmentActions.LoadResources;
import {Store} from "@ngxs/store";
import {AppointmentState} from "../state/appointment.state";
import FilterChanged = AppointmentActions.FilterChanged;
import LoadAppointments = AppointmentActions.LoadAppointments;
import LoadMoreAppointments = AppointmentActions.LoadMoreAppointments;

@Component({
  selector: "ns-wod-list",
  styleUrls: ['./wod-list.component.scss'],
  templateUrl: "./wod-list.component.html"
})
export class WodListComponent implements OnInit, OnDestroy {
  wodsPerDay$ = new Observable<{ day: String, wods: Wod[] }[]>();
  loading$: Observable<boolean>;

  filter = new FormControl();

  resource = new FormControl();
  public resources$: Observable<{ id: string; label: string, displayname: string }[]>;
  private subscriptions: Subscription[] = [];

  constructor(private store: Store) {
    this.loading$ = this.store.select(AppointmentState.loading);

    this.subscriptions.push(
      this.store.select(AppointmentState.persona).subscribe(it =>
        this.wodsPerDay$ = this.store
          .select(AppointmentState.filteredAppointments)
          .pipe(
            map(filterFn => filterFn(it)),
            map(it => this.groupAppointmentsPerDay(it))
          ))
    );

    this.resources$ = this.store.select(AppointmentState.resources);
    this.resource.setValue('');
    this.resource.valueChanges.subscribe(() => this.store.dispatch(new FilterChanged(this.filter.value, this.resource.value, '')));

    this.filter.setValue('');
    this.filter.valueChanges.subscribe(() => this.store.dispatch(new FilterChanged(this.filter.value, this.resource.value, '')));
  }

  ngOnInit(): void {
    this.store.dispatch(new LoadResources());
    this.store.dispatch(new LoadAppointments());
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(it => it.unsubscribe());
  }


  reloadWods() {
    this.store.dispatch(new LoadAppointments());
  }

  private groupAppointmentsPerDay(it: Wod[]) {
    const groupKey = "dd, DD.MM.YYYY";
    let days = new Set<String>(it.map(item => item.start.format(groupKey)));
    let wodsPerDay: { day: String, wods: Wod[] }[] = [];
    days.forEach(day => {
      return wodsPerDay.push({
          day: day,
          wods: it.filter(i => i.start.format(groupKey) == day)
        }
      );
    })

    return wodsPerDay;
  }

  more() {
    this.store.dispatch(new LoadMoreAppointments())
  }
}
