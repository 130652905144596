<div class="section" *ngIf="(status$ | async) == 'formular'">
  <div class="header">Konto aktivieren</div>
  <div class="body">
    <ng-container ngSwitch="isRegisterForServiceProvider">
      <app-public-confirmation-formular *ngIf="!isRegisterForServiceProvider" (activate)="this.activate($event)"></app-public-confirmation-formular>
      <app-sp-confirmation-formular *ngIf="isRegisterForServiceProvider" [serviceProvider]="serviceProvider" [userAttributes]="userAttributes" [onlyRequestAttributes]="userExists" (activate)="this.activate($event)"></app-sp-confirmation-formular>
    </ng-container>
  </div>
</div>

<div class="section" *ngIf="(status$ | async) == 'invalid_link'">
  <div class="header error">Konto aktivieren</div>
  <div class="body">
    <p>Du hast versucht einen Aktivierungslink zu öffnen. Leider ist dieser ungültig.</p>
    <p>Bitte prüfe, ob Du den kompletten Link kopiert hast. Wenn ja dann wende Dich bitte an uns,
      damit wir Dir helfen können.</p>
  </div>
  <div class="actions">
    <a routerLink="/login" mat-stroked-button color="primary">Zum Login</a>
  </div>
</div>

<div class="section" *ngIf="(status$ | async) == 'request'">
  <div class="header">Konto aktivieren</div>
  <div class="body">
    <p>Wir aktivieren jetzt Dein Konto.</p>
  </div>
</div>


<div class="section" *ngIf="(status$ | async) == 'error'">
  <div class="header">Konto aktivieren</div>
  <div class="body">
    <p>Leider trat beim aktivieren Deines Kontos ein Fehler auf.</p>
    <p>Versuche es doch bitte erneut. Sollte der Fehler weiterhin auftreten, so teile uns dies
      bitte mit damit wir Dir so schnell wie möglich helfen können.</p>
  </div>
</div>

