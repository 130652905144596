import {Component, OnInit} from '@angular/core';
import {Api} from "../../state/api";
import {MessageService} from "../../../shared/message.service";
import {AppointmentState} from "../../state/appointment.state";
import {Store} from "@ngxs/store";
import {Observable} from "rxjs";
import {Wod} from "../../model/wod";
import {Attachment} from "../../state/attachment";

@Component({
  selector: 'app-ts-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss']
})
export class AttachmentsComponent implements OnInit {
  public timeslot$: Observable<Wod>;

  constructor(private api: Api, private messageService: MessageService, private store: Store) {
    this.timeslot$ = this.store.select(AppointmentState.currentAppointment);
  }

  ngOnInit(): void {
  }

  download(attachment: Attachment) {
    return this.api.getAttachmentContent(attachment.id)
      .subscribe((response: any) => {
        let dataType = response.type;
        let binaryData = [];
        binaryData.push(response);
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
        downloadLink.setAttribute('download', attachment.name);
        document.body.appendChild(downloadLink);
        downloadLink.click();
      }, error => {
        this.messageService.showError("Fehler beim Download des Anhang.")
      });
  }
}
