import { Component } from '@angular/core';
import {Store} from "@ngxs/store";
import {Persona} from "../model/persona";
import {PersonaActions} from "../state/persona.actions";
import {PersonaState} from "../state/persona.state";
import {Observable} from "rxjs";
import {MatBottomSheetRef} from "@angular/material/bottom-sheet";

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent {
  personas$: Observable<Persona[]>;

  constructor(private store: Store, private _bottomSheetRef: MatBottomSheetRef<SelectComponent>) {
    this.personas$ = this.store.select(PersonaState.personas);
  }

  select(persona: Persona, event: MouseEvent): void {
    this.store.dispatch(new PersonaActions.PersonaSelected(persona))
    event.preventDefault();
    this._bottomSheetRef.dismiss();

  }

  adminPersonas() {
    this._bottomSheetRef.dismiss();
  }

  signature(persona: Persona) {
    let fnLetter = (persona.firstname?.length > 0) ? persona.firstname[0].toUpperCase() : '';
    let lnLetter = (persona.lastname?.length > 0) ? persona.lastname[0].toUpperCase() : '';

    let r = fnLetter+lnLetter;
    return r.trim();
  }
}
