import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CustomerRoutingModule} from "./customer-routing.module";
import {ChangeMailComponent} from './change-mail/change-mail.component';
import {SettingsComponent} from './settings/settings.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {StoreModule} from "@ngrx/store";
import {EffectsModule} from "@ngrx/effects";

import * as fromCustomer from './actions/customer.reducer';
import {CustomerEffects} from "./actions/customer.effects";
import {NotificationsComponent} from './notifications/notifications.component';
import {NotificationItemComponent} from './notifications/notification-item/notification-item.component';
import {IcalFeedComponent} from './ical-feed/ical-feed.component';
import {ClipboardModule} from "@angular/cdk/clipboard";
import {AppMaterialModule} from "../material.module";
import {ChangeNameComponent} from './change-name/change-name.component';
import {LayoutComponent} from './layout/layout.component';
import {CustomerModuleApi} from "./customer-module-api";
import {ContractComponent} from './contract/contract.component';
import {CustomerApi} from "./contract/api";
import {RemoveAccountComponent} from './remove-account/remove-account.component';
import {ChangePasswordComponent} from "./update-password/dump/change-password/change-password.component";
import {SetPasswordComponent} from "./update-password/dump/set-password/set-password.component";
import {UpdatePasswordPageComponent} from "./update-password/update-password-page/update-password-page.component";
import {SpConfirmationFormularComponent} from './invitation/confirm-email/container/sp-confirmation-formular/sp-confirmation-formular.component';
import {PublicConfirmationFormularComponent} from './invitation/confirm-email/container/public-confirmation-formular/public-confirmation-formular.component';
import {InvitationUserAttributesComponent} from './invitation/confirm-email/container/invitation-user-attributes/invitation-user-attributes.component';
import {AttributeStringComponent} from "./invitation/confirm-email/container/attributes/string/attribute-string.component";
import {AttributeEnumComponent} from "./invitation/confirm-email/container/attributes/list/attribute-enum.component";
import {AttributeDateComponent} from "./invitation/confirm-email/container/attributes/date/attribute-date.component";
import {AttributeBooleanComponent} from "./invitation/confirm-email/container/attributes/boolean/attribute-boolean.component";
import {AttributeAgreementComponent} from "./invitation/confirm-email/container/attributes/agreement/attribute-agreement.component";

@NgModule({
  declarations: [ChangeMailComponent, SettingsComponent, NotificationsComponent,
    NotificationItemComponent, IcalFeedComponent, ChangeNameComponent,
    ChangePasswordComponent, SetPasswordComponent, UpdatePasswordPageComponent,
    LayoutComponent,
    ContractComponent,
    RemoveAccountComponent,
    SpConfirmationFormularComponent,
    PublicConfirmationFormularComponent,
    InvitationUserAttributesComponent,
    AttributeStringComponent, AttributeEnumComponent, AttributeDateComponent, AttributeBooleanComponent, AttributeAgreementComponent],
  imports: [
    CommonModule,
    CustomerRoutingModule,
    AppMaterialModule,
    ReactiveFormsModule,
    StoreModule.forFeature(fromCustomer.featureKey, fromCustomer.reducer),
    EffectsModule.forFeature([CustomerEffects]),
    FormsModule,
    ClipboardModule,
  ],
  exports: [
    SpConfirmationFormularComponent,
    PublicConfirmationFormularComponent,
    InvitationUserAttributesComponent
  ],
  providers: [
    CustomerModuleApi,
    CustomerApi
  ]
})
export class CustomerModule {
}
