<ng-container *ngIf="timeslot$ | async as timeslot">
  <ng-container *ngIf="!timeslot.informationalOnly">
    <ng-container *ngIf="personaId$ | async as personaId">
      <div class="detail-section">

        <div class="line">
          <div class="label">Status</div>
          <div class="value">
            <span *ngIf="timeslot.isNotSubscribed(personaId)">nicht gebucht</span>
            <span *ngIf="timeslot.isRequested(personaId)">Deine Anmeldung wartet auf die Bestätigung durch einen Verantwortlichen. Bitte verwende für eventuelle Zahlungen die Buchungsreferenz.</span>
            <span *ngIf="timeslot.isConfirmed(personaId)" class="subscribed">gebucht</span>
          </div>
        </div>

        <div class="line" [class.secondary]="timeslot.hasBookingCodeFor(personaId)" *ngIf="timeslot.hasBookingReferenceFor(personaId)">
          <div class="label">Buchungsreferenz</div>
          <div class="value" >{{timeslot.bookingReferenceFor(personaId)}}</div>
        </div>

        <div class="line" *ngIf="timeslot.isConfirmed(personaId) && timeslot.isBookingCodeSupported()">
          <div class="label">Buchungscode</div>
          <div class="value">{{timeslot.bookingCode(personaId)}}</div>
        </div>

        <div class="line" *ngIf="(timeslot.isConfirmed(personaId) || timeslot.isRequested(personaId))&& timeslot.isSubscriptionPersonsSupported()">
          <div class="label">gebuchte Personen</div>
          <div class="value">{{timeslot.subscriptionPersons(personaId)}}</div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
