<mat-form-field class="filter" appearance="outline">
  <input matInput (keyup)="applyFilter($event.target.value)" placeholder="mind. 3 Buchstaben (z.B. Halle)" autocomplete="off">
  <mat-icon matPrefix>search</mat-icon>
</mat-form-field>

<div class="no_filter" *ngIf="!hasFilterValue">Bitte gib mindestens 3 Buchstaben (z.B. Teile vom Namen, Ort oder der Strasse) für die Suche nach öffentlichen Anbietern ein.</div>
<div class="no_result" *ngIf="!searchResultsFound && hasFilterValue">Es wurden leider keine Ergebnisse gefunden.</div>

<app-service-provider-item *ngFor="let sp of searchResult$ | async"
                           [serviceProvider]="sp" [member]="isMember(sp)"
                           (selected)="select(sp)">
</app-service-provider-item>

<button *ngIf="this.hasRegisteredServiceProviders()" class="link_to_member mat-elevation-z3" mat-stroked-button routerLink="/service-provider-select">Mitgliedschaften anzeigen</button>


