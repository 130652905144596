import {Component, OnInit} from '@angular/core';
import {UserApi} from "../services/user-api.service";
import {ApiError} from "../services/error";
import {BehaviorSubject, Observable} from "rxjs";
import {ServiceProvider} from "../services/service-provider";
import {Session} from "../services/session";
import {ActivatedRoute, Router} from "@angular/router";
import {ServiceProviderModuleApi} from "../service-provider/service-provider-module-api";
import {PersonaModuleApi} from "../persona/persona-module-api";
import {MessageService} from "../shared/message.service";
import {AppointmentModuleApi} from "../appointment/appointment-module-api";

@Component({
  selector: 'app-service-provider-select',
  templateUrl: './service-provider-select.component.html',
  styleUrls: ['./service-provider-select.component.scss']
})
export class ServiceProviderSelectComponent implements OnInit {
  private _serviceProviders$ = new BehaviorSubject<ServiceProvider[]>([]);
  private _serviceProvidersCount = 0

  constructor(private _api: UserApi, private _session: Session,
              private _router: Router, private route: ActivatedRoute,
              private messageService: MessageService,
              private personaModuleApi: PersonaModuleApi,
              private serviceProviderModuleApi: ServiceProviderModuleApi,
              private appointmentModuleApi: AppointmentModuleApi) {
  }

  ngOnInit(): void {
    this.personaModuleApi.currentId().subscribe(personaId => this.loadServiceProviders(personaId));
  }

  private loadServiceProviders(personaId: string) {
    if (personaId == undefined)
      return;

    this._api.getRegisterdServiceProviders(personaId)
      .subscribe(
        serviceProviders => {
          if (serviceProviders.length == 0) {
            this._router.navigate(["/service-provider-search"]);
            return;
          }

          this._serviceProviders$.next(serviceProviders);
          this._serviceProvidersCount = serviceProviders.length
        },
        () => {
          this.onApiError(new ApiError("error.providers.loading"))
        }
      );
  }

  get serviceProviders$(): Observable<ServiceProvider[]> {
    return this._serviceProviders$.asObservable();
  }

  private onApiError(error: ApiError) {
    this.messageService.showError(error.getDisplayMessage());
  }

  select(sp: ServiceProvider) {
    this.serviceProviderModuleApi.setServiceProvider(sp);
    this.appointmentModuleApi.serviceProviderChanged(sp);
    this._router.navigate(["/ts"]);
  }
}
