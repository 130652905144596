import {Component, Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-message-layout',
  templateUrl: './message-layout.component.html',
  styleUrls: ['./message-layout.component.scss']
})
export class MessageLayoutComponent {
  public message: string;
  public action: string;

  constructor(@Inject(MAT_SNACK_BAR_DATA) data: any, public snackBar: MatSnackBar) {
    this.message = data.message;
    this.action = data.action;
  }

  dismiss() {
    this.snackBar.dismiss();
  }
}
