import {Component, Input, EventEmitter, Output} from '@angular/core';
import {Participant} from "../../model/participant";

@Component({
  selector: 'appointment-detail-participants',
  templateUrl: './participants.component.html',
  styleUrls: ['./participants.component.scss']
})
export class ParticipantsComponent {
  items: Participant[];
  @Input() set participants(items: Participant[]) {
    this.items = items;
  };
  @Output() participantSelected = new EventEmitter<Participant>();

  get displayedColumns(): string[] {
    if (this.containsCovid())
      // return ['name', 'covid', 'presence'];
      return ['name', 'covid'];
    else
      // return['name', 'presence'];
      return['name'];
  }

  containsCovid() {
    return (this.items.length > 0 && this.items[0].covid);
  }
}
