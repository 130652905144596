import { Component, OnInit } from '@angular/core';
import {Store} from "@ngxs/store";
import {AppointmentState} from "../../state/appointment.state";
import {Observable} from "rxjs";
import {AppointmentActions} from "../../state/appointment.actions";
import ReloadAppointmentsForSelectedDate = AppointmentActions.ReloadAppointmentsForSelectedDate;

@Component({
  selector: 'app-day-view',
  templateUrl: './day-view.component.html',
  styleUrls: ['./day-view.component.scss']
})
export class DayViewComponent implements OnInit {
  loading$: Observable<boolean>;

  constructor(private store: Store) {
    this.loading$ = this.store.select(AppointmentState.loading);
  }

  ngOnInit(): void {
  }

  reloadAppointments() {
    this.store.dispatch(new ReloadAppointmentsForSelectedDate());
  }
}
