import {Component, OnInit} from '@angular/core';
import {Session} from "../services/session";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {Observable} from "rxjs";
import {Store} from "@ngrx/store";
import {Store as NgxsStore} from "@ngxs/store";
import {AppState} from "../actions";
import {AppActions} from "../actions/action-types";
import {filter, map, mergeMap} from "rxjs/operators";
import {NewsApi} from "../news/news.api";
import {NewsMessage} from "../news/model/newsMessage";
import {CustomerModuleApi} from "../customer/customer-module-api";
import {environment} from '../../environments/environment';
import {NgxsAppActions} from "../actions/ngxs/app.actions";
import {RouterSelectors} from "../state/router-selectors";


@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss']
})
export class TopMenuComponent implements OnInit {

  isAuthenticated$: Observable<boolean>;
  serviceProviderName$: Observable<string>;
  title: string = null;
  heading: string = null;
  back: string;
  news$: Observable<NewsMessage[]>;
  showPersona: boolean = false;

  constructor(private session: Session,
              private router: Router, private store: Store<AppState>,
              private ngxsStore: NgxsStore,
              private activatedRoute: ActivatedRoute,
              private customerModuleApi: CustomerModuleApi,
              private newsApi: NewsApi) {
    ngxsStore.select(RouterSelectors.url).subscribe(
      it => {
        if (it?.startsWith('/service-provider-select')) {
          this.heading = 'Mitgliedschaften';
        } else if (it?.startsWith('/service-provider-search')) {
          this.heading = 'Öffentliche Dienstleister';
        } else if (it?.startsWith('/bookings')) {
          this.heading = 'Buchungen';
        } else {
          this.heading = null;
        }
      }
    );
  }

  ngOnInit(): void {
    this.isAuthenticated$ = this.session.isAuthenticated$.pipe();
    this.showPersona = environment.persona;
    this.serviceProviderName$ = this.session.serviceProviderName$.pipe(
      map(it => {
        return it;
        // return (it.length > 30) ? it.slice(0, 30) : it;
      })
    );

    this.news$ = this.newsApi.unreadNews().pipe();

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)
    ).subscribe(data => {
      if (data?.title) {
        this.title = data.title;
        this.back = data.back;
      }
      else {
        this.title = null;
        this.back = null;
      }
    });
  }

  logout() {
    this.store.dispatch(AppActions.logout());
    this.ngxsStore.dispatch(new NgxsAppActions.Logout());
    this.router.navigate(["/login"]);
  }

  switchToDayView() {
    this.customerModuleApi.setAppointmentLayout("day");
  }

  switchToListView() {
    this.customerModuleApi.setAppointmentLayout("list");
  }
}
