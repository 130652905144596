import {Injectable} from '@angular/core';
import {SwUpdate} from "@angular/service-worker";

@Injectable({providedIn: 'root'})
export class UpdateService {
  constructor(updates: SwUpdate) {

    updates.available.subscribe(event => {
      console.log('current version is', event.current);
      console.log('available version is', event.available);

      updates.activateUpdate().then(
        () => {
          console.log("activateUpdate success");
          document.location.reload()
        },
        () => {
          console.log("activateUpdate error");
        }
      );
    });

    updates.activated.subscribe(event => {
      console.log('old version was', event.previous);
      console.log('new version is', event.current);
    });
  }
}
