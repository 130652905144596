import {Component, Inject, Output, EventEmitter} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {TimeslotAttribute} from "../model/timeslot_attribute";

export interface BookingDetailDialogData {
  personen: number;
  maxAllowedPersons: number;
  isSubscriptionPersonsSupported: boolean
  attributes: TimeslotAttribute[];
}

@Component({
  selector: 'app-booking-detail-dialog',
  templateUrl: './booking-detail-dialog.component.html',
  styleUrls: ['./booking-detail-dialog.component.scss']
})
export class BookingDetailDialogComponent {
  form: FormGroup
  @Output() onOkClicked = new EventEmitter<{personen: number}>();

  constructor(
    public dialogRef: MatDialogRef<BookingDetailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BookingDetailDialogData,
    fb: FormBuilder) {
    this.form = fb.group({
      personen: [data.personen, [Validators.required, Validators.min(1), Validators.max(data.maxAllowedPersons)]],
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onOkClick() {
    this.onOkClicked.emit({personen: this.form.get('personen').value});
//    this.dialogRef.close({personen: this.form.get('personen').value})
  }
}
