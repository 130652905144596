import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {AppointmentActions} from "./appointment.actions";
import {tap} from "rxjs/operators";
import {IAppointment, Wod} from "../model/wod";
import {Resource} from "../model/resource";
import {Api} from "./api";
import * as moment from "moment";
import {Moment} from "moment";
import {MessageService} from "../../shared/message.service";
import {CustomerModuleApi} from '../../customer/customer-module-api';
import {ServiceProviderModuleApi} from "../../service-provider/service-provider-module-api";
import {AppointmentDetailActions} from "./appointment-detail.actions";
import {PersonaState} from "../model/persona-state";
import {PersonaActions} from "../../persona/state/persona.actions";
import FilterChanged = AppointmentActions.FilterChanged;
import AppointmentSelected = AppointmentActions.AppointmentSelected;
import DaySelected = AppointmentActions.DaySelected;
import LoadAppointmentsCountPerWeek = AppointmentActions.LoadAppointmentsCountPerWeek;
import DirectLinkToAppointment = AppointmentActions.DirectLinkToAppointment;
import AttributeValueChanged = AppointmentDetailActions.AttributeValueChanged;
import NumberOfPersonChanged = AppointmentDetailActions.NumberOfPersonChanged;
import AppointmentChanged = AppointmentDetailActions.AppointmentChanged;
import ApplyInstructor = AppointmentDetailActions.ApplyInstructor;
import UnapplyInstructor = AppointmentDetailActions.UnapplyInstructor;
import {Section} from "../model/section";
import SectionChanged = AppointmentActions.SectionChanged;
import ServiceProviderChanged = AppointmentActions.ServiceProviderChanged;

export interface AppointmentStateModel {
  appointments: Wod[];
  loadingAppointments: boolean;

  loadingCurrentAppointment: boolean;
  currentAppointment: IAppointment;

  resources: Resource[];
  sections: Section[];
  filter: { filter: string, resourceId: string, sectionId: string };
  start: Moment;
  end: Moment;

  //welche version soll in der Detailansicht gerendert werden
  detailDialogVersion: number;
  // in der Detailansicht wird gerade eine
  // Operation (subscribe, unsubscribe, add to waiting list, remove from waitinglist) ausgeführt
  detailWorking: boolean;
  numberOfPersons: number;
  personaId: string;


  // Eigenschaften für die DayView
  selectedDay: Moment;
  hasNextAppointment: boolean;
  loadingNextAppointment: boolean;

  // Day Bar
  perDay: Map<string, number>;
}

@State<AppointmentStateModel>({
  name: 'apoointments',
  defaults: {
    appointments: [],
    currentAppointment: null,

    loadingAppointments: false,
    loadingCurrentAppointment: false,

    filter: {filter: '', resourceId: '', sectionId: ''},
    resources: [],
    sections: [],
    start: moment(),
    end: moment(),

    detailDialogVersion: 1,
    detailWorking: false,
    numberOfPersons: 1,
    personaId: null,

    selectedDay: moment(),
    hasNextAppointment: true,
    loadingNextAppointment: false,
    perDay: new Map()
  }
})
@Injectable()
export class AppointmentState {
  private maxDateRangeInYears = 2;

  constructor(private api: Api, private messageService: MessageService,
              private customerModuleApi: CustomerModuleApi,
              private serviceProviderModuleApi: ServiceProviderModuleApi) {
  }

  @Selector()
  static appointments(state: AppointmentStateModel): Wod[] {
    return state.appointments;
  }

  @Selector()
  static selectedDay(state: AppointmentStateModel): Moment {
    return state.selectedDay;
  }

  @Selector()
  static detailWorking(state: AppointmentStateModel): boolean {
    return state.detailWorking;
  }

  @Selector()
  static detailDialogVersion(state: AppointmentStateModel): string {
    switch (state.currentAppointment.serviceProviderVariant) {
      case 'museum':
      case 'store':
        return 'v2'
      default:
        return 'v1';
    }
  }

  @Selector()
  static filteredAppointments(state: AppointmentStateModel) {
    return (personaId: string) : Wod[] => {
      let filteredItems = state.appointments;
      // return filteredItems;


      if (state.filter.filter !== '') {
        filteredItems = filteredItems
          .filter(i => {
            switch (state.filter.filter) {
              case 'bookable':
                return i.canSubscribe(personaId) || i.canSubscribeToWaitingList(personaId);
              case 'free':
                return i.canSubscribe(personaId);
              case 'booked':
                return i.isConfirmed(personaId) || i.isRequested(personaId) || i.isSubscribedOnWaitingList(personaId);
              default:
                return true;
            }
          });
      }

      let x = [];
      x.push(...filteredItems);

      return x.sort((a, b) => a.start?.unix() - b.start?.unix());
    }
  }

  @Selector()
  static resources(state: AppointmentStateModel): { id: string, label: string, displayname: string }[] {
    let result = state.resources.map(it => {
      return {
        id: it.id,
        label: it.label,
        displayname: (it.resourceGroup) ? it.resourceGroup.name + " / " + it.label : it.label
      }
    });

    result.sort((left, right) => left.displayname.localeCompare(right.displayname));
    return [].concat({id: '', label: 'alle', displayname: 'alle'}, result);
  }

  @Selector()
  static sections(state: AppointmentStateModel): Section[] {
    return state.sections;
  }

  @Selector()
  static selectedSection(state: AppointmentStateModel): string | null {
    return state.filter.sectionId;
  }

  @Selector()
  static needSectionSelection(state: AppointmentStateModel): boolean {
    return (state.sections.length > 0 && !state.filter.sectionId)
  }



  @Selector()
  static loading(state: AppointmentStateModel): boolean {
    return state.loadingAppointments;
  }

  @Selector()
  static currentAppointment(state: AppointmentStateModel): Wod {
    return Wod.fromModel(state.currentAppointment);
  }

  @Selector()
  static loadingCurrentAppointment(state: AppointmentStateModel): boolean {
    return state.loadingCurrentAppointment;
  }

  @Selector()
  static appointmentsPerDay(state: AppointmentStateModel): Map<string, number> {
    return state.perDay;
  }

  @Selector()
  static hasNextAppointment(state: AppointmentStateModel): boolean {
    return state.hasNextAppointment;
  }

  @Selector()
  static loadingNextAppointment(state: AppointmentStateModel): boolean {
    return state.loadingNextAppointment;
  }

  @Selector()
  static persona(state: AppointmentStateModel): string | undefined {
    return state.personaId;
  }

  @Action(AppointmentActions.DaySelected)
  selectDay(ctx: StateContext<AppointmentStateModel>, action: DaySelected) {
    if (ctx.getState().selectedDay.format('YYYY-MM-DD') === action.date.format('YYYY-MM-DD')) {
      return;
    }

    ctx.patchState({appointments: [], selectedDay: action.date});
    return this.loadAppointmentsForDate(ctx);
  }

  @Action(AppointmentActions.NextDaySelected)
  selectNextDay(ctx: StateContext<AppointmentStateModel>) {
    const nextDay = ctx.getState().selectedDay.clone().add(1, 'days');
    ctx.patchState({appointments: [], selectedDay: nextDay})
    return this.loadAppointmentsForDate(ctx);
  }

  @Action(AppointmentActions.PrevDaySelected)
  selectPrevDay(ctx: StateContext<AppointmentStateModel>) {
    const nextDay = ctx.getState().selectedDay.clone().add(-1, 'days');
    ctx.patchState({appointments: [], selectedDay: nextDay})
    return this.loadAppointmentsForDate(ctx);
  }

  @Action(AppointmentActions.ReloadAppointmentsForSelectedDate)
  reloadAppointmentsForSelectedDay(ctx: StateContext<AppointmentStateModel>) {
    return this.loadAppointmentsForDate(ctx);
  }

  @Action(AppointmentActions.NextWeekSelected)
  selectNextWeek(ctx: StateContext<AppointmentStateModel>) {
    let date = ctx.getState().selectedDay.clone().add(1, "week");
    if (date.isAfter(moment().add(this.maxDateRangeInYears, "years")))
      date = moment().add(this.maxDateRangeInYears, "years");

    if (ctx.getState().selectedDay.format("YYYY-MM-DD") == date.format("YYYY-MM-DD"))
      return;

    ctx.patchState({appointments: [], selectedDay: date})
    return this.loadAppointmentsForDate(ctx);
  }

  @Action(AppointmentActions.PrevWeekSelected)
  selectPrevWeek(ctx: StateContext<AppointmentStateModel>) {
    let date = ctx.getState().selectedDay.clone().subtract(1, "week");
    if (ctx.getState().selectedDay.format("YYYY-MM-DD") == date.format("YYYY-MM-DD"))
      return;

    ctx.patchState({appointments: [], selectedDay: date})
    return this.loadAppointmentsForDate(ctx);
  }

  @Action(AppointmentActions.LoadAppointmentsCountPerWeek)
  loadAppointmentsCountForWeek(ctx: StateContext<AppointmentStateModel>) {
    return this._loadAppointmentsCountForWeek(ctx);
  }

  private _loadAppointmentsCountForWeek(ctx: StateContext<AppointmentStateModel>) {
    let selectedDay = ctx.getState().selectedDay;
    const personaId = ctx.getState().personaId;
    let monday;
    let sunday;
    if (selectedDay.day() === 0) {
      monday = selectedDay.clone().add(-1, 'week').day(1);
      sunday = selectedDay.clone();
    } else {
      monday = selectedDay.clone().day(1);
      sunday = selectedDay.clone().add(1, 'week').day(0);
    }

    return this.api.getAppointmentsCountBetweenDates(personaId, monday, sunday, ctx.getState().filter.resourceId, ctx.getState().filter.sectionId).pipe(
      tap(
        appointmentCounts => {
          ctx.patchState({perDay: appointmentCounts.perDay});
        },
        () => {
          ctx.patchState({perDay: new Map<string, number>()});
        }
      )
    );
  }

  @Action(AppointmentDetailActions.AttributeValueChanged)
  attributeValueChanged(ctx: StateContext<AppointmentStateModel>, action: AttributeValueChanged) {
    let state = ctx.getState();
    let timeslot = Wod.fromModel(state.currentAppointment);
    timeslot.setAttribute(state.personaId, action.id, action.value);
    ctx.patchState({currentAppointment: timeslot});
  }

  @Action(AppointmentDetailActions.NumberOfPersonChanged)
  numberOfPersonChanged(ctx: StateContext<AppointmentStateModel>, action: NumberOfPersonChanged) {
    let state = ctx.getState();
    let timeslot = Wod.fromModel(state.currentAppointment);
    timeslot.setNumberOfPersons(state.personaId, action.numberOfPersons);
    ctx.patchState({currentAppointment: timeslot});
  }

  @Action(PersonaActions.PersonaChanged)
  personaInModuleChanged(ctx: StateContext<AppointmentStateModel>, action: PersonaActions.PersonaChanged) {
    ctx.patchState({personaId: action.personaId});
  }

  private loadAppointmentsForDate(ctx: StateContext<AppointmentStateModel>) {
    ctx.patchState({loadingAppointments: true, hasNextAppointment: false});
    return this.api.getAppointmentsForADay(
      ctx.getState().selectedDay,
      ctx.getState().filter?.resourceId,
      ctx.getState().filter?.sectionId
    ).pipe(
      tap(
        appointments => {
          ctx.patchState({appointments, loadingAppointments: false});
          if (appointments.length === 0) {
            this._checkHasNextAppointment(ctx);
          }
        },
        error => {
          ctx.patchState({appointments: [], loadingAppointments: false});
          this.messageService.showError(error.getDisplayMessage());
        }
      )
    );
  }

  @Action(AppointmentActions.LoadResources)
  loadResources(ctx: StateContext<AppointmentStateModel>) {
    return this.api.getResources().pipe(
      tap(
        it => {
          ctx.patchState({resources: it})
        },
        error => {
          ctx.patchState({resources: []});
          this.messageService.showError(error.getDisplayMessage())
        }
      )
    )
  }

  @Action(AppointmentActions.LoadResources)
  loadSections(ctx: StateContext<AppointmentStateModel>) {
    return this.api.getSections().pipe(
      tap(
        it => {
          ctx.patchState({sections: it})
        },
        error => {
          ctx.patchState({sections: []});
          this.messageService.showError(error.getDisplayMessage())
        }
      )
    )
  }

  @Action(AppointmentActions.LoadAppointments)
  loadAppointments(ctx: StateContext<AppointmentStateModel>) {
    ctx.patchState({loadingAppointments: true});
    return this._loadAppointments(ctx);
  }

  @Action(AppointmentActions.FilterChanged)
  filterChanged(ctx: StateContext<AppointmentStateModel>, action: FilterChanged) {
    const oldResourceId = ctx.getState().filter.resourceId;
    const oldSectionId = ctx.getState().filter.sectionId;
    ctx.patchState({filter: {filter: action.filter, resourceId: action.resourceId, sectionId: action.sectionId}});

    if (oldResourceId == action.resourceId && oldSectionId === action.sectionId) {
      return;
    }

    ctx.dispatch(new LoadAppointmentsCountPerWeek());
    if (this.customerModuleApi.getAppointmentLayout() === 'day') {
      return this.loadAppointmentsForDate(ctx);
    }

    return this.loadAppointments(ctx);
  }

  @Action(AppointmentActions.SectionChanged)
  sectionChanged(ctx: StateContext<AppointmentStateModel>, action: SectionChanged) {
    ctx.patchState({filter: {...ctx.getState().filter, sectionId: action.sectionId}});
    ctx.dispatch(new LoadAppointmentsCountPerWeek());
    // return this._loadAppointmentsCountForWeek(ctx);
  }

  @Action(AppointmentActions.ServiceProviderChanged)
  serviceProviderChanged(ctx: StateContext<AppointmentStateModel>, action: ServiceProviderChanged) {
    ctx.patchState({
      filter: {filter: '', sectionId: '', resourceId: ''},
      sections: [],
      appointments: []
    });
  }

  @Action(AppointmentActions.LoadMoreAppointments)
  loadMoreAppointments(ctx: StateContext<AppointmentStateModel>) {
    ctx.patchState({loadingAppointments: true});
    return this.api.getAppointments(ctx.getState().end.add(1, "day"), ctx.getState().filter.resourceId, ctx.getState().filter.sectionId, 20).pipe(
      tap(
        appointments => {
          if (appointments.length == 0) {
            return;
          }

          let end = AppointmentState._findLastDayInAppointmentList(appointments);
          ctx.patchState({
            appointments: [...ctx.getState().appointments, ...appointments],
            end: end,
            loadingAppointments: false
          })
        },
        error => {
          ctx.patchState({loadingAppointments: false})
          this.messageService.showError(error.getDisplayMessage())
        }
      )
    )
  }

  @Action(AppointmentActions.AppointmentSelected)
  appointmentSelected(ctx: StateContext<AppointmentStateModel>, action: AppointmentSelected) {
    ctx.patchState({currentAppointment: null, loadingCurrentAppointment: true});
    return this.api.getAppointment(action.id).pipe(
      tap(
        appointment => {
          this._selectDefaultPersona(ctx, appointment.personaStates);
          ctx.patchState({currentAppointment: appointment, loadingCurrentAppointment: false});
        },
        error => {
          this.messageService.showError(error.getDisplayMessage())
        }
      )
    );
  }

  @Action(AppointmentActions.DirectLinkToAppointment)
  directLinkToAppointment(ctx: StateContext<AppointmentStateModel>, action: DirectLinkToAppointment) {
    ctx.patchState({currentAppointment: null, loadingCurrentAppointment: true});
    return this.api.getAppointmentByUid(action.id).pipe(
      tap(
        appointment => {
          this._selectDefaultPersona(ctx, appointment.personaStates);
          this.serviceProviderModuleApi.getServiceProvider(appointment.serviceProvider)
            .toPromise().then(sp => {
              this.serviceProviderModuleApi.setServiceProvider(sp);
              ctx.patchState({
                currentAppointment: appointment,
                loadingCurrentAppointment: false,
                selectedDay: appointment.start.clone()
              });
            }
          )
        },
        error => {
          this.messageService.showError(error.getDisplayMessage())
        }
      )
    );
  }

  @Action(AppointmentDetailActions.AppointmentChanged)
  appointmentChanged(ctx: StateContext<AppointmentStateModel>, action: AppointmentChanged) {
    return this.api.getAppointment(action.id).pipe(
      tap(
        appointment => {
          let appointments = ctx.getState().appointments;
          let newAppointments = appointments.filter(it => it.id != appointment.id);
          newAppointments.push(appointment);

          ctx.patchState({appointments: newAppointments, currentAppointment: appointment});
        },
        error => {
          this.messageService.showError(error.getDisplayMessage())
        }
      )
    );
  }

  @Action(AppointmentDetailActions.Subscribe)
  subscribe(ctx: StateContext<AppointmentStateModel>, action: AppointmentDetailActions.Subscribe) {
    ctx.patchState({detailWorking: true});

    const state = ctx.getState();
    const timeslot = state.currentAppointment;
    let personaState = timeslot.personaStateForPersona(state.personaId);
    return this.api.subscribePersonaToTimeslot(timeslot.id, personaState.personaId, personaState.attributes, action.personen).pipe(
      tap(
        () => {
          ctx.patchState({detailWorking: false, numberOfPersons: 1});
          this.messageService.showSuccess("Deine Anmeldung war erfolgreich.");
        },
        error => {
          ctx.patchState({detailWorking: false, numberOfPersons: 1});
          this.messageService.showError(error.getDisplayMessage())
        }
      )
    )
  }

  @Action(AppointmentDetailActions.ApplyInstructor)
  applyInstructor(ctx: StateContext<AppointmentStateModel>, action: ApplyInstructor) {
    ctx.patchState({detailWorking: true});

    return this.api.applyInstructor(action.timeslotId, action.personaId).pipe(
      tap(
        () => {
          ctx.patchState({detailWorking: false});
          this.messageService.showSuccess("Deine Registrierung als Trainer war erfolgreich.");
          ctx.dispatch(new AppointmentChanged(action.timeslotId));
        },
        error => {
          ctx.patchState({detailWorking: false});
          this.messageService.showError(error.getDisplayMessage())
        }
      )
    )
  }

  @Action(AppointmentDetailActions.UnapplyInstructor)
  unapplyInstructor(ctx: StateContext<AppointmentStateModel>, action: UnapplyInstructor) {
    ctx.patchState({detailWorking: true});

    return this.api.unapplyInstructor(action.timeslotId, action.personaId).pipe(
      tap(
        () => {
          ctx.patchState({detailWorking: false});
          this.messageService.showSuccess("Deine Registrierung als Trainer wurde gelöscht.");
          ctx.dispatch(new AppointmentChanged(action.timeslotId));
        },
        error => {
          ctx.patchState({detailWorking: false});
          this.messageService.showError(error.getDisplayMessage())
        }
      )
    )
  }

  @Action(AppointmentDetailActions.Unsubscribe)
  unsubscribe(ctx: StateContext<AppointmentStateModel>) {
    ctx.patchState({detailWorking: true});

    const state = ctx.getState();
    const timeslot = state.currentAppointment;
    let personaState = timeslot.personaStateForPersona(state.personaId);

    return this.api.unsubscribePersonaFromTimeslot(Wod.fromModel(timeslot), personaState.personaId).pipe(
      tap(
        () => {
          ctx.patchState({detailWorking: false});
          this.messageService.showSuccess("Du hast Dich erfolgreich abgemeldet.");
        },
        error => {
          ctx.patchState({detailWorking: false});
          this.messageService.showError(error.getDisplayMessage())
        }
      )
    )
  }

  @Action(AppointmentDetailActions.SubscribeToWaitingList)
  subscribeToWaitingList(ctx: StateContext<AppointmentStateModel>) {
    ctx.patchState({detailWorking: true});

    const state = ctx.getState();
    const timeslot = state.currentAppointment;
    const personaState = timeslot.personaStateForPersona(state.personaId);
    return this.api.subscribePersonaToWaitingList(Wod.fromModel(timeslot), personaState.personaId, personaState.attributes).pipe(
      tap(
        () => {
          ctx.patchState({detailWorking: false});
          this.messageService.showSuccess("Du bist jetzt auf der Warteliste.");
        },
        error => {
          ctx.patchState({detailWorking: false});
          this.messageService.showError(error.getDisplayMessage())
        }
      )
    )
  }

  @Action(AppointmentDetailActions.UnsubscribeFromWaitingList)
  unsubscribeFromWaitingList(ctx: StateContext<AppointmentStateModel>) {
    ctx.patchState({detailWorking: true});

    const state = ctx.getState();
    const timeslot = state.currentAppointment;
    const personaState = timeslot.personaStateForPersona(state.personaId);
    return this.api.unsubscribePersonaFromWaitingList(Wod.fromModel(timeslot), personaState.personaId).pipe(
      tap(
        () => {
          ctx.patchState({detailWorking: false});
          this.messageService.showSuccess("Du hast Dich aus der Warteliste ausgetragen.");
        },
        error => {
          ctx.patchState({detailWorking: false});
          this.messageService.showError(error.getDisplayMessage())
        }
      )
    )
  }

  @Action(AppointmentActions.JumpToNextDayWithAppointments)
  jumpToNextDayWithAppointment(ctx: StateContext<AppointmentStateModel>) {
    ctx.patchState({loadingAppointments: true});
    return this.api.getAppointments(ctx.getState().selectedDay, ctx.getState().filter.resourceId, ctx.getState().filter.sectionId, 1).pipe(
      tap(
        appointments => {
          if (appointments.length > 0) {
            ctx.patchState({
              selectedDay: appointments[0].start.clone(),
              appointments: appointments,
              loadingAppointments: false
            });
          } else {
            ctx.patchState({loadingAppointments: false});
          }
        },
        error => {
          ctx.patchState({appointments: [], loadingAppointments: false});
          this.messageService.showError(error.getDisplayMessage());
        }
      )
    );
  }

  private _loadAppointments(ctx: StateContext<AppointmentStateModel>) {
    ctx.patchState({loadingAppointments: true});
    return this.api.getAppointments(ctx.getState().start, ctx.getState().filter.resourceId, ctx.getState().filter.sectionId, 20).pipe(
      tap(
        appointments => {
          let end = AppointmentState._findLastDayInAppointmentList(appointments);
          ctx.patchState({appointments: appointments, loadingAppointments: false, end: end})
        },
        error => {
          ctx.patchState({appointments: [], loadingAppointments: false})
          this.messageService.showError(error.getDisplayMessage())
        }
      )
    )
  }

  private static _findLastDayInAppointmentList(appointments: Wod[]): Moment {
    appointments
      .sort((a, b) => a.start.unix() - b.start.unix())

    let end = moment();
    if (appointments.length > 0) {
      let lastElement = appointments[appointments.length - 1];
      end = (lastElement) ? moment(lastElement.start) : moment();
    }
    return end;
  }

  /**
   * prüft ob es Termine in der Zukunft gibt
   * @param ctx
   * @private
   */
  private _checkHasNextAppointment(ctx: StateContext<AppointmentStateModel>) {
    this.api.getAppointments(ctx.getState().selectedDay, ctx.getState().filter.resourceId, ctx.getState().filter.sectionId, 1)
      .toPromise()
      .then(appointments => {
        ctx.patchState({hasNextAppointment: appointments.length > 0});
      })
      .catch(() => ctx.patchState({hasNextAppointment: false}))
  }

  /**
   * diese Methode stellt sicher, das immer eine Persona im State gesetzt ist die auch in den PersonaStates vorkommt
   * soltte keine Persona gesetzt sein oder die gesetzte Persona nicht in den PersonaStates vorkommen,
   * so wird der erste Eintrag der PersonaStates genommen
   *
   * @param ctx
   * @param personaStates Liste mit verfügbare Personas
   * @private
   */
  private _selectDefaultPersona(ctx: StateContext<AppointmentStateModel>, personaStates: PersonaState[]) {
    const state = ctx.getState();

    //keine personaId gesetzt
    if (state.personaId == null) {
      ctx.patchState({personaId: personaStates[0].personaId});
    }

    //unbekannte personaId gesetzt
    if (personaStates.filter(it => it.personaId == state.personaId).length == 0) {
      ctx.patchState({personaId: personaStates[0].personaId});
    }
  }
}
