<ng-container *ngIf="appointment$ | async as timeslot">
  <div class="detail-section">
    <div class="line" *ngIf="timeslot.canceled">
      <div class="line"></div>
      <div class="value cancel-info">Abgesagt</div>
    </div>

    <div class="line">
      <div class="label">Start</div>
      <div class="value" [class.canceled]="timeslot.canceled">
        <span class="date">{{timeslot.start | amDateFormat: 'dd, D. MMM YY'}}</span>
        <span class="time">{{timeslot.start | amDateFormat: 'HH:mm'}}</span>
      </div>
    </div>

    <div class="line">
      <div class="label">Ende</div>
      <div class="value" [class.canceled]="timeslot.canceled">
        <span class="date">{{timeslot.end | amDateFormat: 'dd, D. MMM YY'}}</span>
        <span class="time">{{timeslot.end | amDateFormat: 'HH:mm'}}</span>
      </div>
    </div>

    <div class="line" *ngIf="templateVersion == 'v1'">
      <div class="label">Teilnehmer</div>
      <div class="value">{{timeslot.numberOfMembers}} von {{timeslot.maxMembers}}</div>
    </div>

    <div class="line" *ngIf="templateVersion == 'v2'">
      <div class="label">freie Plätze</div>
      <div class="value">{{timeslot.freeSlots}}</div>
    </div>

    <div class="line">
      <div class="label">Zugangsbeschränkung</div>
      <div class="value">{{ 'ACCESS_CHECK_'+timeslot.accessCheck | translate }}</div>
    </div>

  </div>
</ng-container>
