export class NotificationConfiguration {
  constructor(public key: string, public mail: boolean, public telegram: boolean) {
  }

  clone(): NotificationConfiguration{
    return new NotificationConfiguration(this.key, this.mail, this.telegram);
  }

  public static fromJson(data: any) : NotificationConfiguration {
    return new NotificationConfiguration(data.key, data.mail, data.telegram);
  }
}
