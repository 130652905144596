<div class="section">
  <div class="header">E-Mail-Adresse ändern - Schritt 1 / 2</div>
  <div class="body">
    <form [formGroup]="formGroup" autocomplete="off" novalidate>
      <div>
        <mat-form-field>
          <mat-label>E-Mail-Adresse</mat-label>
          <input id="email" matInput formControlName="email">
        </mat-form-field>
      </div>
      <div class="actions">
        <button id="submit" mat-stroked-button color="primary" (click)="send()" [disabled]="!this.formGroup.valid">
          Senden
        </button>
      </div>
      <div class="description">
        Gib einfach Deine neue E-Mail-Adresse ein und wir schicken
        Dir einen Link, mit dem Du bestätigst, dass Du auf die E-Mails
        zugreifen kannst.
      </div>
    </form>
  </div>
</div>

