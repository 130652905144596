<div class="section">
  <div class="header">Name ändern</div>
  <div class="body">
    <form [formGroup]="formGroup" autocomplete="off" novalidate>
      <div>
        <mat-form-field>
          <mat-label>Vorname</mat-label>
          <input id="firstname" type="text" matInput formControlName="firstname">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Nachname</mat-label>
          <input id="lastname" type="text" matInput formControlName="lastname">
        </mat-form-field>
      </div>
      <div class="actions">
        <button id="submit" mat-stroked-button color="primary" (click)="send()" [disabled]="!this.formGroup.valid">
          Name ändern
        </button>
      </div>
    </form>
  </div>
</div>

