import { Component } from '@angular/core';
import {Observable} from "rxjs";
import {Wod} from "../../model/wod";
import {Store} from "@ngxs/store";
import {AppointmentState} from "../../state/appointment.state";

@Component({
  selector: 'appointment-detail-timeframes',
  templateUrl: './timeframes.component.html',
  styleUrls: ['./timeframes.component.scss']
})
export class TimeframesComponent {
  public timeslot$: Observable<Wod>;
  personaId$: Observable<string | undefined>;

  constructor(private store: Store) {
    this.timeslot$ = this.store.select(AppointmentState.currentAppointment);
    this.personaId$ = this.store.select(AppointmentState.persona);
  }
}
