import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Booking} from "../../../services/booking";

@Component({
  selector: 'app-booking-item',
  templateUrl: './booking-item.component.html',
  styleUrls: ['./booking-item.component.scss']
})
export class BookingItemComponent {
  @Input() bookingItem: Booking;
  @Output() show = new EventEmitter<void>();
  constructor() { }
}
