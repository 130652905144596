import {BrowserModule, HammerModule} from '@angular/platform-browser';
import {ErrorHandler, Injectable, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LoginComponent} from "./login/login.component";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ReactiveFormsModule} from "@angular/forms";
import {UserApi} from "./services/user-api.service";
import {Session} from "./services/session";
import {AuthGuard} from "./guards/auth-guard.service";
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {MomentModule} from "ngx-moment";
import {BookingListComponent} from "./booking-list/booking-list.component";
import * as moment from "moment";
import {TopMenuComponent} from "./top-menu/top-menu.component";
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {version} from '../environments/version';
import * as Sentry from "@sentry/browser";
import {PasswordChangeComponent} from './password-change/password-change.component';
import {ServiceProviderSelectComponent} from './service-provider-select/service-provider-select.component';
import {ServiceProviderGuard} from "./guards/service-provider-guard.service";
import {UpdateService} from "./services/update.service";
import {CheckForUpdateService} from "./services/check-for-update.service";
import {ServiceProviderItemComponent} from './service-provider-item/service-provider-item.component';
import {PasswordResetComponent} from './password-reset/password-reset.component';
import {InvitationComponent} from './customer/invitation/invitation.component';
import {ConfirmEmailComponent} from './customer/invitation/confirm-email/confirm-email.component';
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {ImpressumComponent} from './impressum/impressum.component';
import {ConfirmEmailChangeWithTokenComponent} from "./confirm-email-change-with-token/confirm-email-change-with-token.component";
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {EffectsModule} from '@ngrx/effects';
import {AppEffects} from './actions/app.effects';
import {routerReducer, StoreRouterConnectingModule} from '@ngrx/router-store';
import {RouterModule} from "@angular/router";
import {MessageLayoutComponent} from "./message-display/message-layout/message-layout.component";
import {MessageDisplayComponent} from "./message-display/message-display.component";
import * as fromAppReducer from './actions/index';
import {AppMaterialModule} from "./material.module";
import {NewsModule} from "./news/news.module";
import {ConfirmDialogComponent} from "./shared/confirm-dialog/confirm-dialog.component";
import {ServiceProviderDeeplinkComponent} from "./service-provider-deeplink/service-provider-deeplink.component";
import {NgxsModule} from "@ngxs/store";
import {NgxsReduxDevtoolsPluginModule} from "@ngxs/devtools-plugin";
import {CustomerModule} from "./customer/customer.module";
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import * as Hammer from 'hammerjs';
import {ServiceProviderModule} from "./service-provider/service-provider.module";
import {NgxsFormPluginModule} from "@ngxs/form-plugin";
import {ToastrModule} from "ngx-toastr";
import {AppointmentModule} from "./appointment/appointment.module";
import {ServiceProviderDetailComponent} from "./service-provider-detail/service-provider-detail.component";
import {registerLocaleData} from "@angular/common";
import localeDe from "@angular/common/locales/de";
import {PersonaModule} from "./persona/persona.module";
import {PersonaComponent} from "./top-menu/persona/persona.component";
import {BottomMenuComponent} from "./bottom-menu/bottom-menu.component";
import {NgxsRouterPluginModule, RouterStateSerializer} from "@ngxs/router-plugin";
import {CustomRouterStateSerializer} from "./state/router-state.serializer";
import {InfoComponent} from "./info/info.component";
import {TokenLoginComponent} from "./login/token-login/token-login.component";
import {LoginByLinkComponent} from "./login/login-by-link/login-by-link.component";
import {BookingItemComponent} from "./booking-list/container/booking-item/booking-item.component";
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS} from "@angular/material-moment-adapter";
import {ServiceProviderSearchComponent} from "./service-provider-search/service-provider-search.component";
@Injectable()
export class HammerConfig extends HammerGestureConfig {
  overrides = {
    swipe: { direction: Hammer.DIRECTION_HORIZONTAL },
    pinch: { enable: false },
    rotate: { enable: false },
    pan: { enable: false }
  } as any;
}

moment.locale("de");
registerLocaleData(localeDe);

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

Sentry.init({
  dsn: "https://25452144c9c4433aaad65767e5e41e74@sentry.patrix.biz/7",
  environment: window.location.hostname,
  release: version
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  handleError(error) {
    if (environment.production)
      Sentry.captureException(error.originalError || error);
    else
      console.log(error);
  }
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    TokenLoginComponent,
    LoginByLinkComponent,
    InfoComponent,
    BookingListComponent,
    BookingItemComponent,
    TopMenuComponent,
    PasswordChangeComponent,
    ServiceProviderSelectComponent,
    ServiceProviderDeeplinkComponent,
    ServiceProviderItemComponent,
    ServiceProviderDetailComponent,
    ServiceProviderSearchComponent,
    PasswordResetComponent,
    InvitationComponent,
    ConfirmEmailComponent,
    ImpressumComponent,
    ConfirmEmailChangeWithTokenComponent,
    MessageLayoutComponent,
    MessageDisplayComponent,
    ConfirmDialogComponent,
    PersonaComponent,
    BottomMenuComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AppMaterialModule,
    ReactiveFormsModule,
    HammerModule,
    HttpClientModule,
    MomentModule,
    NewsModule,
    CustomerModule,
    AppointmentModule,
    PersonaModule,
    ServiceProviderModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-bottom-center',
      preventDuplicates: true,
      progressBar: true,
      progressAnimation: "decreasing"
    }),
    NgxsFormPluginModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    TranslateModule.forRoot({
      defaultLanguage: 'default',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      }
    }),
    StoreModule.forRoot({app: fromAppReducer.appReducers, router: routerReducer}),
    EffectsModule.forRoot([AppEffects]),
    RouterModule.forRoot([]),
    StoreDevtoolsModule.instrument({maxAge: 25, logOnly: environment.production}),
    StoreRouterConnectingModule.forRoot(),

    NgxsModule.forRoot([], {developmentMode: !environment.production}),
    NgxsRouterPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot()

  ],
  providers: [
    UserApi,
    Session,
    AuthGuard,
    ServiceProviderGuard,
    UpdateService, CheckForUpdateService,
    {provide: ErrorHandler, useClass: SentryErrorHandler},
    {provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfig},
    {provide: RouterStateSerializer, useClass: CustomRouterStateSerializer},
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}}
  ],
  exports: [
    PasswordChangeComponent
  ],
  bootstrap: [AppComponent]
})


export class AppModule {
}
