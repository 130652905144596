import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {filter, map} from "rxjs/operators";
import {ActivatedRoute, Router} from "@angular/router";
import {UserApi} from "../../services/user-api.service";
import {ServiceProvider} from "../../services/service-provider";
import {ApiError} from "../../services/error";
import {MatSnackBar} from "@angular/material/snack-bar";
import {BehaviorSubject} from "rxjs";
import * as Sentry from "@sentry/browser";

@Component({
  selector: 'app-invitation',
  templateUrl: './invitation.component.html',
  styleUrls: ['./invitation.component.scss']
})
export class InvitationComponent implements OnInit {
  registerForm: FormGroup;
  private spIdentifier: string;
  private token: string;
  private registerData: { email: string };
  serviceProvider: ServiceProvider;

  /**
   * "invalid_link", "formular", "request", "success", "error"
   */
  status$: BehaviorSubject<string> = new BehaviorSubject("");

  constructor(private fb: FormBuilder, private userApi: UserApi,
              private _route: ActivatedRoute, private _router: Router,
              private _snackBar: MatSnackBar) {
    this.initializeForm();
  }

  ngOnInit(): void {
    if (this.extractLinkParams()) {
      this.loadServiceProvider();
    }
  }

  /**
   * extrahiert die Parameter aus der URL und prüft diese
   */
  private extractLinkParams(): boolean {
    let result = true;

    this.spIdentifier = this._route.snapshot.queryParamMap.get("sp")
    if (!this.spIdentifier) {
      result = false;
    }

    this.token = this._route.snapshot.queryParamMap.get("token")
    if (!this.token) {
      result = false;
    }

    if (result == false) {
      this.sendSentryEvent("invalid invitation link");
      this.status$.next('invalid_link');
    }

    return result;
  }



  private loadServiceProvider() {
    this.userApi.getServiceProvider(this.spIdentifier)
      .subscribe(
        serviveProvider => {
          this.serviceProvider = serviveProvider
          this.status$.next("formular")
        },
        error => {
          this.onApiError(new ApiError(error))
          this.status$.next("invalid_link")
        }
      );
  }

  register() {
    this.status$.next('request');
    this.userApi
      .invite(this.registerData.email, this.token, this.spIdentifier)
      .subscribe(
        () => {
          this.status$.next('success');
        },
        () => {
          this.status$.next('error');
        }
      )
  }

  private onApiError(error: ApiError) {
    this._snackBar.open(error.getDisplayMessage(), "OK", {duration: 8000});
  }


  /**
   * erstellt das Formular
   */
  private initializeForm() {
    this.registerForm = this.fb.group(
      {
        email: ['', [Validators.required]],
      }
    )

    this.registerForm.valueChanges
      .pipe(
        filter(() => this.registerForm.valid),
        map(value => {
          return {email: value.email}
        }),
      )
      .subscribe(
        login => {
          this.registerData = login;
        }
      );
  }

  sendSentryEvent(message: string) {
    Sentry.setExtra("params", this._route.snapshot.params);
    Sentry.setExtra("queryParams", this._route.snapshot.queryParams);
    Sentry.captureException(new Error(`message: ${message}`));
  }
}
