import {Component, OnInit} from '@angular/core';
import * as Version from "../../environments/version";
import {Store} from "@ngxs/store";
import {RouterSelectors} from "../state/router-selectors";
import {map} from "rxjs/internal/operators";
import {Observable} from "rxjs";
import {tap} from "rxjs/operators";
import {Navigate} from "@ngxs/router-plugin";

@Component({
  selector: 'app-bottom-menu',
  templateUrl: './bottom-menu.component.html',
  styleUrls: ['./bottom-menu.component.scss']
})
export class BottomMenuComponent implements OnInit {
  version: string;
  section$: Observable<string>;

  constructor(private store: Store) {
    this.version = Version.version;
    this.section$ = store.select(RouterSelectors.url).pipe(
      tap(it => console.log(it)),
      map(it => {
        if (it?.startsWith('/service-provider-select')) return 'sp';
        if (it?.startsWith('/service-provider-search')) return 'sp';
        if (it?.startsWith('/ts')) return 'ts';
        if (it?.startsWith('/appointments')) return 'ts';
        if (it == ('/')) return 'ts';
        if (it?.startsWith('/bookings')) return 'bookings';
        return ''
      })
    );
  }

  ngOnInit(): void {
  }

  goto(url: string) {
    this.store.dispatch(new Navigate([url]))
  }
}
