import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {Persona} from "./model/persona";
import {Store} from "@ngxs/store";
import {PersonaState} from "./state/persona.state";
import {PersonaActions} from "./state/persona.actions";
import {map} from "rxjs/internal/operators";
import {MatBottomSheet} from "@angular/material/bottom-sheet";
import {SelectComponent} from "./select/select.component";

@Injectable()
export class PersonaModuleApi {
  constructor(private store: Store, private _bottomSheet: MatBottomSheet) {}

  personas$(): Observable<Persona[]>{
    if (this.store.selectSnapshot(PersonaState.personas)?.length == 0) {
      this.store.dispatch(new PersonaActions.LoadAll(true));
    }
    return this.store.select(PersonaState.personas);
  }

  selectPersona(persona: Persona) {
    this.store.dispatch(new PersonaActions.PersonaSelected(persona))
  }

  selectPersonaById(personaId: string) {
    this.store.dispatch(new PersonaActions.PersonaIdSelected(personaId))
  }

  /**
   * @Deprecated besser ist es auf das Ereigniss PersonaActions.PersonaSelected zu reagieren, so wie im AppointmentState
   * @see AppointmentState.personaInModuleSelected
   */
  currentId() : Observable<string | null>{
    return this.store.select(PersonaState.current).pipe(map(it => it?.id));
  }

  currentPersona() : Observable<Persona | null>{
    return this.store.select(PersonaState.current);
  }

  reloadPersonas() {
    this.store.dispatch(new PersonaActions.LoadAll(true));
  }

  displayPersonaSelectionDialog() : void {
    this._bottomSheet.open(SelectComponent);
  }
}
