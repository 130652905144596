import { Injectable } from '@angular/core';
import {NEWS} from "./news.data";
import {NewsMessage} from "./model/newsMessage";
import {Observable, ReplaySubject} from "rxjs";

@Injectable({providedIn: 'root'})
export class NewsService {
  private news = NEWS;
  private _unreadMessages: ReplaySubject<NewsMessage[]> = new ReplaySubject<NewsMessage[]>();

  constructor() {
    this.calculateUnreadMessages();
  }

  getMessages() : NewsMessage[] {
    return this.news;
  }

  markAllMessagesAsRead() : void {
    localStorage.setItem("last_read_message", this.news[0].id.toString());
    this.calculateUnreadMessages();
  }

  getUnreadMessages() : Observable<NewsMessage[]> {
    return this._unreadMessages.asObservable();
  }

  private calculateUnreadMessages() {
    let lastId = +localStorage.getItem("last_read_message");
    this._unreadMessages.next(this.getMessages().filter(it => it.id > lastId));
  }

}
