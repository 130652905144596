<ng-container *ngIf="(serviceProviders$|async) as serviceProviders">
  <ng-container *ngIf="serviceProviders.length > 0">
    <mat-form-field appearance="outline">
      <mat-label>Anbieter</mat-label>
      <mat-select [formControl]="spField">
        <mat-option *ngFor="let sp of serviceProviders" [value]="sp.identifier">
          {{sp.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="serviceProviders.length == 0">
    <div>Du hast aktuell bei keinen Dienstleister einen Vertrag oder Guthaben.</div>
  </ng-container>
</ng-container>

<ng-container *ngIf="personaState$|async as personaState">
  <div class="item">
    <span class="title">aktueller Vertrag</span>
    <span class="value">{{contractType(personaState.contractType,personaState.contingentUnits)}}</span>
  </div>
  <div class="item">
    <span class="title">gültig bis</span>
    <span class="value">{{contractUntil(personaState.contractUntil)}}</span>
  </div>
  <div class="item">
    <span class="title">Prepaid-Einheiten</span>
    <span class="value">{{personaState.prepaidUnits}}</span>
  </div>
</ng-container>

