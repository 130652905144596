<div class="section">
  <div class="header">Login</div>
  <div class="body">
    <div id="errorMessage">{{errorMessage}}</div>
    <form [formGroup]="loginForm" autocomplete="off" novalidate>
      <div>
        <mat-form-field appearance="outline">
          <mat-label>E-Mail-Adresse</mat-label>
          <input data-cy="login-email" type="email" matInput formControlName="email" autofocus tabindex="1">
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="outline">
          <mat-label>Passwort</mat-label>
          <input data-cy="login-password"  matInput formControlName="password" [type]="hide ? 'password' : 'text'" tabindex="2">
        </mat-form-field>
      </div>
      <div class="actions">
        <a data-cy="register" id="btn-register" mat-stroked-button color="primary" (click)="gotoRegister()" tabindex="-1">Registrieren</a>
        <button data-cy="login-submit" id="btn-login" mat-stroked-button color="warn" (click)="submit()" [disabled]="!this.loginForm.valid" tabindex="3">
          Login
        </button>
      </div>
    </form>
    <a data-cy="login-by-link"  routerLink="/login-by-link">Login via E-Mail</a>
    <a data-cy="login-reset"  routerLink="/reset-password">Neues Passwort setzen</a>
  </div>
</div>

