<ng-container *ngIf="serviceProviders$ | async as providers">
  <ng-container *ngIf="providers?.length > 0">
    <ng-container *ngFor="let sp of serviceProviders$ | async">
    <app-service-provider-item
                               [attr.data-cy]="'service-provider-select-item-'+sp.identifier"
                               [serviceProvider]="sp" [member]="true"
                               (selected)="select(sp)"></app-service-provider-item>
    </ng-container>
  </ng-container>
</ng-container>

<button class="link_to_public" mat-stroked-button routerLink="/service-provider-search">öffentliche Anbieter suchen</button>
