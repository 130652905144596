import {Component} from '@angular/core';
import {UserApi} from "../services/user-api.service";
import {combineLatest, Observable, of, ReplaySubject} from "rxjs";
import {ServiceProvider} from "../services/service-provider";
import {PersonaModuleApi} from "../persona/persona-module-api";
import {ServiceProviderModuleApi} from "../service-provider/service-provider-module-api";
import {Router} from "@angular/router";
import {map} from "rxjs/internal/operators";

@Component({
  selector: 'app-service-provider-search',
  templateUrl: './service-provider-search.component.html',
  styleUrls: ['./service-provider-search.component.scss']
})
export class ServiceProviderSearchComponent {
  searchResult$: Observable<ServiceProvider[]> = of([]);
  searchResultsFound = true;
  hasFilterValue = false;

  private filter$ = new ReplaySubject<string>();
  private registeredServiceProviders: ServiceProvider[] = [];
  private publicServiceProviders$: Observable<ServiceProvider[]> = of();

  constructor(private api: UserApi, private personaModuleApi: PersonaModuleApi,
              private serviceProviderModuleApi: ServiceProviderModuleApi, private router: Router) {
    this.personaModuleApi.currentId().subscribe(personaId => this.loadServiceProviders(personaId))
    this.publicServiceProviders$ = this.api.getPublicServiceProviders();
    this.searchResult$ = combineLatest([this.filter$, this.publicServiceProviders$]).pipe(
      map(([filter, serviceProviders]) => {
        if (filter.length < 3) {
          return [];
        }

        let searchResult =  serviceProviders.filter(sp =>
          sp.name.toLowerCase().includes(filter.toLowerCase())
          || sp.address?.town?.toLowerCase().includes(filter.toLowerCase())
          || sp.address?.line1?.toLowerCase().includes(filter.toLowerCase())
          || sp.nameLong?.toLowerCase().includes(filter.toLowerCase())
        );

        this.searchResultsFound = searchResult.length > 0;
        return searchResult;

      })
    )
  }

  select(sp: ServiceProvider) {
    this.serviceProviderModuleApi.setServiceProvider(sp);
    this.router.navigate(["/ts"]);
  }

  applyFilter(filterValue: string) {
    this.hasFilterValue = filterValue?.length > 2;
      this.filter$.next(filterValue);
  }

  isMember(serviceProvider: ServiceProvider) : boolean {
      return this.registeredServiceProviders.filter(sp => sp.id == serviceProvider.id).length > 0;
  }

  hasRegisteredServiceProviders() : boolean {
    return this.registeredServiceProviders?.length > 0;
  }

  private loadServiceProviders(personaId: string) {
    this.api.getRegisterdServiceProviders(personaId).subscribe(serviceProviders => this.registeredServiceProviders = serviceProviders);
  }
}
