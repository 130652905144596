import {Component, Inject} from '@angular/core';
import {Participant} from "../../model/participant";
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from "@angular/material/bottom-sheet";

@Component({
  selector: 'app-participant-bottom-action',
  templateUrl: './participant-bottom-action.component.html',
  styleUrls: ['./participant-bottom-action.component.scss']
})
export class ParticipantBottomActionComponent {
  participant: Participant;

  constructor(private _bottomSheetRef: MatBottomSheetRef<ParticipantBottomActionComponent>, @Inject(MAT_BOTTOM_SHEET_DATA) public data:any) {
    this.participant = data.participant;
  }

  presence() {
    this._bottomSheetRef.dismiss({action: 'presence'});
  }

  absence() {
    this._bottomSheetRef.dismiss({action: 'absence'});
  }
}
