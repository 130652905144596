import {Component, EventEmitter, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {RegistrationData} from "../../model/registration-data";

@Component({
  selector: 'app-public-confirmation-formular',
  templateUrl: './public-confirmation-formular.component.html',
  styleUrls: ['./public-confirmation-formular.component.scss']
})
export class PublicConfirmationFormularComponent {
  hide = true;
  form: FormGroup;
  @Output() activate = new EventEmitter<RegistrationData>();


  constructor(fb: FormBuilder) {
    this.form = fb.group(
      {
        password: ['', [Validators.required]],
        user_agreement: [false, [Validators.pattern('true')]]
      }
    )
  }

  activateNewUser() {
    this.activate.emit({
      firstname: '',
      lastname: '',
      password: this.form.get('password').value,
      userAgreement: '1.0',
      attributes: []
    })
  }
}
