<ng-container *ngIf="!readonly">
  <div [formGroup]="form">
    <mat-form-field appearance="outline">
      <mat-label>{{attribute.name}}</mat-label>
      <mat-select [formControlName]="attribute.identifier" required>
        <mat-option *ngFor="let opt of attribute.values" [value]="opt">{{opt}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</ng-container>

<ng-container *ngIf="readonly">
  <div class="line">
    <div class="label">{{attribute.name}}</div>
<!--    <div class="value">{{attribute.value}}</div>-->
  </div>
</ng-container>
