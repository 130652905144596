import {Moment} from "moment";
import {ServiceProvider} from "../../services/service-provider";

export namespace AppointmentActions {
  export class LayoutChanged {
    static readonly type = '[AppointmentHome] Layout Changed';

    constructor(public layout: string) {
    }
  }

  export class ServiceProviderChanged {
    static readonly type = '[AppointmentModuleApi] serviceProvider Changed';
    constructor(public serviceProvider: ServiceProvider) {}
  }

  export class LoadResources {
    static readonly type = '[AppointmentList] load all resources for a serviceProvider';
  }

  export class LoadSections {
    static readonly type = '[AppointmentList] load all sections for a serviceProvider';
  }

  export class LoadAppointments {
    static readonly type = '[AppointmentList] load first appointments';
  }

  export class LoadMoreAppointments {
    static readonly type = '[AppointmentList] load more appointments';
  }

  export class AppointmentSelected {
    static readonly type = '[AppointmentListItem] appointment selected'

    constructor(public id: string) {
    }
  }

  export class DirectLinkToAppointment {
    static readonly type = '[DirectLink] appointment selected'

    constructor(public id: string) {
    }
  }

  export class FilterChanged {
    static readonly type = '[FilterBar] filter changed';

    constructor(public filter: string, public resourceId: string, public sectionId: string) {
    }
  }

  export class SectionChanged {
    static readonly type = '[SectionList] section changed';

    constructor(public sectionId: string) {
    }
  }

  export class DaySelected {
    static readonly type = '[DayBar] day selected';

    constructor(public date: Moment) {
    }
  }

  export class NextDaySelected {
    static readonly type = '[DayList] next day selected (per Swipe)';
  }

  export class PrevDaySelected {
    static readonly type = '[DayList] prev day selected (per Swipe)';
  }

  export class NextWeekSelected {
    static readonly type = '[DayBar] next week selected';
  }

  export class PrevWeekSelected {
    static readonly type = '[DayBar] prev week selected';
  }

  export class ReloadAppointmentsForSelectedDate {
    static readonly type = '[DayView] reload appointments for selected date';
  }

  export class LoadAppointmentsCountPerWeek {
    static readonly type = '[DayList] load appointments count for visible dates';
  }

  export class JumpToNextDayWithAppointments {
    static readonly type = '[DayList] set dey selected to next date with appointments';
  }
}
