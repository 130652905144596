import {createAction, props} from '@ngrx/store';
import {NotificationConfiguration} from "../customer/model/notification-configuration";
import {ApiError} from "../services/error";
import {Feeds} from "../customer/model/feeds";


export const logoutSuccess = createAction('[API] logout was successful');
export const wrongAuthenticationData = createAction('[API] authentication data was wrong');
export const invalidSession = createAction('[API] session data are invalid');
export const notificationSettingChanged = createAction('[API] notification setting was changed', props<{key: string, mail: boolean, telegram: boolean}>());
export const notificationSettingsLoaded = createAction('[API] notification settings loaded', props<{configurations: NotificationConfiguration[]}>());
export const loadNameSuccess = createAction('[API] name was loaded', props<{firstname: string, lastname: string}>());
export const loadNameError = createAction('[API] error at name loading', props<{error: ApiError}>());
export const changeNameSuccess = createAction('[API] name was changed', props<{firstname: string, lastname: string}>());
export const changeNameError = createAction('[API] error at name changing. name was not changed', props<{error: ApiError}>());
export const loadFeedsSuccess = createAction('[Api] load feeds success', props<{feeds: Feeds}>());
export const loadFeedsError = createAction('[API] load feeds error', props<{error: ApiError}>());
export const resetIcalFeedSuccess = createAction('[API] reset ical feeds success');
export const resetIcalFeedError = createAction('[API] reset ical feeds error', props<{error: ApiError}>());

