import { Component } from '@angular/core';
import {AppointmentState} from '../state/appointment.state';
import {Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {AppointmentActions} from '../state/appointment.actions';
import JumpToNextDayWithAppointments = AppointmentActions.JumpToNextDayWithAppointments;

@Component({
  selector: 'app-no-data',
  templateUrl: './no-data.component.html',
  styleUrls: ['./no-data.component.scss']
})
export class NoDataComponent {
  hasNextAppointment$: Observable<boolean>;
  loadingNextAppointment$: Observable<boolean>;

  constructor(private store: Store) {
    this.hasNextAppointment$ = this.store.select(AppointmentState.hasNextAppointment);
    this.loadingNextAppointment$ = this.store.select(AppointmentState.loadingNextAppointment);
  }

  jumpToNextAppointment() {
    this.store.dispatch(new JumpToNextDayWithAppointments());
  }


}
