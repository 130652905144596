import {Component, EventEmitter, Output} from '@angular/core';
import {Observable} from "rxjs";
import {Wod} from "../../model/wod";
import {Store} from "@ngxs/store";
import {AppointmentState} from "../../state/appointment.state";
import {Participant} from "../../model/participant";

@Component({
  selector: 'appointment-detail-data',
  templateUrl: './data.component.html',
  styleUrls: ['./data.component.scss']
})
export class DataComponent {
  public wod$: Observable<Wod>;
  @Output() participantSelected = new EventEmitter<Participant>();

  constructor(private store: Store) {
    this.wod$ = this.store.select(AppointmentState.currentAppointment)
  }

  useV1() {
    return this.store.selectSnapshot(AppointmentState.detailDialogVersion) == 'v1'
  }

  useV2() {
    return this.store.selectSnapshot(AppointmentState.detailDialogVersion) == 'v2'
  }
}
