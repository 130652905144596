<ng-container *ngIf="!readonly">
  <mat-checkbox [formControl]="control" [required]="attribute.mandatory">{{attribute.name}}
  </mat-checkbox>
</ng-container>

<ng-container *ngIf="readonly">
  <div class="line">
    <div class="label">{{attribute.name}}</div>
    <div class="value">{{attribute.value}}</div>
  </div>
</ng-container>
