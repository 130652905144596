import * as moment from "moment";
import {Moment} from "moment";

export class Booking {
  public workoutId: string;
  public name: string;
  public start: Moment;
  public end: Moment;
  public unsubscriptionClosingAt: Moment;
  public durationInMinutes: number;
  public canceled: boolean;
  public serviceProviderName: string;
  public serviceProviderIdentifier: string;
  public serviceProviderId: string;
  public bookingCode: string;
  public bookingReference: string;

  public static fromJson(data: any): Booking {
    let result = new Booking();
    result.workoutId = data.timeslotId;
    result.name = data.name;
    result.start = moment(data.start);
    result.end = moment(data.end);
    result.unsubscriptionClosingAt = moment(data.unsubscription_closing_at);
    result.durationInMinutes = data.duration_minutes;
    result.canceled = data.canceled;
    result.serviceProviderName = data.serviceProvider?.name;
    result.serviceProviderIdentifier = data.serviceProvider?.identifier;
    result.serviceProviderId = data.serviceProvider?.id;
    result.bookingCode = data.bookingCode;
    result.bookingReference = data.bookingReference;
    return result;
  }

  public isOpen() {
    return this.start.unix() > moment().unix();
  }

  public canShowBookingCode(): boolean {
    return true;
    //return this.serviceProviderId == 'store' || this.serviceProviderVariant == 'museum';
  }

  public hasBookingCode(): boolean {
    return !!this.bookingCode && this.bookingCode != '';
  }

  hasBookingReference() {
    return this.bookingReference !== null;
  }
}
