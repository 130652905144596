<ng-container *ngIf="appointment$ | async as appointment">
  <ng-container *ngIf="personaId$ | async as personaId">
    <div class="detail-section-no-border">
      <ng-container *ngIf="appointment.canSubscribe(personaId) && !appointment.canceled">
        <button *ngIf="appointment.isFree(personaId)" mat-raised-button color="primary" (click)="this.subscribe(appointment, personaId)" [disabled]="working" [class.working]="working">
          {{ 'DETAIL.ACTION_BAR.SUBSCRIBE_FREE' | translate }}
        </button>
        <button *ngIf="!appointment.isFree(personaId)" mat-raised-button color="primary" (click)="this.subscribe(appointment,personaId)" [disabled]="working" [class.working]="working">
          {{ 'DETAIL.ACTION_BAR.SUBSCRIBE' | translate }}
        </button>
      </ng-container>

      <ng-container *ngIf="appointment.canUnsubscribe(personaId) && !appointment.canceled">
        <mat-menu #addToCalendarMenu="matMenu">
          <a mat-menu-item [href]="appointment.getGoogleCalendarLink()" target="_blank">Android</a>
          <a mat-menu-item [href]="getIosCalendarLink(appointment)" target="_blank">IOS</a>
        </mat-menu>
        <button mat-raised-button color="primary" (click)="this.unsubscribe(appointment)" [disabled]="working" [class.working]="working">Absagen</button>
        <button mat-raised-button color="primary" [matMenuTriggerFor]="addToCalendarMenu">+ Kalender</button>
      </ng-container>

      <ng-container *ngIf="isWaitingListSupported()">
        <ng-container *ngIf="!appointment.canSubscribe(personaId) && appointment.canSubscribeToWaitingList(personaId)">
          <button mat-raised-button color="primary" [disabled]="working" [class.working]="working" (click)="this.subscribeToWaitingList(appointment)">auf Warteliste setzen</button>
        </ng-container>

        <ng-container *ngIf="appointment.canUnsubscribeToWaitingList(personaId)">
          <button mat-raised-button color="primary" [disabled]="working" [class.working]="working" (click)="this.unsubscribeFromWaitingList(appointment)">von Warteliste (Platz {{appointment.getPositionOnWaitingList(personaId)}}) austragen</button>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</ng-container>
