import {Component} from '@angular/core';
import {Observable} from "rxjs";
import {Wod} from "../../model/wod";
import {Store} from "@ngxs/store";
import {AppointmentState} from "../../state/appointment.state";
import {MessageService} from "../../../shared/message.service";

@Component({
  selector: 'appointment-detail-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent {

  public timeslot$: Observable<Wod>;
  personaId$: Observable<string | undefined>;

  constructor(private store: Store, private msgService: MessageService) {
    this.timeslot$ = this.store.select(AppointmentState.currentAppointment);
    this.personaId$ = this.store.select(AppointmentState.persona);
  }

  disabledLinkClicked() {
    this.msgService.showInfo("Dieser Link ist erst nach Ablauf der Abmeldefrist für alle angemeldeten Teilnehmer sichtbar.");
  }
}
