import {Persona} from "../model/persona";

export namespace PersonaActions {
  export class LoadAll {
    static readonly type = '[PersonaList] load All personas';
    constructor(public selectFirst: boolean = false) {}
  }

  export class Edit {
    static readonly type = '[PersonaList] edit persona';
    constructor(public persona: Persona) {}
  }
  export class CancelEdit {
    static readonly type = '[PersonaEdit] cancel edit persona';
  }
  export class SubmitEdit {
    static readonly type = '[PersonaEdit] update persona';
  }

  export class Add {
    static readonly type = '[PersonaList] add persona';
    constructor() {}
  }
  export class CancelAdd {
    static readonly type = '[PersonaList] cancel add persona';
    constructor() {}
  }
  export class SubmitAdd {
    static readonly type = '[PersonaList] submit add persona';
    constructor() {}
  }

  export class Delete {
    static readonly type = '[PersonaList] delete persona';
    constructor(public persona: Persona) {}
  }

  export class PersonaSelected {
    static readonly type = '[PersonaModuleAp] persona selected';
    constructor(public persona: Persona) {}
  }

  export class PersonaIdSelected {
    static readonly type = '[PersonaModuleAp] persona by id selected';
    constructor(public personaId: string) {}
  }

  export class PersonaChanged {
    static readonly type = '[PersonaModuleAp] persona changed';
    constructor(public personaId: string) {}
  }

}
