import {Component, EventEmitter, Output} from '@angular/core';
import {Observable} from "rxjs";
import {Wod} from "../../../model/wod";
import {Store} from "@ngxs/store";
import {AppointmentState} from "../../../state/appointment.state";
import {Participant} from "../../../model/participant";

@Component({
  selector: 'app-data-v2',
  templateUrl: './data-v2.component.html',
  styleUrls: ['./data-v2.component.scss']
})
export class DataV2Component {
  public timeslot$: Observable<Wod>;
  @Output() participantSelected = new EventEmitter<Participant>();

  constructor(private store: Store) {
    this.timeslot$ = this.store.select(AppointmentState.currentAppointment)
  }
}
