import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {ServiceProvider} from "../services/service-provider";
import {UserApi} from "../services/user-api.service";
import {Session} from "../services/session";
import {ActivatedRoute, Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ApiError} from "../services/error";
import {ServiceProviderModuleApi} from "../service-provider/service-provider-module-api";

@Component({
  selector: 'app-service-provider-deeplink',
  templateUrl: './service-provider-deeplink.component.html',
  styleUrls: ['./service-provider-deeplink.component.scss']
})
export class ServiceProviderDeeplinkComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription[] = [];
  private identifier: string;

  constructor(private _api: UserApi, private _session: Session,
              private _router: Router, route: ActivatedRoute, private _snackBar: MatSnackBar,
              private serviceProviderModuleApi: ServiceProviderModuleApi) {
    this.identifier = route.snapshot.paramMap.get("identifier");
  }

  ngOnInit(): void {
    this._subscriptions.push(this._api.getServiceProvider(this.identifier)
      .subscribe(
        sp => this.select(sp),
        () => {
          this.onApiError(new ApiError("error.providers.notfound"));
          this._router.navigateByUrl("/service-provider-select")
        }
      ));
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(it => it.unsubscribe());
  }

  private onApiError(error: ApiError) {
    this._snackBar.open(error.getDisplayMessage(), "OK", {duration: 5000});
  }

  select(sp: ServiceProvider) {
    this.serviceProviderModuleApi.setServiceProvider(sp);
    this._router.navigate(["/wods"]);
  }
}
