import {createSelector} from "@ngrx/store";
import {CustomerState, featureKey} from "./customer.reducer";

export const name = createSelector(
  state => state[featureKey],
  (state: CustomerState) => {
    return {firstname: state.settings.firstname, lastname: state.settings.lastname};
  }
)

export const nofiticationConfiguration = createSelector(
  state => state[featureKey],
  (state: CustomerState) => {
    return state.notificationConfigurations;
  }
)

export const feeds = createSelector(
  state => state[featureKey],
  (state: CustomerState) => {
    return state.feeds;
  }
)
