<div class="item" *ngIf="serviceProvider">
<!--  <img *ngIf="serviceProvider.hasLogo()" (click)="select(serviceProvider)"-->
<!--       src="{{serviceProvider.logo?.uri}}" alt="Logo von {{serviceProvider.name}}" class="logo">-->

  <div class="name" (click)="showDetails(serviceProvider)">{{serviceProvider.name}}</div>

  <div class="adress" (click)="showDetails(serviceProvider)">
    <div>{{serviceProvider.address.line1}}</div>
    <div>{{serviceProvider.address.zip}} {{serviceProvider.address.town}}</div>
  </div>

  <mat-icon (click)="select(serviceProvider)">keyboard_arrow_right</mat-icon>


  <div class="info" (click)="showDetails(serviceProvider)">
    <div *ngIf="member" class="member">Mitglied</div>
    <div *ngIf="!member" class="public">Öffentlich</div>
  </div>
</div>
