<ng-container *ngIf="timeslot$ | async as timeslot">
  <ng-container *ngIf="timeslot.instructors?.length > 0">
    <div class="detail-section">
      <div class="line">
        <div class="label">Trainer</div>
        <div class="value">
          <div *ngFor="let instructor of timeslot.instructors" class="instructor">{{instructor}}</div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>


