import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from "./login/login.component";
import {AuthGuard} from "./guards/auth-guard.service";
import {BookingListComponent} from "./booking-list/booking-list.component";
import {PasswordChangeComponent} from "./password-change/password-change.component";
import {ServiceProviderSelectComponent} from "./service-provider-select/service-provider-select.component";
import {PasswordResetComponent} from "./password-reset/password-reset.component";
import {InvitationComponent} from "./customer/invitation/invitation.component";
import {ConfirmEmailComponent} from "./customer/invitation/confirm-email/confirm-email.component";
import {ImpressumComponent} from "./impressum/impressum.component";
import {ConfirmEmailChangeWithTokenComponent} from "./confirm-email-change-with-token/confirm-email-change-with-token.component";
import {ServiceProviderDeeplinkComponent} from "./service-provider-deeplink/service-provider-deeplink.component";
import {ServiceProviderDetailComponent} from "./service-provider-detail/service-provider-detail.component";
import {InfoComponent} from "./info/info.component";
import {TokenLoginComponent} from "./login/token-login/token-login.component";
import {LoginByLinkComponent} from "./login/login-by-link/login-by-link.component";
import {ServiceProviderSearchComponent} from "./service-provider-search/service-provider-search.component";

const routes: Routes = [
  //TODO fallback url handlin / bsp. signin ohne token
  //TODO prüfen welche routen per authguard gesichert werden sollen
  {path: "impressum", component: ImpressumComponent},
  {path: "login", component: LoginComponent},
  {path: "login-by-link", component: LoginByLinkComponent},
  {path: "signin/:token", component: TokenLoginComponent},

  {path: "invite", component: InvitationComponent},
  {path: "login-and-register", component: InvitationComponent},

  {path: "confirmEmail/:token", component: ConfirmEmailComponent},
  {path: 'confirmEmailChange/:token', component: ConfirmEmailChangeWithTokenComponent},

  {path: "reset-password", component: PasswordResetComponent},
  {path: "passwordChange/:token", component: PasswordChangeComponent},
  {path: "service-provider-search", component: ServiceProviderSearchComponent, canActivate: [AuthGuard]},
  {path: "service-provider-select", component: ServiceProviderSelectComponent, canActivate: [AuthGuard]},
  {path: "service-provider-select/:id", component: ServiceProviderDetailComponent, canActivate: [AuthGuard]},
  {path: "sp/:identifier", component: ServiceProviderDeeplinkComponent, canActivate: [AuthGuard]},
  {path: "bookings", component: BookingListComponent, canActivate: [AuthGuard]},
  {path: "info", component: InfoComponent, data: {title: 'Info / Rechtliches', back: '/'}},
  {
    path: "register",
    loadChildren: () => import('./customer/login-and-register/login-and-register.module').then(m => m.LoginAndRegisterModule),
  },
  {
    path: '',
    loadChildren: () => import('./appointment/appointment.module').then(m => m.AppointmentModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'appointments',
    loadChildren: () => import('./appointment/appointment.module').then(m => m.AppointmentModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'ts',
    loadChildren: () => import('./appointment/appointment.module').then(m => m.AppointmentModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'customer',
    loadChildren: () => import('./customer/customer.module').then(m => m.CustomerModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'news',
    loadChildren: () => import('./news/news.module').then(m => m.NewsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'persona',
    loadChildren: () => import('./persona/persona.module').then(m => m.PersonaModule),
    canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
