import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {TimeslotAttribute} from "../../../model/timeslot_attribute";
import {FormControl} from "@angular/forms";
import {Store} from "@ngxs/store";
import {AppointmentDetailActions} from "../../../state/appointment-detail.actions";
import {Subscription} from "rxjs";
import AttributeValueChanged = AppointmentDetailActions.AttributeValueChanged;

@Component({
  selector: 'attribute-boolean',
  templateUrl: './boolean.component.html',
  styleUrls: ['./boolean.component.scss']
})
export class BooleanComponent implements OnInit, OnChanges, OnDestroy {
  @Input() attribute: TimeslotAttribute;
  @Input() disabled: boolean
  @Input() readonly: boolean
  value: string;
  control : FormControl;
  private subscriptions: Subscription[] = [];

  constructor(private store: Store) {
    this.control = new FormControl();
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.control.valueChanges.subscribe(it => {
        this.store.dispatch(new AttributeValueChanged(this.attribute.id, it))
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.control.setValue(this.attribute.value, {emitEvent: false});
    this.disabled ? this.control.disable({emitEvent: false}) : this.control.enable({emitEvent: false});
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(it => it.unsubscribe());
  }
}
