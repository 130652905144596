import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {UserApi} from "../../services/user-api.service";
import {ApiError} from "../../services/error";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Router} from "@angular/router";

@Component({
  selector: 'app-change-mail',
  templateUrl: './change-mail.component.html',
  styleUrls: ['./change-mail.component.scss']
})
export class ChangeMailComponent implements OnInit {
  formGroup: FormGroup;
  private newEmail: string;

  constructor(private _fb: FormBuilder, private api: UserApi, private snackBar: MatSnackBar, private router: Router) {
    this.formGroup = this._fb.group({
      email: ['', [Validators.required]],
    })
  }

  ngOnInit(): void {
  }

  send() {
    this.newEmail = this.formGroup.get('email').value;
    this.api.changeMail(this.newEmail)
      .subscribe(
        () => {
          this.onSuccess();
        },
        (error: ApiError) => {
          this.onFailure(error);
        }
      );
  }

  onSuccess() {
    this.snackBar.open(`Wir haben Dir eine E-Mail an ${this.newEmail} geschickt.`, "OK", {duration: 8000});
    this.router.navigate(["/"]);
  }

  onFailure(error: ApiError) {
    this.snackBar.open(error.getDisplayMessage(), "OK", {duration: 8000});
  }
}
