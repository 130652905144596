import {Action, createReducer, MetaReducer, on} from '@ngrx/store';
import {environment} from '../../../environments/environment';
import {NotificationConfiguration} from "../model/notification-configuration";
import {ApiActions} from "../../actions/action-types";
import {Feeds} from "../model/feeds";

export interface CustomerState {
  notificationConfigurations: NotificationConfiguration[],
  feeds: Feeds,
  settings: {
    firstname: string,
    lastname: string,
  }
}

export const initialState: CustomerState = {
  notificationConfigurations: [],
  feeds: null,
  settings: {
    firstname: "",
    lastname: ""
  }
}

export const featureKey = 'customer';
export const reducers = createReducer(
  initialState,
  on(ApiActions.loadNameSuccess, (state, action) => {
    return {...state, settings: {firstname: action.firstname, lastname: action.lastname}}
  }),
  on(ApiActions.loadNameError, (state) => {
    return {...state, settings: {firstname: "", lastname: ""}}
  }),
  on(ApiActions.changeNameSuccess, (state, action) => {
    return {...state, settings: {firstname: action.firstname, lastname: action.lastname}}
  }),

  on(ApiActions.notificationSettingsLoaded, (state, action) => {
    return {...state, notificationConfigurations: action.configurations}
  }),

  on(ApiActions.notificationSettingChanged, (state, action) => {
    let configs = state.notificationConfigurations
      .map(it => it.clone())

    configs
      .filter(it => it.key == action.key)
      .forEach(it => {it.mail = action.mail; it.telegram = action.telegram});

    return {...state, notificationConfigurations: configs}
  }),

  on(ApiActions.loadFeedsSuccess, (state, action) => {
    return {...state, feeds: action.feeds}
  }),


)


export function reducer(state: CustomerState | undefined, action: Action) {
  return reducers(state, action);
}

export const metaReducers: MetaReducer<CustomerState>[] = !environment.production ? [] : [];
