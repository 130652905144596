import { Component } from '@angular/core';
import {Observable} from "rxjs";
import {Wod} from "../../model/wod";
import {Store} from "@ngxs/store";
import {AppointmentState} from "../../state/appointment.state";

@Component({
  selector: 'appointment-detail-subscription-condition-hint',
  templateUrl: './subscription-condition-hint.component.html',
  styleUrls: ['./subscription-condition-hint.component.scss']
})
export class SubscriptionConditionHintComponent  {

  public appointment$: Observable<Wod>;
  personaId$: Observable<string | undefined>;
  freeVisible = false;

  constructor(private store: Store) {
    this.appointment$ = this.store.select(AppointmentState.currentAppointment)
    this.personaId$ = this.store.select(AppointmentState.persona);
  }

  toggleFreeDescription() {
    this.freeVisible = !this.freeVisible;
  }
}
