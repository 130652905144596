<ng-container *ngIf="(attributes$ | async).length > 0">
  <div class="detail-section-no-border items">
    <ng-container *ngFor="let attribute of attributes$ | async;trackBy:getAttributeId">
      <ng-container [ngSwitch]="attribute.type">
        <attribute-string *ngSwitchCase="'STRING'" [attribute]="attribute" [disabled]="disabled" [readonly]="subscribed"></attribute-string>
        <attribute-enum *ngSwitchCase="'ENUM'" [attribute]="attribute" [disabled]="disabled"  [readonly]="subscribed"></attribute-enum>
        <attribute-boolean *ngSwitchCase="'BOOLEAN'" [attribute]="attribute" [disabled]="disabled" [readonly]="subscribed"></attribute-boolean>
        <attribute-date *ngSwitchCase="'DATE'" [attribute]="attribute" [disabled]="disabled" [readonly]="subscribed"></attribute-date>
        <attribute-agreement *ngSwitchCase="'AGREEMENT'" [attribute]="attribute" [disabled]="disabled" [readonly]="subscribed"></attribute-agreement>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
