import {Component} from '@angular/core';
import {UserApi} from "../../services/user-api.service";
import {ApiError} from "../../services/error";
import {MessageService} from "../../shared/message.service";
import {AppActions} from "../../actions/action-types";
import {NgxsAppActions} from "../../actions/ngxs/app.actions";
import {Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {AppState} from "../../actions";
import {Store as NgxsStore} from "@ngxs/store";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-remove-account',
  templateUrl: './remove-account.component.html',
  styleUrls: ['./remove-account.component.scss']
})
export class RemoveAccountComponent {
  form: FormGroup

  constructor(private api: UserApi,
              private messageService: MessageService,
              private router: Router,
              private store: Store<AppState>,
              private ngxsStore: NgxsStore,
              private fb: FormBuilder
  ) {
    this.form = this.fb.group(
      {
        password: ['', Validators.required]
      }
    )
  }

  removeAccount() {
    this.api.removeAccount(this.form.get('password').value).subscribe(
      () => {
        this.messageService.showInfo("Dein Konto wurde erfolgreich gelöscht.");
        this.store.dispatch(AppActions.logout());
        this.ngxsStore.dispatch(new NgxsAppActions.Logout());
        this.router.navigate(["/login"]);
      },
      (error: ApiError) => {
        console.log(error.getDisplayMessage());
        this.messageService.showError(error.getDisplayMessage());
      }
    );
  }
}
