import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {filter} from "rxjs/operators";
import {UserApi} from "./services/user-api.service";
import {Session} from "./services/session";
import {ServiceProviderModuleApi} from "./service-provider/service-provider-module-api";
import {SwUpdate} from "@angular/service-worker";
import {CheckForUpdateService} from "./services/check-for-update.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Zeitfrei';

  private activatedUrl: string;

  constructor(private router: Router, private api: UserApi,
              private serviceProviderModuleApi: ServiceProviderModuleApi,
              private session: Session, private swUpdate: SwUpdate, private updateService: CheckForUpdateService) {
  }

  ngOnInit(): void {
    this.activatedUrl = '/bookings';
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => this.activatedUrl = event.urlAfterRedirects);

    this.loadTranslation();
    this.updateService.init();
  }

  isBottomNavigationEnabled() {
    return this.activatedUrl != "/login";
  }

  private loadTranslation() {
    let identifier = this.session.serviceProviderIdentifier;
    if (!identifier)
      return;

    this.api.getServiceProvider(identifier)
      .subscribe(sp => {
        this.serviceProviderModuleApi.setServiceProvider(sp);
      });
  }

}
