import {Component, Input} from '@angular/core';
import {UserAttribute} from "../../../../model/user-attribute";
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'app-invitation-user-attributes',
  templateUrl: './invitation-user-attributes.component.html',
  styleUrls: ['./invitation-user-attributes.component.scss']
})
export class InvitationUserAttributesComponent {
  @Input() attributes: UserAttribute[];
  @Input() form: FormGroup;
}
