<ng-container *ngIf="personaId$ | async as personaId">
  <mat-card (click)="clicked()"
            [ngClass]="{
       'mat-elevation-z3': true,
      'item': true,
      'item-subscribed': timeslot.isSubscribed(personaId),
      'item-canceled': timeslot.canceled,
      'item-requested': timeslot.isRequested(personaId),
      'item-waitinglist': timeslot.isOnWaitinglist(personaId),
      'item-cansubscribe': timeslot.canSubscribe(personaId),
      'item-canunsubscribe': timeslot.canUnsubscribe(personaId)
  }">
    <mat-card-title>
      <div class="header">{{timeslot.name}}</div>
      <div class="detail">
        <ng-container *ngIf="!timeslot.isConfirmed(personaId) && !timeslot.isOnWaitinglist(personaId) && !timeslot.isRequested(personaId) && !timeslot.informationalOnly">
          <span *ngIf="timeslot.freeSlots <= 0" class="fully_booked">ausgebucht</span>
          <span *ngIf="timeslot.freeSlots == 1">1 freier Platz</span>
          <span *ngIf="timeslot.freeSlots > 1 ">{{timeslot.freeSlots}} freie Plätze</span>
        </ng-container>
        <span *ngIf="timeslot.isRequested(personaId)" class="subscribed">wartet auf Bestätigung</span>
        <span *ngIf="timeslot.isOnWaitinglist(personaId)" class="subscribed">auf Warteliste</span>
        <span *ngIf="timeslot.isConfirmed(personaId)" class="subscribed">gebucht</span>
      </div>
    </mat-card-title>
    <mat-card-content>
      <div class="date_access_check">
        <div>
          {{timeslot.start | amDateFormat: 'HH:mm'}} - {{timeslot.end | amDateFormat: 'HH:mm'}} Uhr
        </div>
        <div *ngIf="timeslot.accessCheck != 'NONE'" matTooltip="Zugangsbeschränkung">{{ 'ACCESS_CHECK_'+timeslot.accessCheck | translate }}</div>
      </div>

      <div class="detail-section-no-border" *ngIf="timeslot.instructors.length > 0">
        <div class="line">
          <div class="label">Trainer</div>
          <div class="value">
            <div *ngFor="let instructor of timeslot.instructors" class="instructor">{{instructor}}</div>
          </div>
        </div>

      </div>
    </mat-card-content>

  </mat-card>
</ng-container>

