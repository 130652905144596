<div id="terminlayout">
  <div class="title">Termindarstellung</div>
  <div class="description">Zur Darstellung der Termine kannst Du zwischen einer fortlaufenden Liste oder der
    tagesbasierten Ansicht wählen. Beide haben Ihre Vorteile - entscheide selbst.</div>
  <div class="action">
    <mat-radio-group [(ngModel)]="appointmentLayout" (change)="appointmentLayoutChanged()">
      <mat-radio-button value="list">Listenansicht</mat-radio-button>
      <mat-radio-button value="day">Tagesansicht</mat-radio-button>
    </mat-radio-group>
  </div>
</div>
