import {Component, OnInit} from '@angular/core';
import {Store} from "@ngxs/store";
import {PersonaActions} from "../state/persona.actions";
import {Observable} from "rxjs";
import {Persona} from "../model/persona";
import {PersonaState} from "../state/persona.state";
import {ConfirmDialogComponent, ConfirmDialogModel} from "../../shared/confirm-dialog/confirm-dialog.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
  items$: Observable<Persona[]>;

  constructor(private store: Store, private dialog: MatDialog) {
    this.items$ = this.store.select(PersonaState.personas);
    this.store.dispatch(new PersonaActions.LoadAll());
  }

  ngOnInit(): void {
  }

  edit(persona: Persona) {
    this.store.dispatch(new PersonaActions.Edit(persona))
  }

  delete(persona: Persona) {
    let dialogData: ConfirmDialogModel;
    dialogData = new ConfirmDialogModel(
      "Hinweis",
      `Willst Du diese Persona ${persona.firstname} ${persona.lastname} wirklich löschen?
      Die vorhandenen Zuordnungen zu einem Anbieter werden gelöscht und die Persona kann nicht wieder hergestellt werden.`);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult == true)
        this.store.dispatch(new PersonaActions.Delete(persona));
    });
  }

  add() {
    this.store.dispatch(new PersonaActions.Add())
  }
}
