import { Component, OnInit } from '@angular/core';
import {Store} from "@ngxs/store";
import {AppointmentDetailActions} from "../../../state/appointment-detail.actions";
import ApplyInstructor = AppointmentDetailActions.ApplyInstructor;
import UnapplyInstructor = AppointmentDetailActions.UnapplyInstructor;
import {AppointmentState} from "../../../state/appointment.state";
import {Observable} from "rxjs";
import {Wod} from "../../../model/wod";
import AppointmentChanged = AppointmentDetailActions.AppointmentChanged;

@Component({
  selector: 'app-instructor-action-bar',
  templateUrl: './instructor-action-bar.component.html',
  styleUrls: ['./instructor-action-bar.component.scss']
})
export class InstructorActionBarComponent implements OnInit {
  public timeslot$: Observable<Wod>;
  public personaId$: Observable<string | undefined>;

  constructor(private store: Store) {
    this.timeslot$ = this.store.select(AppointmentState.currentAppointment)
    this.personaId$ = this.store.select(AppointmentState.persona);
  }

  ngOnInit(): void {
  }

  apply(timeslotId: string, personaId: string): void {
    this.store.dispatch(new ApplyInstructor(timeslotId, personaId)).subscribe(
      () => this.store.dispatch(new AppointmentChanged(timeslotId))
    );
  }

  unapply(timeslotId: string, personaId: string): void {
    this.store.dispatch(new UnapplyInstructor(timeslotId, personaId)).subscribe(
      () => this.store.dispatch(new AppointmentChanged(timeslotId))
    );
  }
}
