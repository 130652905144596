import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {Session} from "../services/session";
import {filter} from "rxjs/operators";
import {ApiError} from "../services/error";
import {UserApi} from "../services/user-api.service";
import {MessageService} from "../shared/message.service";

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent {
  resetForm: FormGroup;
  private email: string;

  constructor(fb: FormBuilder, private _router: Router, private session: Session, private api: UserApi,
              private messageService: MessageService) {
    this.resetForm = fb.group(
      {
        email: ['', [Validators.required]],
      }
    )

    this.resetForm.valueChanges
      .pipe(
        filter(() => this.resetForm.valid),
      )
      .subscribe(value => {
        this.email = value.email;
      })
  }

  submit() {
    this.api.sendPasswordMail(this.email)
      .subscribe(
        () => {
          this.onSuccess();
        },
        (error: ApiError) => {
          this.onFailure(error);
        }
      );
  }

  onSuccess() {
    this.messageService.showSuccess(`Wir haben Dir eine Mail an ${this.email} geschickt.`);
    this._router.navigate(["/login"]);
  }

  onFailure(error: ApiError) {
    this.messageService.showError(error.getDisplayMessage());
  }

  gotoLogin() {
    this._router.navigate(["/login"]).then();
  }
}
