<div class="item">
  <div class="icon"><mat-icon>attachment</mat-icon></div>
  <div class="title">Datenschutz</div>
  <div class="description">
    <a href="https://zeitfrei.app/assets/legal/nutzungsvereinbarung.pdf">Nutzungsvereinbarung</a><br />
    <a href="https://zeitfrei.app/assets/legal/datenschutz.pdf">Datenschutz</a><br />
  </div>
</div>
<div class="item" routerLink="change-email">
  <div class="icon"><mat-icon>email</mat-icon></div>
  <div class="title">Support</div>
  <div class="description">Bei Fragen und Anregungen erreichst Du unseren Support unter <a href="mailto:support@zeitfrei.app">support@zeitfrei.app</a>.</div>
</div>
<div class="item">
  <div class="icon"><mat-icon>info</mat-icon></div>
  <div class="title">Version

  </div>
  <div class="description">
    <div>{{version}}</div>
  </div>
  <div class="action">
    <button mat-stroked-button (click)="checkForUpdate()" [disabled]="checking">check for updates...</button>
  </div>
</div>
<app-impressum></app-impressum>
