import {Component, EventEmitter, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {repeatPasswordValidator} from "../../repeat-password-listener";

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent {
  formGroup: FormGroup;
  @Output() onSubmit = new EventEmitter<{oldPassword: string, newPassword: string}>();

  constructor(fb: FormBuilder) {
    this.formGroup = fb.group({
      old_password: ['', [Validators.required]],
      new_password_1: ['', [Validators.required]],
      new_password_2: ['', [Validators.required]],
    }, {validators: repeatPasswordValidator})
  }

  send() {
    console.log("submit");
    this.onSubmit.emit({oldPassword: this.formGroup.get('old_password').value, newPassword: this.formGroup.get('new_password_1').value})
  }
}
