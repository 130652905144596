<ng-container *ngIf="wod$ | async as wod">
  <ng-container *ngIf="wod != undefined">
    <div class="toolbar">
      <div class="title">{{wod.name}}</div>
      <mat-icon class="close" [mat-dialog-close]>clear</mat-icon>
    </div>
    <appointment-detail-data (participantSelected)="particpantSelected($event)"></appointment-detail-data>
  </ng-container>
</ng-container>

<ng-container *ngIf="loading$ | async">
  Lade Daten - bitte warten
</ng-container>

