import {HttpClient} from "@angular/common/http";
import {Session} from "../../services/session";
import {Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {AppState} from "../../actions";
import {TerminusApi} from "../../services/terminus-api";
import {Injectable} from "@angular/core";
import {catchError, map} from "rxjs/internal/operators";
import {Persona} from "../model/persona";

@Injectable()
export class Api extends TerminusApi {
  constructor(http: HttpClient, session: Session, router: Router, store: Store<AppState>) {
    super(http, session, router, store);
  }

  loadAll() {
    let url = "/api/v2/cain/user/personas";
    return this.http
      .get<Persona[]>(url, {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleError(err)),
        map(response => {
          return (<any[]>response).map(it => {
            let l = {firstname: it.firstname ?? '', lastname: it.name ?? '', id: it.id};
            return l;
          })
        })
      )
  }

  update(id: string, values: { firstname: string; name: string }) {
    let url = "/api/v2/cain/user/personas/" + id;
    return this.http
      .put(url, {firstname: values.firstname, name: values.name}, {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleError(err))
      );
  }

  add(values: { firstname: string; name: string }) {
    let url = "/api/v2/cain/user/personas/";
    return this.http
      .post(url, {firstname: values.firstname, name: values.name}, {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleError(err))
      );
  }

  delete(id: string) {
    let url = "/api/v2/cain/user/personas/" + id;
    return this.http
      .delete(url, {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleError(err))
      );
  }


}
