import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from "@angular/common/http";
import {Observable} from "rxjs";

import {catchError, map} from "rxjs/internal/operators";
import {Booking} from "./booking";
import {TerminusApi} from "./terminus-api";
import {ServiceProvider} from "./service-provider";
import {Session} from "./session";
import {Router} from "@angular/router";
import {Base64} from "../shared/base64";
import {InvitationInfos} from "./invitation-infos";
import {AppState} from "../actions";
import {Store} from "@ngrx/store";
import {NotificationConfiguration} from "../customer/model/notification-configuration";
import {Feeds} from "../customer/model/feeds";
import {ApiActions} from "../actions/action-types";
import {RegistrationData} from "../customer/invitation/confirm-email/model/registration-data";

@Injectable()
export class UserApi extends TerminusApi {
  constructor(http: HttpClient, session: Session, router: Router, store: Store<AppState>) {
    super(http, session, router, store);
  }

  getBookings(personaid: string): Observable<Booking[]> {
    let url = "/api/v2/cain/user/subscriptions/" + personaid;
    return this.http
      .get<any[]>(url, {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleError(err)),
        map(response => {
          return response.map(Booking.fromJson)
        }),
      )
  }

  /**
   * erstellt einen Nutzer aufgrund einer Einladung
   */
  invite(email: string, token: string, serviceProvider: string) {
    let url = "/api/v2/cain/customer-invite/create";
    return this.http
      .post(url, {email: email, invitationToken: token, serviceProvider: serviceProvider})
  }

  /**
   * lädt die Infos für eine Einladung
   * @param token
   */
  getInvitationInfos(token: string): Observable<InvitationInfos> {
    let url = `/api/v2/cain/customer-invite/${token}/info`;
    return this.http
      .get<any>(url, {})
      .pipe(
        catchError(err => this.handleError(err)),
        map(data => InvitationInfos.fromJson(data)),
      )
  }

  /**
   * aktiviert einen Nutzer aufgrund einer Einladung
   */
  activateUser(token: string, registrationData?: RegistrationData) {
    let url = "/api/v2/cain/customer-invite/confirm-with-token";

    let body: any = {token};

    if (registrationData) {
      if (registrationData.firstname)
        body.firstname = registrationData.firstname
      if (registrationData.lastname)
        body.name = registrationData.lastname
      if (registrationData.password)
        body.password = registrationData.password
      if (registrationData.userAgreement)
        body.userAgreement = '1'

      if (registrationData.attributes) {
        let attributes = [];
        for (let key in registrationData.attributes) {
          attributes.push({identifier: key, value: registrationData.attributes[key]});
        }
        body.attributes = attributes;
      }
    }

    return this.http
      .post<HttpResponse<any>>(url, body, {observe: "response"})
      .pipe(catchError(err => this.handleError(err)))
  }

  getRegisterdServiceProviders(personaId?: string | undefined): Observable<ServiceProvider[]> {
    let url = (personaId == undefined) ? "/api/v2/cain/user/service-providers" : "/api/v2/cain/user/service-providers/" + personaId;
    return this.http
      .get<any[]>(url, {
        headers: this.getHeaders(),
        params: new HttpParams().append("logoWidth", "50")
      })
      .pipe(
        catchError(err => this.handleError(err)),
        map(response => {
          return response.map(ServiceProvider.fromJson)
        }),
      )
  }

  getPublicServiceProviders(): Observable<ServiceProvider[]> {
    let url = "/api/v2/cain/pub/service-providers";
    return this.http
      .get<any>(url, {
        headers: this.getHeaders(),
        params: new HttpParams()
          .append("logoWidth", "50")
          .append("upaged", "true")
      })
      .pipe(
        catchError(err => this.handleError(err)),
        map(response => {
          return response.content.map(ServiceProvider.fromJson)
        }),
      )
  }

  getServiceProvider(identifier: string): Observable<ServiceProvider> {
    let url = `/api/wodstock-v1/sp/${identifier}`;
    return this.http
      .get<any>(url, {
        headers: this.getHeaders(),
        params: new HttpParams().append("logoWidth", "400")
      })
      .pipe(
        catchError(err => this.handleError(err)),
        map(data => ServiceProvider.fromJson(data)),
      )
  }

  login(username: string, password: string): Observable<HttpResponse<any>> {
    let encodedStr = Base64.encode(username + ":" + password);
    let headers = new HttpHeaders();
    headers = headers
      .append("Authorization", "Basic " + encodedStr)
      .append("X-Requested-With", "XMLHttpRequest");
    return this.http
      .get<HttpResponse<any>>("/api/wodstock-v1/login", {headers: headers, observe: "response"})
      .pipe(
        catchError(err => this.handleError(err, true))
      )
  }

  /**
   * sendet an die E-Mailadresse einen Link zu direkten einloggen
   *
   * @param email
   */
  sendLoginLinkWithEmail(email: string): Observable<any> {
    let url = "/api/v2/email-login/request?portal=app";
    return this.http
      .post(url, {email: email})
      .pipe(
        catchError(err => this.handleError(err))
      )
  }

  /**
   * @param token
   * @param personaId
   * @return token  session token
   */
  verifyEmailLogin(token: string, personaId?: string): Observable<string> {
    let url = "/api/v2/email-login/verify";

    let body: { token: string; persona?: string } = {token: token}
    if (personaId) {
      body = {...body, persona: personaId};
    }

    return this.http
      .post<HttpResponse<void>>(url, body, {headers: this.getHeaders(), observe: "response"})
      .pipe(
        catchError(err => this.handleError(err)),
        map((response: HttpResponse<any>) => response.headers.has("X-Auth-Token") ? response.headers.get("X-Auth-Token") : this.getApiToken())
      )
  }

  logout(): Observable<HttpResponse<any>> {
    return this.http
      .get<HttpResponse<any>>("/api/logout", {headers: this.getHeaders(), observe: "response"})
      .pipe(
        catchError(err => this.handleError(err, true))
      )
  }


  /**
   * lädt die Einstellungen für die Benachrichtigungen
   * @return Observable<NotificationConfiguration[]>
   */
  getNotificationSettings(): Observable<NotificationConfiguration[]> {
    let url = `/api/wodstock-v1/user/settings/notification`;
    return this.http
      .get<any>(url, {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleError(err)),
        map(response => {
          return response.notification.map(NotificationConfiguration.fromJson);
        }),
      )
  }

  /**
   * aktualisiert die Benachrichtigungseinstellung für einen Key
   */
  updateNotificationSetting(key: string, mail: boolean, telegram: boolean) {
    let url = `/api/wodstock-v1/user/settings/notification/` + key;
    return this.http
      .put(url, {mail: mail, telegram: telegram}, {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleError(err)),
      )
  }

  /**
   * setzt ein neues Passwort für den Nutzer
   */
  changePassword(old_password: string, new_password: string) {
    let url = `/api/v2/user/password`;
    return this.http
      .put(url, {prev: old_password, new: new_password}, {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleError(err)),
      )
  }

  /**
   * setzt ein initiales Passwort für den Nutzer
   */
  setPassword(password: string) {
    let url = `/api/v2/user/password`;
    return this.http
      .put(url, {new: password}, {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleError(err)),
      )
  }

  /**
   * prüft ob der Nutzer schon ein Passwort gesetzt hat
   */
  public hasPassword(): Observable<boolean> {
    let url = `/api/v2/user/has-password`;

    return this.http
      .get<{ passwordSet: boolean }>(url, {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleError(err)),
        map((data: { passwordSet: boolean }) => data.passwordSet)
      )
  }

  /**
   * setzt für den Nutzer ein neues Passwort
   * @param password
   * @param token
   */
  changePasswordWithToken(password: string, token: string) {
    let url = "/api/wodstock-v1/password/change-with-token";
    return this.http
      .put(url, {token: token, new: password})
      .pipe(
        catchError(err => this.handleError(err))
      )
  }

  /**
   * setzt einen neuen Namen für die Persona
   */
  changeName(personaId: string, firstname: string, lastname: string) {
    let url = `/api/v2/cain/user/personas/` + personaId;
    return this.http
      .put(url, {firstname: firstname, name: lastname}, {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleError(err)),
      )
  }

  /**
   * ermittelt den Namen für die Persona
   */
  loadName(personaId: string): Observable<{ firstname: string, lastname: string }> {
    let url = `/api/v2/cain/user/personas/` + personaId;
    return this.http
      .get<any>(url, {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleError(err)),
        map(data => {
          return {firstname: data.firstname, lastname: data.name}
        }),
      )
  }

  /**
   * sendet dem Nutzer eine Mail in der ein Link ist, mit der er sein Passwort zurücksetzen kann
   * @param email
   */
  sendPasswordMail(email: string) {
    let url = "/api/wodstock-v1/password/send-password-mail";
    return this.http
      .post(url, {user_identifier: email})
      .pipe(
        catchError(err => this.handleError(err))
      )
  }


  /**
   * initiert den Prozeß zum ändern der E-Mailadresse
   * @param newEmail
   */
  changeMail(newEmail: string) {
    let url = "/api/wodstock-v1/user/email";
    return this.http
      .put(url, {newEmail: newEmail, admin: false}, {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleError(err))
      )
  }

  /**
   * bestätigt die Änderung der E-Mail-Adresse mit einem Token
   * @param token
   */
  confirmEmailChange(token: string) {
    let url = "/api/wodstock-v1/user/email-confirm-with-token";
    return this.http
      .post(url, {token: token}, {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleError(err))
      )
  }

  /**
   * lädt die Feeds eines Nutzers, z.B. den ICal-Feed
   */
  loadFeeds(): Observable<Feeds> {
    let url = "/api/v2/cain/user/feed-links";
    return this.http
      .get<any>(url, {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleError(err)),
        map(data => Feeds.fromJson(data)),
      )
  }

  resetIcalFeed() {
    let url = "/api/v2/cain/user/feed-links/ical";
    return this.http
      .delete(url, {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleError(err)),
        map(() => ApiActions.resetIcalFeedSuccess())
      )
  }

  removeAccount(password: string) {
    let url = "/api/v2/cain/user";

    return this.http.request('delete', url, {body: {password: password}, headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleError(err)),
      )
  }
}
