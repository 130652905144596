import { Component, OnInit } from '@angular/core';
import {Observable} from "rxjs";
import {Wod} from "../../../model/wod";
import {Store} from "@ngxs/store";
import {AppointmentState} from "../../../state/appointment.state";

@Component({
  selector: 'app-instructor-list',
  templateUrl: './instructor-list.component.html',
  styleUrls: ['./instructor-list.component.scss']
})
export class InstructorListComponent implements OnInit {
  public timeslot$: Observable<Wod>;

  constructor(private store: Store) {
    this.timeslot$ = this.store.select(AppointmentState.currentAppointment)
  }

  ngOnInit(): void {
  }


}
