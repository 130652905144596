import {UserAttribute} from "../customer/model/user-attribute";

export enum Userstate {
  New,
  Existing
}

export class InvitationInfos {
  serviceProvider: string
  userState: Userstate
  attributes: UserAttribute[];

  static fromJson(data: any) : InvitationInfos {
    let result = new InvitationInfos();
    result.serviceProvider = data.serviceProvider
    result.userState = data.userState == 'existing' ? Userstate.Existing : Userstate.New
    result.attributes = data.attributes;
    return result;
  }
}
