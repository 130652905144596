import {Component, OnInit} from '@angular/core';
import {Store} from "@ngxs/store";
import {AppointmentActions} from "../state/appointment.actions";
import DaySelected = AppointmentActions.DaySelected;
import * as moment from 'moment';
import {MatDialogRef} from "@angular/material/dialog";
import {AppointmentState} from "../state/appointment.state";

@Component({
  selector: 'app-day-selector',
  templateUrl: './day-selector.component.html',
  styleUrls: ['./day-selector.component.scss']
})
export class DaySelectorComponent implements OnInit{
  selectedDate: Date;
  maxDate = moment().add("2", "years").toDate();

  constructor(private store: Store, public dialogRef: MatDialogRef<DaySelectorComponent>) {
  }

  ngOnInit(): void {
    this.store.select(AppointmentState.selectedDay).subscribe(it => {
        this.selectedDate = it.toDate()
      }
    )
  }

  selectedChange(event: any) {
    this.store.dispatch(new DaySelected(moment(event)));
    this.dialogRef.close()
  }
}
