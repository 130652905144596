<p>Um Deinen Konto zu aktivieren musst Du nur noch ein paar Angaben für {{serviceProvider.name}} machen.</p>

<p><span class="hint">
    Hinweis</span>: Bitte erleichtere <b>{{serviceProvider.name}}</b> die Arbeit, in dem Du auf die
  Verwendung von Pseudonymen verzichtest.</p>

<ng-container *ngIf="form">
  <form [formGroup]="form" autocomplete="off" novalidate>
    <ng-container *ngIf="!onlyRequestAttributes">
      <div>
        <mat-form-field appearance="outline">
          <mat-label>Vorname</mat-label>
          <input id="firstname" matInput formControlName="firstname" required>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="outline">
          <mat-label>Nachname</mat-label>
          <input id="lastname" matInput formControlName="lastname" required>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="outline">
          <mat-label>Passwort</mat-label>
          <input id="password" matInput formControlName="password" [type]="hide ? 'password' : 'text'" required>
          <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                  [attr.aria-pressed]="hide">
            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </ng-container>

    <app-invitation-user-attributes [attributes]="userAttributes" [form]="attributesGroup"></app-invitation-user-attributes>

    <div *ngIf="!onlyRequestAttributes">
      <mat-checkbox formControlName="user_agreement">Ich akzeptiere die <a href="/src/assets/legal/nutzungsvereinbarung.pdf" target="_blank">Nutzungsvereinbarung</a>.
      </mat-checkbox>
    </div>

    <div class="actions">
      <button id="submit" mat-stroked-button color="warn" (click)="activateNewUser()"
              [disabled]="!this.form.valid">
        Aktivieren
      </button>
    </div>
  </form>
</ng-container>
