<app-notification-item title="Anmeldung"
                       description="Du erhälst eine E-Mail, wenn Du Dich zu einem Termin anmeldest."
                       key="timeslot-subscribed">
</app-notification-item>

<app-notification-item title="Abmeldung"
                       description="Du erhälst eine E-Mail, wenn Du Dich von einem Termin abmeldest."
                       key="timeslot-unsubscribed">
</app-notification-item>

<app-notification-item title="Position in der Warteliste"
                       description="Sobald sich Deine Position in der Warteliste ändert, erhälst Du eine E-Mail."
                       key="waiting-list-move-up">
</app-notification-item>

<app-notification-item title="Warteliste -> Teilnehmer"
                       description="Wenn Du von der Warteliste in die Teilnehmerliste übernommen wirst, erhälst Du eine E-Mail."
                       key="waiting-list-move-up-to-participants">
</app-notification-item>

<app-notification-item title="Terminänderung"
                       description="Wenn ein Termin geändert wird, für den Du angemeldet bist, erhälst Du eine E-Mail."
                       key="timeslot-modified">
</app-notification-item>

<app-notification-item title="Trainer-Änderung"
                       description="Wenn sich ein Trainer bei einem von Dir gebuchten Termin ändert, erhälst Du eine E-Mail"
                       key="instructor-modified">
</app-notification-item>

<app-notification-item title="Buchungsänderung"
                       description="Eine Buchung wurde durch einen Admin erstellt oder geändert."
                       key="subscription-by-admin">
</app-notification-item>

<app-notification-item title="Einladung zu einem Termin"
                       description="Wenn Du zu einem Termin eingeladen wirst, erhälst Du eine E-Mail."
                       key="timeslot-invitation">
</app-notification-item>

<app-notification-item title="Terminabsage"
                       description="Wenn ein Termin abgesagt wird, für den Du angemeldet bist, erhälst Du eine E-Mail."
                       key="timeslot-canceled">
</app-notification-item>

<app-notification-item title="Anmeldebestätigung"
                       description="Für Termine, bei denen die Anmeldung bestätigt werden muss, erhälst Du eine E-Mail, wenn Deine Anmeldung bestätigt oder abgelehnt wurde."
                       key="timeslot-subscription-confirmed-or-rejected">
</app-notification-item>

<app-notification-item title="Passwortbestätigung"
                       description="Wenn Du Dein Passwort erfolgreich geändert hast, erhälst Du darüber eine E-Mail."
                       key="password-changed-notice">
</app-notification-item>

<app-notification-item title="Infos zur Mitgliedschaft"
                       description="Wenn sich dein Mitgliedschaftsstatus bei Vereinen oder Dienstleistern ändert, erhälst Du darüber eine E-Mail."
                       key="sp-membership">
</app-notification-item>
