import {Injectable} from "@angular/core";
import {Moment} from "moment";
import {TimeslotAttribute} from "./timeslot_attribute";
import {WaitingList} from "./waiting-list";
import * as moment from "moment";

@Injectable()
export class PersonaState {
  public personaId: string;
  public subscriptionState: string;
  public subscriptionPersons: number = 1;
  public canSubscribe: boolean;
  public canUnsubscribe: boolean;
  public subscriptionOpeningAt: Moment;
  public subscriptionClosingAt: Moment;
  public unsubscriptionClosingAt: Moment;
  public subscribeCondition: string;
  public bookingCode: string;
  public bookingReference: string | null;
  public attributes: TimeslotAttribute[] = [];
  public waitingList: WaitingList;
  public instructorState: 'NONE' | 'APLLIED';
  public canApplyInstructor: boolean;
  public canUnapplyInstructor: boolean;

  public static fromModel(model: PersonaState): PersonaState {
    let result = Object.assign(new PersonaState(), model);
    result.attributes = model.attributes?.map(a => TimeslotAttribute.fromModel(a));
    result.waitingList = WaitingList.fromModel(model.waitingList);
    return result;
  }

  public static fromJson(id: string, data: any): PersonaState {
    let result = new PersonaState();

    result.personaId = id;
    result.subscribeCondition = data.subscribeCondition;
    result.subscriptionOpeningAt = data.subscriptionOpeningAt ? moment(data.subscriptionOpeningAt) : null;
    result.subscriptionClosingAt = moment(data.subscriptionClosingAt);
    result.unsubscriptionClosingAt = moment(data.unsubscriptionClosingAt);
    result.canSubscribe = data.canSubscribe;
    result.canUnsubscribe = data.canUnsubscribe;
    result.subscriptionState = data.subscriptionState;
    result.subscriptionPersons = data.subscriptionPersons;
    result.waitingList = WaitingList.fromJson(data.waitingList);
    result.bookingCode = data.bookingCode;
    result.bookingReference = data.bookingReference;
    result.attributes = data.attributes?.map(a => TimeslotAttribute.fromJson(a));
    result.canApplyInstructor = data.canApplyInstructor;
    result.canUnapplyInstructor = data.canUnapplyInstructor;
    result.instructorState = data.instructorState;

    return result;
  }

}
