import {Component} from '@angular/core';
import {Observable} from "rxjs";
import {Wod} from "../../model/wod";
import {Store} from "@ngxs/store";
import {AppointmentState} from "../../state/appointment.state";

@Component({
  selector: 'app-allocation',
  templateUrl: './allocation.component.html',
  styleUrls: ['./allocation.component.scss']
})
export class AllocationComponent {
  timeslot$: Observable<Wod>;
  templateVersion: string;
  personaId$: Observable<string | undefined>;

  constructor(private store: Store) {
    this.timeslot$ = this.store.select(AppointmentState.currentAppointment)
    this.templateVersion = this.store.selectSnapshot(AppointmentState.detailDialogVersion);
    this.personaId$ = this.store.select(AppointmentState.persona);
  }
}
