<div id="filter">
  <mat-form-field>
    <mat-select [formControl]="filter" placeholder="Termine">
      <mat-option *ngFor="let filter of filterList" [value]="filter.value">{{filter.label}}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field *ngIf="hasResources$ | async">
    <mat-select [formControl]="resource" placeholder="Resource">
      <mat-option *ngFor="let resource of resources$ | async"
                  [value]="resource.id">{{resource.displayname}}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field *ngIf="hasSections$ | async">
    <mat-select [formControl]="section" placeholder="{{ 'COMMON.SECTION' | translate }}">
      <mat-option *ngFor="let section of sections$ | async"
                  [value]="section.id">{{section.name}}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
