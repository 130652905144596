<mat-toolbar color="primary" class="toolbar" *ngIf="(isAuthenticated$ | async) else anonymous">

  <ng-container *ngIf="!title">
    <button mat-icon-button [matMenuTriggerFor]="menuAuthenticated">
      <mat-icon>menu</mat-icon>
    </button>
    <div class="title" *ngIf="heading">{{heading}}</div>
    <div class="title" *ngIf="!heading && (serviceProviderName$ | async) != ''">{{serviceProviderName$ | async}}</div>
    <div class="title" *ngIf="!heading && (serviceProviderName$ | async) == ''">Zeitfrei</div>
  </ng-container>

  <ng-container *ngIf="title">
    <a class="backlink" routerLink="{{back}}">
      <mat-icon>arrow_back</mat-icon>
    </a>
    <span class="title">{{title}}</span>
  </ng-container>

  <top-menu-persona></top-menu-persona>

</mat-toolbar>

<ng-template #anonymous class="toolbar">
  <mat-toolbar color="primary">
    <ng-container *ngIf="!title">
      <button mat-icon-button [matMenuTriggerFor]="menuAnonymous">
        <mat-icon>menu</mat-icon>
      </button>
      <div class="title">Zeitfrei</div>
    </ng-container>

    <ng-container *ngIf="title">
      <a class="backlink" routerLink="{{back}}">
        <mat-icon>arrow_back</mat-icon>
      </a>
      <span class="title">{{title}}</span>
    </ng-container>

  </mat-toolbar>
</ng-template>

<mat-menu #menuAuthenticated>
  <a mat-menu-item [routerLink]="['customer', 'settings']">
    <span>Einstellungen</span>
  </a>
  <a mat-menu-item routerLink="news">
    <span>News</span>
  </a>
  <a mat-menu-item [routerLink]="['customer', 'settings', 'contract']">
    <span>Verträge / Guthaben</span>
  </a>
  <button mat-menu-item [matMenuTriggerFor]="selfservice">
    <span>Darstellung</span>
  </button>
  <button mat-menu-item routerLink="info">
    <span>Info / Rechtliches</span>
  </button>
  <button mat-menu-item (click)="logout()">
    <span>Logout</span>
  </button>
</mat-menu>

<mat-menu #selfservice="matMenu">
  <button mat-menu-item (click)="switchToDayView()">pro Tag</button>
  <button mat-menu-item (click)="switchToListView()">als Liste</button>
</mat-menu>

<mat-menu #menuAnonymous>
  <button mat-menu-item routerLink="/login">
    <span>Login</span>
  </button>
  <button mat-menu-item routerLink="/register">
    <span>Registrieren</span>
  </button>
  <button mat-menu-item routerLink="/reset-password">
    <span>Neues Passwort setzen</span>
  </button>
  <button mat-menu-item routerLink="/info">
    <span>Info / Rechtliches</span>
  </button>
</mat-menu>



<!--<mat-toolbar color="primary" id="toolbar">-->
<!--  <ng-container *ngIf="!title">-->
<!--    <button id="menu" mat-icon-button [matMenuTriggerFor]="menu" *ngIf="(isAuthenticated$ | async)">-->
<!--      <mat-icon>menu</mat-icon>-->
<!--    </button>-->
<!--    &lt;!&ndash;    <img id="logo" src="../../assets/icons/icon-100x100.png" alt="logo"/>&ndash;&gt;-->
<!--    <div class="title" *ngIf="(serviceProviderName$ | async) != ''">{{serviceProviderName$ | async}}</div>-->
<!--  </ng-container>-->

<!--  <ng-container *ngIf="title">-->
<!--    <a class="backlink" routerLink="{{back}}">-->
<!--      <mat-icon>arrow_back</mat-icon>-->
<!--    </a>-->
<!--    <span class="title">{{title}}</span>-->
<!--  </ng-container>-->

<!--  <ng-container *ngIf="(isAuthenticated$ | async) && (news$ | async).length > 0">-->
<!--    <div id="news" (click)="news()">News ({{(news$ | async).length}})</div>-->
<!--  </ng-container>-->

<!--  <top-menu-persona></top-menu-persona>-->

<!--  <mat-menu #menu>-->
<!--    <ng-container *ngIf="!(isAuthenticated$ | async)">-->
<!--      <button mat-menu-item routerLink="/login" id="tm-login">-->
<!--        <span>Login</span>-->
<!--      </button>-->
<!--    </ng-container>-->

<!--    <ng-container *ngIf="(isAuthenticated$ | async)">-->
<!--      <button mat-menu-item (click)="settings()">-->
<!--        <span>Einstellungen</span>-->
<!--      </button>-->
<!--      <button mat-menu-item (click)="news()">-->
<!--        <span>News</span>-->
<!--      </button>-->
<!--      <button mat-menu-item [matMenuTriggerFor]="selfservice">-->
<!--        <span>Darstellung</span>-->
<!--      </button>-->
<!--      <button mat-menu-item routerLink="/info">-->
<!--        <span>Info / Rechtliches</span>-->
<!--      </button>-->
<!--      <button mat-menu-item (click)="logout()">-->
<!--        <span>Logout</span>-->
<!--      </button>-->
<!--    </ng-container>-->

<!--  </mat-menu>-->

<!--  <mat-menu #selfservice="matMenu">-->
<!--    <button mat-menu-item (click)="switchToDayView()">pro Tag</button>-->
<!--    <button mat-menu-item (click)="switchToListView()">als Liste</button>-->
<!--  </mat-menu>-->
<!--</mat-toolbar>-->
