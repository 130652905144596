<div *ngIf="timeslot" class="container">
  <div>
    <mat-icon class="prev nav" *ngIf="hasPrev()" (click)="prev()">arrow_back_ios</mat-icon>
  </div>
  <div class="content" (click)="showTimeslot()" [@openClose]='state'>
    <div class="title">{{timeslot.name}}</div>
    <div class="date">{{timeslot.start | amDateFormat: 'dd, D. MMM YY'}}</div>
  </div>
  <div>
    <mat-icon class="next nav" *ngIf="hasNext()" (click)="next()">arrow_forward_ios</mat-icon>
  </div>
</div>


