import { Component, OnInit } from '@angular/core';
import {PersonaModuleApi} from "../../../persona/persona-module-api";
import {Observable} from "rxjs";
import {Persona} from "../../../persona/model/persona";
import {Store} from "@ngxs/store";
import {AppointmentState} from "../../state/appointment.state";

@Component({
  selector: 'timeslot-detail-persona',
  templateUrl: './persona.component.html',
  styleUrls: ['./persona.component.scss']
})
export class PersonaComponent implements OnInit {
  public personas$: Observable<Persona[]>;
  public personaId$: Observable<string>;

  constructor(private personaModuleApi: PersonaModuleApi, private store: Store) {
    this.personas$ = this.personaModuleApi.personas$();
    this.personaId$ = this.store.select(AppointmentState.persona);
  }

  ngOnInit(): void {
  }

  selectPersona(persona: Persona) {
    this.personaModuleApi.selectPersona(persona)
  }

  signature(persona: Persona) {
    return (persona.firstname[0]?.toUpperCase()??'') + "" + (persona.lastname[0]?.toUpperCase()??'')
  }
}
