<div class="section">
  <div class="header">Neues Passwort setzen</div>
  <div class="body">
    <form [formGroup]="resetForm" autocomplete="off" novalidate>
      <div>
        <mat-form-field appearance="outline">
          <mat-label>E-Mail</mat-label>
          <input id="email" matInput formControlName="email" tabindex="1">
        </mat-form-field>
      </div>
      <div class="actions">
        <a mat-stroked-button color="primary" (click)="gotoLogin()" tabindex="-1">
          Abbrechen
        </a>
        <button mat-stroked-button color="warn" (click)="submit()" [disabled]="!this.resetForm.valid" tabindex="2">
          Senden
        </button>
      </div>
    </form>
  </div>
</div>
