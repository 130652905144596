import * as moment from "moment";
import {Moment} from "moment";
import {IParticipant, Participant} from "./participant";
import {IVideoLink, VideoLink} from "./video-link";
import {PersonaState} from "./persona-state";
import {Attachment} from "../state/attachment";

export interface IAppointment {
  id: string;
  name: string;
  maxMembers: number;
  numberOfMembers: number;
  start: Moment;
  end: Moment;
  durationInMinutes: number;
  description: string;
  canceled: boolean;
  serviceProvider: string,
  serviceProviderVariant: string,
  participants: IParticipant[];
  detailsAvailable: boolean;
  videoLink: IVideoLink;
  personaStates: PersonaState[];
  attachments: Attachment[];
  accessCheck: "COVID_2G" | "COVID_2G_PLUS" | "COVID_3G" | "NONE";

  personaStateForPersona(personaId: string): PersonaState | undefined;
}

export class Wod implements IAppointment {
  public id: string;
  public uid: string;
  public name: string;
  public maxMembers: number;
  public numberOfMembers: number;
  public start: Moment;
  public end: Moment;
  public durationInMinutes: number;
  public description: string;
  public canceled: boolean;
  public participants: Participant[] = [];
  public detailsAvailable: boolean = false;
  public videoLink: VideoLink = null;
  // public resources: Resource[] = [];
  public informationalOnly: boolean = false;
  public promotion: boolean = false;
  public serviceProvider: string;
  public serviceProviderVariant: string;
  public personaStates: PersonaState[] = [];
  public attachments: Attachment[] = [];
  public instructors: string[];
  public accessCheck: "COVID_2G" | "COVID_2G_PLUS" | "COVID_3G" | "NONE";


  public static fromModel(model: IAppointment): Wod {
    let result = Object.assign(new Wod(), model);
    if (model.videoLink)
      result.videoLink = VideoLink.fromModel(model.videoLink);
    else
      result.videoLink = null;

    result.start = model.start.clone();
    // result.end = model.end.clone();

    result.participants = model.participants?.map(p => Participant.fromModel(p));
    result.personaStates = model.personaStates?.map(a => PersonaState.fromModel(a));
    result.attachments = model.attachments?.map(a => Attachment.fromModel(a));

    return result;
  }

  public static fromJson(data: any): Wod {
    let result = new Wod();
    result.id = data.id;
    result.uid = data.uid;
    result.name = data.name;
    result.maxMembers = data.maxParticipants;
    result.numberOfMembers = data.allParticipants;
    result.start = moment(data.start);
    result.end = moment(data.end);
    result.durationInMinutes = data.durationMinutes;
    result.description = data.descriptionRendered;
    result.canceled = data.canceled;
    result.detailsAvailable = data.detailsAvailable;
    result.informationalOnly = data.informationalOnly;
    result.promotion = data.promotion;
    result.serviceProvider = data.serviceProvider;
    result.serviceProviderVariant = data.serviceProviderVariant;
    result.participants = data.participants?.map(p => Participant.fromJson(p));
    result.attachments = data.attachments?.map(p => Attachment.fromJson(p));
    result.accessCheck = data.accessCheck;
    if (data.videolink != undefined) {
      result.videoLink = VideoLink.fromJson(data.videolink);
    }
    result.personaStates = [];
    for (let id in data.personaStates) {
      result.personaStates.push(PersonaState.fromJson(id, data.personaStates[id]));
    }
    result.instructors = data.instructors?.map(it => it.name);

    // result.resources = data.resources?.map(r => Resource.fromJsonV2(r));

    return result;
  }

  public personaStateForPersona(personaId: string): PersonaState | undefined {
    let result;
    if (personaId == null)
      result = this.personaStates[0];
    else
      result = this.personaStates.find(it => it.personaId == personaId);

    if (result == undefined)
      result = this.personaStates[0];

    return result;
  }

  isSubscriptionSlotStarted(personaId: string): boolean {
    return this.personaStateForPersona(personaId)?.subscriptionOpeningAt?.isBefore(moment());
  }

  isSubscriptionSlotClosed(personaId: string): boolean {
    return this.personaStateForPersona(personaId)?.subscriptionClosingAt.isBefore(moment());
  }

  isUnsubscriptionSlotClosed(personaId: string): boolean {
    return this.personaStateForPersona(personaId)?.unsubscriptionClosingAt.isBefore(moment());
  }

  /**
   * sind die Anmeldungen für diesen Termin frei, also ohne Belastung des Kontingent oder des Prepaidguthaben
   * Das kann z.B. für außerordentliche Feiern oder Versammlungen genutzt werden
   * @return boolean
   */
  isFree(personaId: string): boolean {
    return this.personaStateForPersona(personaId)?.subscribeCondition == 'OPEN_TO_CUSTOMERS';
  }

  /**
   * Anzahl der noch freien Slots
   */
  get freeSlots() {
    return this.maxMembers - this.numberOfMembers;
  }

  /**
   * benötigen die Anmeldungen für diesen Termin eine Bestätigung durch einen Admin
   * @return boolean
   */
  needsConfirmation(personaId: string): boolean {
    return this.personaStateForPersona(personaId)?.subscribeCondition == 'CONFIRMATION';
  }

  isSubscriptionDisabled(personaId: string): boolean {
    return this.personaStateForPersona(personaId)?.subscribeCondition == 'NO_SUBSCRIPTION_ALLOWED';
  }

  /**
   * ist der Nutzer für diesen Termin noch gar nicht gemeldet
   * @return boolean
   */
  isNotSubscribed(personaId: string) {
    return !this.informationalOnly && this.personaStateForPersona(personaId)?.subscriptionState == 'NOT_SUBSCRIBED';
  }

  /**
   * muss die Anmeldung für den Nutzer noch bestätigt werden
   * @return boolean
   */
  isRequested(personaId: string) {
    return this.personaStateForPersona(personaId)?.subscriptionState == 'REQUESTED';
  }

  /**
   * ist der Nutzer angemeldet
   * @return boolean
   */
  isSubscribed(personaId: string) {
    return this.personaStateForPersona(personaId)?.subscriptionState == 'CONFIRMED';
  }

  /**
   * ist der Nutzer auf der Teilnehmerliste
   * @return boolean
   */
  isConfirmed(personaId: string) {
    return this.personaStateForPersona(personaId)?.subscriptionState == 'CONFIRMED';
  }

  /**
   * ist der Nutzer auf der Warteliste
   * @return boolean
   */
  isOnWaitinglist(personaId: string) {
    return this.personaStateForPersona(personaId)?.waitingList.position > 0
  }

  hasAttributes(personaId: string) {
    return this.personaStateForPersona(personaId)?.attributes.length > 0;
  }

  setAttribute(personaId: string, id: string, value: string) {
    this.personaStateForPersona(personaId).attributes.find(it => it.id == id).value = value;
  }

  setNumberOfPersons(personaId: string, numberOfPersons: number) {
    this.personaStateForPersona(personaId).subscriptionPersons = numberOfPersons;
  }

  canSubscribe(personaId: string) {
    return this.personaStateForPersona(personaId).canSubscribe;
  }

  canUnsubscribe(personaId: string) {
    return this.personaStateForPersona(personaId).canUnsubscribe;
  }

  canSubscribeToWaitingList(personaId: string) {
    return this.personaStateForPersona(personaId).waitingList.canSubscribe;
  }

  canUnsubscribeToWaitingList(personaId: string) {
    return this.personaStateForPersona(personaId).waitingList.canUnsubscribe;
  }

  isSubscribedOnWaitingList(personaId: string) {
    return this.personaStateForPersona(personaId).waitingList.subscribed;
  }

  getPositionOnWaitingList(personaId: string) {
    return this.personaStateForPersona(personaId).waitingList.position;
  }

  subscriptionOpeningAt(personaId: string) {
    // console.log("1 = ", this.personaStateForPersona(personaId).subscriptionOpeningAt);
    return this.personaStateForPersona(personaId).subscriptionOpeningAt;
  }

  subscriptionClosingAt(personaId: string) {
    return this.personaStateForPersona(personaId).subscriptionClosingAt;
  }

  unsubscriptionClosingAt(personaId: string) {
    return this.personaStateForPersona(personaId).unsubscriptionClosingAt;
  }

  hasBookingCodeFor(personaId: string) {
    return this.personaStateForPersona(personaId).bookingCode != '';
  }

  bookingCode(personaId: string) {
    return this.personaStateForPersona(personaId).bookingCode;
  }

  hasBookingReferenceFor(personaId: string) {
    return this.personaStateForPersona(personaId).bookingReference !== null;
  }

  bookingReferenceFor(personaId: string) {
    return this.personaStateForPersona(personaId).bookingReference;
  }

  subscriptionPersons(personaId: string) {
    return this.personaStateForPersona(personaId).subscriptionPersons;
  }

  attributes(personaId: string) {
    return this.personaStateForPersona(personaId).attributes;
  }

  isFull(): boolean {
    return this.numberOfMembers >= this.maxMembers;
  }

  private static formatTime(date): string {
    return date.toISOString().replace(/-|:|\.\d+/g, '');
  };

  public getGoogleCalendarLink(): string {
    var startTime = Wod.formatTime(this.start);
    var endTime = Wod.formatTime(this.end)

    return encodeURI([
      'https://www.google.com/calendar/render',
      '?action=TEMPLATE',
      '&text=' + (this.name || ''),
      '&dates=' + (startTime || ''),
      '/' + (endTime || ''),
      '&details=' + (this.description || ''),
      '&sprop=&sprop=name:'
    ].join(''));
  }


  getIosCalendarLink() {
    const startTime = Wod.formatTime(this.start);
    const endTime = Wod.formatTime(this.end);
    return encodeURI(
      'data:text/calendar;charset=utf8,' + [
        'BEGIN:VCALENDAR',
        'VERSION:2.0',
        'BEGIN:VEVENT',
        'URL:' + document.URL,
        'DTSTART:' + startTime,
        'DTEND:' + endTime,
        'SUMMARY:' + this.name,
        'DESCRIPTION:' + this.description,
        'END:VEVENT',
        'END:VCALENDAR'].join('\n'));
  }


  hasParticipantsDetails() {
    return (this.participants != undefined) && (this.participants.length > 0);
  }

  hasParticipants() {
    return this.numberOfMembers > 0;
  }


  isInformationalOnly() {
    return this.informationalOnly;
  }

  isSubscriptionPersonsSupported() {
    return this.serviceProviderVariant == 'museum';
  }

  isBookingCodeSupported() {
    return this.serviceProviderVariant == 'museum';
  }

  /**
   * Anzahl an noch freien Terminen für diese Slot
   */
  public freeAppointments(): number {
    return this.maxMembers - this.numberOfMembers;
  }

  /**
   * müssen Details, z.B. Anzahl Personen oder Attribute, zu einer Buchung abgefragt werden
   * @param personaId
   */
  needsBookingDetails(personaId: string) {
    return this.isSubscriptionPersonsSupported() || this.hasAttributes(personaId)
  }

}
