import { createReducer, on } from '@ngrx/store';
import * as StoreActions from './store.actions';

export const storeFeatureKey = 'news';

export interface State {

}

export const initialState: State = {

};


export const reducer = createReducer(
  initialState,

  on(StoreActions.loadStores, state => state),

);

