import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {UserApi} from "../../services/user-api.service";
import {AppActions} from "../../actions/action-types";
import {Session} from "../../services/session";
import {PersonaModuleApi} from "../../persona/persona-module-api";
import {MessageService} from "../../shared/message.service";
import {Store} from "@ngrx/store";
import {AppState} from "../../actions";

@Component({
  selector: 'app-token-login',
  templateUrl: './token-login.component.html',
  styleUrls: ['./token-login.component.scss']
})
export class TokenLoginComponent implements OnInit {
  private readonly token: string;
  private readonly serviceProviderId: string;
  private readonly timeslotId: string;
  private readonly personaId: string;

  constructor(route: ActivatedRoute,
              private router: Router,
              private api: UserApi,
              private session: Session,
              private personaModuleApi: PersonaModuleApi,
              private messageService: MessageService,
              private store: Store<AppState>) {
    this.token = route.snapshot.paramMap.get("token");
    this.serviceProviderId = route.snapshot.queryParamMap.get("sp")
    this.timeslotId = route.snapshot.queryParamMap.get("ts")
    this.personaId = route.snapshot.queryParamMap.get("p")
  }

  ngOnInit(): void {
    this.api.verifyEmailLogin(this.token, this.personaId).subscribe(
      (session_token) => this.onSuccess(session_token),
      () => this.onFailure()
    );
  }

  private onSuccess(token: string) {
    this.session.login(token);
    this.personaModuleApi.reloadPersonas();

    if (this.personaId) {
      this.personaModuleApi.selectPersonaById(this.personaId);
    }

    if (this.timeslotId) {
      this.router.navigate(['/ts/detail', this.timeslotId]);
      return;
    }

    if (this.serviceProviderId) {
      this.router.navigate(['/sp', this.serviceProviderId]);
      return;
    }

    this.router.navigate(["/service-provider-select"]);
  }

  onFailure() {
    this.store.dispatch(AppActions.logout());
    this.messageService.showError("Der Link ist ungültig.");
  }
}
