import {Component, EventEmitter, Output} from '@angular/core';
import {Wod} from "../model/wod";
import {Store} from "@ngxs/store";
import {AppointmentState} from "../state/appointment.state";
import {Observable} from "rxjs";
import {Participant} from "../model/participant";
import {MatBottomSheet} from "@angular/material/bottom-sheet";
import {ParticipantBottomActionComponent} from "./participant-bottom-action/participant-bottom-action.component";
import {Api} from "../state/api";
import {AppointmentDetailActions} from "../state/appointment-detail.actions";

@Component({
  selector: 'app-detail',
  templateUrl: './detail-dialog.component.html',
  styleUrls: ['./detail-dialog.component.scss']
})
export class DetailDialogComponent {
  public wod$: Observable<Wod>;
  public loading$: Observable<boolean>;
  @Output("modified") onUpdated = new EventEmitter<Wod>();

  constructor(private store: Store, private bottomSheet: MatBottomSheet, private api: Api) {
    this.wod$ = store.select(AppointmentState.currentAppointment)
    this.loading$ = store.select(AppointmentState.loadingCurrentAppointment)
  }

  particpantSelected(participant: Participant) {
    if (!participant.covid)
      return

    const ref = this.bottomSheet.open(ParticipantBottomActionComponent, {data: {participant: participant}})

    ref.afterDismissed().subscribe(
      (result) => {
        if (result == undefined)
          return;

        let timeslotId = this.store.selectSnapshot(AppointmentState.currentAppointment).id;

        if (result.action == 'presence') {
          this.api.markParticipantAsPresent(timeslotId, participant.userId).subscribe(
            () => {
              this.store.dispatch(new AppointmentDetailActions.AppointmentChanged(timeslotId))
            }
          );
        } else if (result.action == 'absence') {
          this.api.markParticipantAsAbsent(timeslotId, participant.userId).subscribe(
            () => {
              this.store.dispatch(new AppointmentDetailActions.AppointmentChanged(timeslotId))
            }
          );
        }
      }
    )
  }

  close() {

  }
}
