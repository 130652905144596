import {HttpClient} from "@angular/common/http";
import {Session} from "../../services/session";
import {Router} from "@angular/router";
import {Injectable} from "@angular/core";
import {TerminusApi} from "../../services/terminus-api";
import {Store} from "@ngrx/store";
import {AppState} from "../../actions";
import {Observable} from "rxjs";
import {catchError} from "rxjs/internal/operators";

export interface PersonaState {
  contractType: string; //CONTINGENT_WEEKLY, CONTINGENT_MONTHLY, FLATRATE
  contractUntil: string;
  contingentUnits: number;
  currentContingentUnits: number;
  prepaidUnits: number;
}
@Injectable()
export class CustomerApi extends TerminusApi {
  constructor(http: HttpClient, session: Session, router: Router, store: Store<AppState>) {
    super(http, session, router, store);
  }

  getPersonaState(personaId: string, spIdentifier: string) : Observable<PersonaState>{
    let url = `/api/v2/cain/sp/${spIdentifier}/customer-state/${personaId}`;
    return this.http
      .get<PersonaState>(url, {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleError(err))
      )
  }
}
