<ng-container *ngIf="(attributes$ | async).length > 0 && subscribed">
  <div class="detail-section items">
    <ng-container *ngFor="let attribute of attributes$ | async">
      <ng-container *ngIf="attribute.type != 'AGREEMENT' && attribute.type != 'DATE'">
          <div class="line">
            <div class="label">{{attribute.name}}</div>
            <div class="value">{{attribute.value}}</div>
          </div>
      </ng-container>

      <ng-container *ngIf="attribute.type == 'DATE'">
          <div class="line">
            <div class="label">{{attribute.name}}</div>
            <div class="value">{{attribute.value | amDateFormat: 'dd, D.M.YYYY'}}</div>
          </div>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
