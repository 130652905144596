<div class="section">
  <div class="header">Login via E-Mail</div>
  <div class="body">
    <form [formGroup]="form" autocomplete="off" novalidate>
      <div>
        <mat-form-field appearance="outline">
          <mat-label>E-Mail-Adresse</mat-label>
          <input id="email" matInput formControlName="email" tabindex="1">
        </mat-form-field>
      </div>
      <div class="description">
        <p>Wir senden Dir an die E-Mail-Adresse einen zeitlich begrenzten Link mit dem Du Dich ohne Eingabe eines Passwortes direkt einloggen kannst.</p>

        <p>Hast Du noch kein Konto, wird die Registrierung gleich mit erledigt.</p>

        <p>Dein Login oder Dein neues Konto sind nur eine E-Mail entfernt!</p>
      </div>

      <div class="actions">
        <a mat-stroked-button color="primary" routerLink="/login" tabindex="-1">
          Abbrechen
        </a>
        <button mat-stroked-button color="warn" (click)="submit()" [disabled]="!this.form.valid" tabindex="2">
          Senden
        </button>
      </div>
    </form>
  </div>
</div>
