<app-filter-bar></app-filter-bar>

<ng-container *ngIf="wodsPerDay$ | async as wodsPerDay">
  <div class="items">

    <!-- Tage mit Terminen -->
    <div class="wodsForADay" *ngFor="let item of wodsPerDay">
      <div class="day">{{item.day}}</div>

      <!-- Termine innerhalb eines Tages -->
      <div class="events" *ngFor="let wod of item.wods">
        <app-list-item [timeslot]="wod"></app-list-item>
      </div>
    </div>
  </div>

  <button mat-stroked-button (click)="more()">Mehr Termine</button>
</ng-container>

