<mat-tab-group>
  <mat-tab label="Offene">
    <ng-container *ngFor="let booking of open$ | async">
      <app-booking-item [bookingItem]="booking" (show)="show(booking)"></app-booking-item>
    </ng-container>
  </mat-tab>

  <mat-tab label="Ältere">
    <ng-container *ngFor="let booking of closed$ | async">
      <app-booking-item [bookingItem]="booking" (show)="show(booking)"></app-booking-item>
    </ng-container>
  </mat-tab>
</mat-tab-group>


