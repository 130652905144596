import {HttpClient, HttpParams} from "@angular/common/http";
import {Session} from "../../services/session";
import {Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {AppState} from "../../actions";
import {Observable, of} from "rxjs";
import {catchError, map} from "rxjs/internal/operators";
import {TerminusApi} from "../../services/terminus-api";
import {Resource} from "../model/resource";
import {Injectable} from "@angular/core";
import {Wod} from "../model/wod";
import {Moment} from "moment";
import * as moment from 'moment';
import {AppointmentsCountPerDays} from "../model/AppointmentsCountPerDays";
import {ITimeslotAttribute, TimeslotAttribute} from "../model/timeslot_attribute";
import {Section} from "../model/section";

@Injectable()
export class Api extends TerminusApi {
  constructor(http: HttpClient, session: Session, router: Router, store: Store<AppState>) {
    super(http, session, router, store);
  }

  /**
   * lädt die Resourcen
   */
  getResources(): Observable<Resource[]> {
    let serviceProvider = this.session.serviceProviderIdentifier;

    let url = `/api/v2/cain/sp/${serviceProvider}/resource`;
    return this.http
      .get<Resource[]>(url, {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleError(err)),
      )
  }

  /**
   * lädt die Abteilungen
   */
  getSections(): Observable<Section[]> {
    let serviceProvider = this.session.serviceProviderIdentifier;

    let url = `/api/v2/cain/sp/${serviceProvider}/sections`;
    return this.http
      .get<Section[]>(url, {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleError(err)),
      )
  }

  /**
   * lädt die Termine für den angegebenen Zeitraum
   */
  getAppointments(start: Moment, resourceId: string, sectionId: string, size: number): Observable<Wod[]> {
    let serviceProvider = this.session.serviceProviderIdentifier;

    let startAsString = start.format("YYYY-MM-DD");
    let url = `/api/v2/cain/sp/${serviceProvider}/timeslot`;

    let params = new HttpParams();
    params = params.append('paged', 'false');
    params = params.append('from', startAsString);

    params = params.append('size', size ? size + '' : '20');

    if (resourceId != '') {
      params = params.append('resource', resourceId);
    }
    if (sectionId) {
      params = params.append('section', sectionId);
    }

    return this.http
      .get(url, {headers: this.getHeaders(), params})
      .pipe(
        catchError(err => this.handleError(err)),
        map(response => {
          return (<any[]>response).map(Wod.fromJson)
        }),
      )
  }

  /**
   * lädt den Termin für die angegebene Id
   * Der Termin kann nur geladen werden, wenn er zu dem ausgewählten ServiceProvider passt
   */
  getAppointment(id: string): Observable<Wod> {
    let serviceProvider = this.session.serviceProviderIdentifier;

    let url = `/api/v2/cain/sp/${serviceProvider}/timeslot/${id}`;

    return this.http
      .get(url, {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleError(err)),
        map(Wod.fromJson)
      )
  }

  /**
   * lädt den Termin für die angegebene Id
   * hier wird der ServiceProvider nicht berücksichtigt
   */
  getAppointmentByUid(id: string): Observable<Wod> {
    let url = `/api/v2/cain/timeslot/${id}`;

    return this.http
      .get(url, {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleError(err)),
        map(Wod.fromJson)
      )
  }

  getAppointmentsForADay(selectedDay: moment.Moment, resourceId: string, sectionId: string) {
    let serviceProvider = this.session.serviceProviderIdentifier;

    let startAsString = selectedDay.format("YYYY-MM-DD");
    let endAsString = selectedDay.format("YYYY-MM-DD");
    let url = `/api/v2/cain/sp/${serviceProvider}/timeslot`;

    let params = new HttpParams();
    params = params.append('from', startAsString);
    params = params.append('to', endAsString);

    if (resourceId != '') {
      params = params.append('resource', resourceId);
    }
    if (sectionId) {
      params = params.append('section', sectionId);
    }

    return this.http
      .get(url, {headers: this.getHeaders(), params})
      .pipe(
        catchError(err => this.handleError(err)),
        map(response => {
          return (<any[]>response).map(Wod.fromJson)
        }),
      )
  }

  /**
   * TODO section einbauen
   */
  getPromotedTimeslots() {
    let serviceProvider = this.session.serviceProviderIdentifier;

    let startAsString = moment().format("YYYY-MM-DD");
    let endAsString = moment().add(12, "months").format("YYYY-MM-DD");
    let url = `/api/v2/cain/sp/${serviceProvider}/timeslot-promotion`;

    let params = new HttpParams();
    params = params.append('from', startAsString);
    params = params.append('to', endAsString);

    return this.http
      .get(url, {headers: this.getHeaders(), params})
      .pipe(
        catchError(err => this.handleError(err)),
        map(response => {
          return (<any[]>response).map(Wod.fromJson)
        }),
        map(timeslots => timeslots.filter(timeslot => timeslot.promotion === true))
      )
  }

  subscribePersonaToTimeslot(timeslotId: string, personaId: string, attributes: ITimeslotAttribute[], numberOfPersons: number): Observable<any> {
    let serviceProvider = this.session.serviceProviderIdentifier;
    let url = `/api/v2/cain/sp/${serviceProvider}/timeslot/${timeslotId}/subscribe/${personaId}`;
    let body = {
      numberOfPersons: numberOfPersons,
      attributes: attributes
        .filter(it => it.value != null)
        .map(it => {
          return {id: it.id, value: it.value}
        })
    };

    return this.http
      .post<any>(url, body, {headers: this.getHeaders(), observe: "response"})
      .pipe(
        catchError(err => this.handleError(err))
      )
  }

  /**
   * registriert eine Persona als Trainer für einen Termin
   * @param timeslotId
   * @param personaId
   */
  applyInstructor(timeslotId: string, personaId: string) {
    let serviceProvider = this.session.serviceProviderIdentifier;
    let url = `/api/v2/cain/sp/${serviceProvider}/timeslot/${timeslotId}/instructor/${personaId}`;

    return this.http
      .put<any>(url, {}, {headers: this.getHeaders(), observe: "response"})
      .pipe(
        catchError(err => this.handleError(err))
      )
  }

  /**
   * löscht die Regstrierung einer Persona als Trainer für einen Termin
   * @param timeslotId
   * @param personaId
   */
  unapplyInstructor(timeslotId: string, personaId: string) {
    let serviceProvider = this.session.serviceProviderIdentifier;
    let url = `/api/v2/cain/sp/${serviceProvider}/timeslot/${timeslotId}/instructor/${personaId}`;

    return this.http
      .delete(url, {headers: this.getHeaders(), observe: "response"})
      .pipe(
        catchError(err => this.handleError(err))
      )
  }

  unsubscribePersonaFromTimeslot(timeslot: Wod, personaId: string): Observable<any> {
    let serviceProvider = this.session.serviceProviderIdentifier;
    let url = `/api/v2/cain/sp/${serviceProvider}/timeslot/${timeslot.id}/unsubscribe/${personaId}`;
    return this.http
      .post(url, {}, {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleError(err))
      )
  }

  subscribePersonaToWaitingList(timeslot: Wod, personaId: string, attributes: TimeslotAttribute[]) {
    let serviceProvider = this.session.serviceProviderIdentifier;
    let url = `/api/v2/cain/sp/${serviceProvider}/timeslot/${timeslot.id}/waiting-list/${personaId}`;
    let body = {
      attributes: attributes
        .filter(it => it.value != null)
        .map(it => {
          return {id: it.id, value: it.value}
        })
    };

    return this.http
      .post<any>(url, body, {headers: this.getHeaders(), observe: "response"})
      .pipe(
        catchError(err => this.handleError(err))
      )
  }

  unsubscribePersonaFromWaitingList(timeslot: Wod, personaId: string) {
    let serviceProvider = this.session.serviceProviderIdentifier;
    let url = `/api/v2/cain/sp/${serviceProvider}/timeslot/${timeslot.id}/waiting-list/${personaId}`;
    return this.http
      .delete(url, {headers: this.getHeaders()})
      .pipe(
        catchError(err => this.handleError(err))
      )

  }


  getAppointmentsCountBetweenDates(personaId: string, from: moment.Moment, to: moment.Moment, resource?: string, sectionId?: string): Observable<AppointmentsCountPerDays> {
    if (personaId === null) {
      return of({perDay: new Map<string, number>()})
    }
    let serviceProvider = this.session.serviceProviderIdentifier;

    let fromAsString = from.format("YYYY-MM-DD");
    let toAsString = to.format("YYYY-MM-DD");
    let url = `/api/v2/cain/sp/${serviceProvider}/timeslot-overview/${personaId}`;

    let params = new HttpParams();
    params = params.append('from', fromAsString);
    params = params.append('to', toAsString);
    if (resource && resource != '') {
      params = params.append('resource', resource);
    }
    if (sectionId) {
      params = params.append('section', sectionId);
    }

    return this.http
      .get<AppointmentsCountPerDays>(url, {headers: this.getHeaders(), params})
      .pipe(
        catchError(err => this.handleError(err)),
        map((response: AppointmentsCountPerDays) => {
          return response;
        }),
      );
  }

  /**
   * Teilnehmer wird als anwesend markiert
   */
  markParticipantAsPresent(timeslotId: string, personaId: string) {
    let serviceProvider = this.session.serviceProviderIdentifier;
    let url = `/api/v2/cain/sp/${serviceProvider}/timeslot/${timeslotId}/on-premise-check/${personaId}`;
    let body = {presence: 'PRESENT'}

    return this.http
      .put<any>(url, body, {headers: this.getHeaders(), observe: "response"})
      .pipe(
        catchError(err => this.handleError(err))
      )
  }

  /**
   * Teilnehmer wird als anwesend markiert
   */
  markParticipantAsAbsent(timeslotId: string, personaId: string) {
    let serviceProvider = this.session.serviceProviderIdentifier;
    let url = `/api/v2/cain/sp/${serviceProvider}/timeslot/${timeslotId}/on-premise-check/${personaId}`;
    let body = {presence: 'ABSENT'}

    return this.http
      .put<any>(url, body, {headers: this.getHeaders(), observe: "response"})
      .pipe(
        catchError(err => this.handleError(err))
      )
  }

  /**
   * lädt den Binär-Inhalt des Anhang, nicht die Metadaten
   * @param attachmentId
   */
  getAttachmentContent(attachmentId: string) {
    const url = `/api/v2/timeslot-attachments/${attachmentId}`;

    return this.http
      .get(url, {headers: this.getHeaders(), responseType: 'blob'})
      .pipe(
        catchError(err => this.handleError(err)),
      )
  }

}
