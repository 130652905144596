<form [formGroup]="addForm" ngxsForm="persona.editForm">
  <mat-form-field appearance="outline">
    <mat-label>Vorname</mat-label>
    <input matInput formControlName="firstname">
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Nachname</mat-label>
    <input matInput formControlName="lastname">
  </mat-form-field>

  <div class="actions">
    <button mat-stroked-button (click)="cancel()">Abbrechen</button>
    <button mat-stroked-button (click)="update()" color="accent">Anlegen</button>
  </div>
</form>
