import {NewsMessage} from "./model/newsMessage";
import * as moment from 'moment';

export const NEWS: NewsMessage[] = [
  new NewsMessage(5, moment("2021-07-15"), 'Verträge und Guthaben', 'Ab der Version 1.17.12' +
    ' kannst Du unter (Verträge / Guthaben) deine aktuellen Verträge mit den Anbietern einsehen. Neben der' +
    ' Vertragslaufzeit zeigen wir Dir auch Dein aktuelles Prepaidguthaben an.'
    ,'/customer/settings/contract'),
  new NewsMessage(4, moment("2021-02-07"), 'Terminansicht', 'Ab der Version 1.13.2' +
    ' kannst Du in den Einstellungen -> Darstellung auswählen, wie die Termine dargestellt werden sollen. Zur Auswahl stehen' +
    ' die bekannte Listenansicht oder die neue Ansicht pro Tag.'
    ,'/customer/settings/layout'),
  new NewsMessage(3, moment("2020-11-19"), 'Namen ändern', 'Ab der Version 1.11.2 kannst Du in den ' +
    'Einstellungen Deinen Namen ändern. Dadurch machst Du es Deinem Verein einfacher, Dich richtig zu indentifizieren.'
    ,'/customer/settings/change-name'),
  new NewsMessage(2, moment("2020-11-03"), 'Kalenderintegration', 'Ab der Version 1.9.0 kannst Du Deine gebuchten Termine' +
    ' in Deine Kalendersoftware integrieren. Wir stellen Dir dafür einen ICAL-Feed zur Verfügung, welchen Du in fast' +
    ' jeder Kalendersoftware abonnieren kannst. Den Link dafür findest Du unter den Einstellungen -> Kalenderintegration.',
    '/customer/settings/ical-feed'),
  new NewsMessage(1, moment("2020-09-27"), 'Benachrichtigungen',
    'Seit der Version 1.8.5 kannst Du unter Einstellungen -> Benachrichtigungen für 8 verschieden Ereignisse, wie z.B.' +
    ' Anmeldung oder Terminabsage, einstellen ob wir Dir eine E-Mail schicken sollen. Damit kannst Du Dich von unnötigen E-Mails ' +
    'befreien.',
    '/customer/settings/notifications'),
];
