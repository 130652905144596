import {Component, Input, OnInit} from '@angular/core';
import {NewsMessage} from "../model/newsMessage";
import {Router} from "@angular/router";

@Component({
  selector: 'app-news-item',
  templateUrl: './news-item.component.html',
  styleUrls: ['./news-item.component.scss']
})
export class NewsItemComponent implements OnInit {
  @Input("message") message: NewsMessage;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  goto(route: string) {
    console.log(route);
    if (route)
      this.router.navigate([route]);
  }
}
