export class Attachment {
  contentType: string;
  filename: string;
  id: string;
  name: string;
  size: number;

  public static fromModel(model: Attachment): Attachment {
    let result = Object.assign(new Attachment(), model);
    result.contentType = model.contentType;
    result.filename  = model.filename;
    result.id = model.id;
    result.name = model.name;
    result.size = model.size;
    return result;
  }

  public static fromJson(data: any): Attachment {
    let result = new Attachment();
    result.contentType = data.contentType;
    result.filename  = data.filename;
    result.id = data.id;
    result.name = data.name;
    result.size = data.size;
    return result;
  }
}
