<ng-container *ngIf="timeslot$ | async as timeslot" xmlns="http://www.w3.org/1999/html">
  <ng-container *ngIf="personaId$ | async as personaId">
    <ng-container *ngIf="timeslot.videoLink?.isVisible() && timeslot.isConfirmed(personaId)">
      <div class="detail-section-no-border">
        <div>
          <a class="link" mat-stroked-button color="accent" href="{{timeslot.videoLink.url}}" target="_blank">{{timeslot.videoLink.title}}</a>
        </div>
        <div class="description">{{timeslot.videoLink.description}}</div>
      </div>
    </ng-container>

    <ng-container *ngIf="timeslot.videoLink?.isHidden()">
      <div class="detail-section-no-border">
        <div>Der Link zum Video ist erst nach dem Start des Termin verfügbar.</div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
