import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {TimeslotAttribute} from "../../../model/timeslot_attribute";
import {FormControl} from "@angular/forms";
import {Store} from "@ngxs/store";
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {AppointmentDetailActions} from "../../../state/appointment-detail.actions";
import {Subscription} from "rxjs";
import AttributeValueChanged = AppointmentDetailActions.AttributeValueChanged;
import * as moment from 'moment';

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'DD.MM.YYYY',
    dateA11yLabel: 'DD.MM.YYYY',
    monthYearA11yLabel: 'DD.MM.YYYY',
  },
};

@Component({
  selector: 'attribute-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class DateComponent implements OnInit, OnChanges, OnDestroy {
  @Input() attribute: TimeslotAttribute;
  @Input() disabled: boolean
  @Input() readonly: boolean
  value: string;
  control: FormControl;
  private subscriptions: Subscription[] = [];

  constructor(private store: Store) {
    this.control = new FormControl();
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.control.valueChanges.subscribe(it => {
        this.store.dispatch(new AttributeValueChanged(this.attribute.id, moment(it).format("YYYY-MM-DD")))
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.control.setValue(this.attribute.value, {emitEvent: false});
    this.disabled ? this.control.disable({emitEvent: false}) : this.control.enable({emitEvent: false});
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(it => it.unsubscribe());
  }

}
