<mat-form-field *ngIf="!readonly">
  <mat-label>{{attribute.name}}</mat-label>
  <input matInput [matDatepicker]="picker" [formControl]="control" [required]="attribute.mandatory">
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker disabled="{{disabled}}"></mat-datepicker>
</mat-form-field>

<div class="line" *ngIf="readonly">
  <div class="label">{{attribute.name}}</div>
  <div class="value">{{attribute.valueAsString | amDateFormat: 'dd, D.M.YYYY'}}</div>
</div>

