import {Persona} from "../../persona/model/persona";

export namespace AppointmentDetailActions {
  export class Subscribe {
    static readonly type = '[ActionBar] subscribe member to timeslot';
    constructor(public personen: number) {}
  }

  export class Unsubscribe {
    static readonly type = '[ActionBar] unsubscribe member from timeslot';
  }

  export class ApplyInstructor {
    constructor(public timeslotId: string, public personaId: string) {}
    static readonly type = '[ActionBar] apply as instructor to timeslot';
  }

  export class UnapplyInstructor {
    constructor(public timeslotId: string, public personaId: string) {}
    static readonly type = '[ActionBar] unapply as instructor from timeslot';
  }

  export class SubscribeToWaitingList {
    static readonly type = '[ActionBar] subscribe member to waitingList';
  }

  export class UnsubscribeFromWaitingList {
    static readonly type = '[ActionBar] unsubscribe member from waitingList';
  }

  export class AppointmentChanged {
    static readonly type = '[AppointmentDetail] Appointment changed'

    constructor(public id: string) {
    }
  }

  export class AttributeValueChanged {
    static readonly type = '[AppointmentDetail-Attribute] Attribute changed'

    constructor(public id: string, public value: string) {
    }
  }

  export class NumberOfPersonChanged {
    static readonly type = '[AppointmentDetail-Attribute] number of persons changed'

    constructor(public numberOfPersons: number) {
    }
  }

  export class PersonaSelected {
  static readonly type = '[TimeslotDetail-Persona] persona selected'

    constructor(public persona: Persona) {
    }
  }

}
