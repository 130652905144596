import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {ApiActions, AppActions} from "./action-types";
import {catchError, map, mergeMap, tap} from "rxjs/operators";
import {EMPTY} from "rxjs";
import {UserApi} from "../services/user-api.service";
import {Session} from "../services/session";
import {Router} from "@angular/router";


@Injectable()
export class AppEffects {
  constructor(private actions$: Actions, private api: UserApi,
              private session: Session, private router: Router) {}

  appLogout = createEffect(() => this.actions$.pipe(
    ofType(AppActions.logout),
    mergeMap(() =>
      this.api.logout()
        .pipe(
          map(() => ApiActions.logoutSuccess()),
          catchError(() => EMPTY)
        )
    )),
  );

  invalidSession = createEffect(() => this.actions$.pipe(
    ofType(ApiActions.invalidSession),
    tap(() => {
      this.session.logout();
      this.router.navigate(["/login"]);
    })
  ), {dispatch: false});

  wrongAuthenticationData = createEffect(() => this.actions$.pipe(
    ofType(ApiActions.wrongAuthenticationData),
    tap(() => this.session.logout())
  ), {dispatch: false});

  sessionLogout = createEffect(() => this.actions$.pipe(
    ofType(ApiActions.logoutSuccess),
    tap(() => this.session.logout())
  ), {dispatch: false});
}
