import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Store} from "@ngrx/store";
import {CustomerState} from "../actions/customer.reducer";
import {CustomerActions} from "../actions/action-types";
import {name} from "../actions/customer.selectors";
import {Subscription} from "rxjs";
import {PersonaModuleApi} from "../../persona/persona-module-api";

@Component({
  selector: 'app-change-name',
  templateUrl: './change-name.component.html',
  styleUrls: ['./change-name.component.scss']
})
export class ChangeNameComponent implements OnInit, OnDestroy {
  formGroup: FormGroup;
  private subs: Subscription[] = [];
  private personaId: string;

  constructor(fb: FormBuilder, private store: Store<CustomerState>, private personaModuleApi: PersonaModuleApi) {
    this.formGroup = fb.group({
      firstname: ['', [Validators.required]],
      lastname: [''],
    })

    this.subs.push(
      this.store.select(name).subscribe(it => {
        this.formGroup.get('firstname').setValue(it.firstname);
        this.formGroup.get('lastname').setValue(it.lastname);
      })
    )
  }

  ngOnInit(): void {
    this.subs.push(
      this.personaModuleApi.currentId().subscribe(it => {
        this.store.dispatch(CustomerActions.loadName({personaId: it}));
        this.personaId = it;
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach(it => it.unsubscribe());
  }

  send() {
    this.store.dispatch(CustomerActions.changeName({personaId: this.personaId, firstname: this.formGroup.get('firstname').value, lastname: this.formGroup.get('lastname').value}))
  }
}
