<div>
<ng-container *ngIf="timeslot$ | async as timeslot">
  <timeslot-detail-persona></timeslot-detail-persona>
  <mat-tab-group animationDuration="0ms">
    <mat-tab label="Allgemein" >
      <div class="data">
        <appointment-detail-name-and-time></appointment-detail-name-and-time>
        <appointment-detail-timeframes *ngIf="!timeslot.informationalOnly"></appointment-detail-timeframes>
        <app-instructor-list></app-instructor-list>
        <div  *ngIf="timeslot.description" class="detail-section" [innerHTML]="timeslot.description"></div>
        <app-allocation></app-allocation>
        <appointment-detail-subscription-condition-hint></appointment-detail-subscription-condition-hint>
        <appointment-detail-attributes></appointment-detail-attributes>
        <appointment-detail-action-bar></appointment-detail-action-bar>
        <app-instructor-action-bar></app-instructor-action-bar>
<!--        TODO wieder einbauen <app-share></app-share>-->
      </div>
    </mat-tab>

    <mat-tab [label]="'Teilnehmer ('+timeslot.participants?.length+')'" *ngIf="timeslot.hasParticipantsDetails()">
      <appointment-detail-participants
        [participants]="timeslot.participants"
        (participantSelected)="participantSelected.emit($event)"></appointment-detail-participants>
    </mat-tab>

    <mat-tab [label]="'Anhänge ('+timeslot.attachments.length+')'" *ngIf="timeslot.attachments?.length > 0">
      <app-ts-attachments></app-ts-attachments>
    </mat-tab>

    <mat-tab label="Videolinks" *ngIf="timeslot.videoLink">
      <appointment-detail-video></appointment-detail-video>
    </mat-tab>
  </mat-tab-group>
</ng-container>
</div>
