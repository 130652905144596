import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ServiceProvider} from "../../../../../services/service-provider";
import {UserAttribute} from "../../../../model/user-attribute";
import {RegistrationData} from "../../model/registration-data";

@Component({
  selector: 'app-sp-confirmation-formular',
  templateUrl: './sp-confirmation-formular.component.html',
  styleUrls: ['./sp-confirmation-formular.component.scss']
})
export class SpConfirmationFormularComponent implements OnChanges {
  @Input() serviceProvider: ServiceProvider;
  @Input() userAttributes: UserAttribute[];
  @Input() onlyRequestAttributes: boolean;
  @Output() activate = new EventEmitter<RegistrationData>();
  hide = true;
  form: FormGroup;
  attributesGroup: FormGroup;

  constructor(private fb: FormBuilder) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.onlyRequestAttributes) {
      if (this.onlyRequestAttributes) {
        this.form = this.fb.group(
          {
            attributes: this.attributesGroup
          }
        )
      } else {
        this.form = this.fb.group(
          {
            firstname: ['', [Validators.required]],
            lastname: ['', [Validators.required]],
            password: ['', [Validators.required]],
            user_agreement: [false, [Validators.pattern('true')]],
            attributes: this.attributesGroup
          }
        )
      }
    }

    if (changes.userAttributes) {
      this.updateAttributes(changes.userAttributes.currentValue);
    }
  }

  activateNewUser() {
    if (this.onlyRequestAttributes) {
      this.activate.emit({
        attributes: this.form.get('attributes').value
      })
    } else {
      this.activate.emit({
        firstname: this.form.get('firstname').value,
        lastname: this.form.get('lastname').value,
        password: this.form.get('password').value,
        userAgreement: '1.0',
        attributes: this.form.get('attributes').value
      })
    }
  }

  private updateAttributes(userAttributes: UserAttribute[]) {
    this.form.removeControl('attributes');
    this.attributesGroup = new FormGroup({});
    userAttributes.forEach(it => {
      let ctrl = new FormControl('');
      if (it.mandatory) ctrl.setValidators(Validators.required);
      this.attributesGroup.addControl(it.identifier, ctrl)
    })
    this.form.addControl('attributes', this.attributesGroup);
  }
}
