import {Injectable} from "@angular/core";
import {CanActivate, Router} from "@angular/router";
import {Session} from "../services/session";

@Injectable()

export class AuthGuard implements CanActivate {
  constructor(private session: Session, private router: Router) {
  }

  canActivate() {
    if (this.session.isLoggedIn()) {
      return true;
    } else {
      let link = location.href.replace(location.origin,'')
      if (link != "/") {
        this.session.setDeeplink(link);
      }

      this.router.navigate(["/login"]);
      return false;
    }
  }
}
