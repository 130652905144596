export class ServiceProvider {
  public id: string;
  public name: string;
  public nameLong: string;
  public identifier: string;
  public subtitle: string;
  public website: string;
  public mail: string;
  /**
   * Variante - dient vor allem für das richtige Wording
   * @see https://gitlab.patrix.biz/zeitfrei/terminus-server/-/wikis/Dienstleister-Varianten
   */
  public variant: string;
  public address: Address;
  public phone: Phone;
  public geopos: Geopos;
  public logo: Image;



  public static fromJson(data: any): ServiceProvider {
    let result = new ServiceProvider();
    result.id = data.id;
    result.name = data.name;
    result.nameLong = data.name_long;
    result.identifier = data.identifier;
    result.subtitle = data.subtitle;
    result.website = data.website;
    result.mail = data.mail;
    result.variant = data.variant;
    result.address = Address.fromJson(data.address);
    result.phone = Phone.fromJson(data.phone);
    result.geopos = Geopos.fromJson(data.geopos);
    result.logo = Image.fromJson(data.logo);
    return result;
  }

  hasLogo() {
    return this.logo?.uri
  }
}

export class Address {
  public line1: string;
  public zip: string;
  public town: string;
  public country: string;
  public countryReadable: string;

  public static fromJson(data: any): Address {
    let result = new Address();
    result.line1 = data?.line1;
    result.zip = data?.zip;
    result.town = data?.town;
    result.country = data?.country;
    result.countryReadable = data?.country_readable;
    return result;
  }
}

export class Phone {
  public country: string;
  public normalized: string;
  public readable: string;

  public static fromJson(data: any): Phone {
    let result = new Phone();
    result.country = data?.country;
    result.normalized = data?.normalized;
    result.readable = data?.readable;
    return result;
  }
}

export class Geopos {
  public lat: number;
  public lon: number;

  public static fromJson(data: any): Geopos {
    let result = new Geopos();
    result.lat = data?.lat;
    result.lon = data?.lon;
    return result;
  }
}

export class Image {
  public width: number;
  public height: number;
  public id: string;
  public uri: string;
  public mimeType: string

  public static fromJson(data: any): Image {
    let result = new Image();
    result.width = data?.width;
    result.height = data?.height;
    result.id = data?.id;
    result.mimeType = data?.mime_type;
    result.uri = data?.uri;

    return result;
  }
}
