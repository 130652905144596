import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ServiceProviderRoutingModule} from './service-provider-routing.module';
import {ServiceProviderModuleApi} from "./service-provider-module-api";


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ServiceProviderRoutingModule,
  ],
  providers: [
    ServiceProviderModuleApi
  ]
})
export class ServiceProviderModule { }
