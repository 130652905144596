export interface ITimeslotAttribute {
  id: string;
  name: string
  /**
   * ENUM | BOOLEAN | STRING | DATE
   */
  type: string;
  mandatory: boolean;
  value: string | boolean;
  /**
   * die möglichen Werte wenn der Typ="ENUM"  ist
   */
  values: string[];
}

export class TimeslotAttribute implements ITimeslotAttribute{
  id: string;
  name: string
  /**
   * ENUM | BOOLEAN | STRING | DATE
   */
  type: string;
  mandatory: boolean = false;
  value: string | boolean;
  /**
   * die möglichen Werte wenn der Typ="ENUM"  ist
   */
  values: string[] = [];

  static fromModel(model: ITimeslotAttribute) {
    return Object.assign(new TimeslotAttribute(), model);
  }

  static fromJson(json: any) {
    let result = new TimeslotAttribute();
    result.name = json.name;
    result.id = json.id;
    result.type = json.type;
    result.mandatory = json.mandatory ?? false;
    result.value = (json.type == 'BOOLEAN') ? json.value == 'true' : json.value;
    result.values = json.values;

    return result;
  }

  get valueAsString() : string {
    return <string>this.value
  }
}
