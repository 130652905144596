import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Api} from "./api";
import {PersonaActions} from "./persona.actions";
import {tap} from "rxjs/operators";
import {MessageService} from "../../shared/message.service";
import {Persona} from "../model/persona";
import {ResetForm} from "@ngxs/form-plugin";
import LoadAll = PersonaActions.LoadAll;
import {NgxsAppActions} from "../../actions/ngxs/app.actions";

export interface PersonaStateModel {
  state: string; //list, edit, new
  personas: Persona[];
  editPersona: Persona; // Persona die gerade bearbeitet wird
  editForm: {
    model: { id: string, firstname: string, lastname: string },
    dirty: boolean,
    status: string,
    errors: {}
  }

  persona: Persona; //Persona die in der App gerade verwendet wird
}

const defaultState = {
  state: 'list',
  personas: [],
  persona: null,
  editPersona: null,
  editForm: {
    model: {id: "", firstname: "", lastname: ""},
    dirty: false,
    status: '',
    errors: {}
  },
};

@State<PersonaStateModel>({name: 'persona',defaults: defaultState})
@Injectable()
export class PersonaState {
  constructor(private api: Api, private messageService: MessageService) {
  }

  @Selector()
  static state(state: PersonaStateModel): String {
    return state.state;
  }

  @Selector()
  static personas(state: PersonaStateModel): Persona[] {
    return state.personas;
  }

  @Selector()
  static current(state: PersonaStateModel): Persona {
    return state.persona;
  }

  @Action(NgxsAppActions.Logout)
  logout(ctx: StateContext<PersonaStateModel>) {
    ctx.patchState(defaultState);
  }

  @Action(PersonaActions.LoadAll)
  loadAll(ctx: StateContext<PersonaStateModel>, action: LoadAll) {
    ctx.patchState({personas: []});

    return this.api.loadAll().pipe(
      tap(
        items => {
          ctx.patchState({personas: items});

          if (ctx.getState().persona != null) {
            const selectedPersonaInListedPersonas = items.findIndex(it => it.id === ctx.getState().persona.id) >= 0;
            if (selectedPersonaInListedPersonas) {
              console.log("aktuell gesetzte persona ist in den gelisteten personas vorhanden -> nichts wird geändert");
            } else {
              console.log("aktuell gesetzte persona ist nicht in den gelisteten personas vorhanden -> ersetze diese durch die erste");
              ctx.patchState({persona: items[0]});
            }
            ctx.dispatch(new PersonaActions.PersonaChanged(ctx.getState().persona.id));
            return;
          }

          if (ctx.getState().persona == null && action.selectFirst) {
            console.log("keine aktuell gesetzte persona und selectFirst gewünscht - füge die erste hinzu");
            ctx.patchState({persona: items[0]});
            ctx.dispatch(new PersonaActions.PersonaChanged(ctx.getState().persona.id));
            return;
          }

        }
      )
    )
  }

  //-----------------
  //Edit Persona
  //-----------------
  @Action(PersonaActions.Edit)
  edit(ctx: StateContext<PersonaStateModel>, action: PersonaActions.Edit) {
    ctx.patchState({
      state: 'edit',
      editPersona: action.persona,
      editForm: {
        ...ctx.getState().editForm,
        model: {
          id: action.persona.id,
          firstname: action.persona.firstname,
          lastname: action.persona.lastname
        }
      }
    });
  }

  @Action(PersonaActions.SubmitEdit)
  submitEdit(ctx: StateContext<PersonaStateModel>) {
    const state = ctx.getState();
    const model = state.editForm.model;
    return this.api.update(state.editPersona.id, {firstname: model.firstname, name: model.lastname}).pipe(
      tap(
        () => {
          this.messageService.showSuccess("Profil wurde aktualisiert.")
          ctx.dispatch(new ResetForm({path: 'persona.editForm'}));
          ctx.patchState({state: 'list'});
        },
        () => {
          this.messageService.showError("Fehler beim aktualisieren.")
        }
      )
    )
  }

  @Action(PersonaActions.CancelEdit)
  cancelEdit(ctx: StateContext<PersonaStateModel>) {
    ctx.dispatch(new ResetForm({path: 'persona.editForm'}));
    ctx.patchState({state: 'list'});
  }

  //-----------------
  //Add Persona
  //-----------------
  @Action(PersonaActions.Add)
  add(ctx: StateContext<PersonaStateModel>) {
    ctx.dispatch(new ResetForm({path: 'persona.editForm'}));
    ctx.patchState({state: 'add'});
  }

  @Action(PersonaActions.CancelAdd)
  cancelAdd(ctx: StateContext<PersonaStateModel>) {
    ctx.dispatch(new ResetForm({path: 'persona.editForm'}));
    ctx.patchState({state: 'list'});
  }

  @Action(PersonaActions.SubmitAdd)
  submitAdd(ctx: StateContext<PersonaStateModel>) {
    const state = ctx.getState();
    const model = state.editForm.model;
    return this.api.add({firstname: model.firstname, name: model.lastname}).pipe(
      tap(
        () => {
          this.messageService.showSuccess("Profil wurde erstellt.")
          ctx.dispatch(new ResetForm({path: 'persona.editForm'}));
          ctx.patchState({state: 'list'});
        },
        () => {
          this.messageService.showError("Fehler beim erstellen des Profil.")
        }
      )
    )
  }

  @Action(PersonaActions.Delete)
  delete(ctx: StateContext<PersonaStateModel>, action: PersonaActions.Delete) {
    return this.api.delete(action.persona.id).pipe(
      tap(
        () => {
          this.messageService.showSuccess("Persona wurde gelöscht.")
          ctx.patchState({state: 'list'});
          ctx.dispatch(new PersonaActions.LoadAll());
        },
        (error) => {
          this.messageService.showError(error.getDisplayMessage());
        }
      )
    )
  }

  @Action(PersonaActions.PersonaSelected)
  personaSelected(ctx: StateContext<PersonaStateModel>, action: PersonaActions.PersonaSelected) {
    ctx.patchState({persona: action.persona});
    ctx.dispatch(new PersonaActions.PersonaChanged(action.persona.id));
  }

  @Action(PersonaActions.PersonaIdSelected)
  personaIdSelected(ctx: StateContext<PersonaStateModel>, action: PersonaActions.PersonaIdSelected) {
    return this.api.loadAll().pipe(
      tap(
        personas => {
          ctx.patchState({personas: personas});

          //persona in den personas suchen und wenn gefunden dann setzen
          const searchedPersona = personas.find(it => it.id == action.personaId);
          if (searchedPersona) {
            ctx.patchState({persona: searchedPersona});
            ctx.dispatch(new PersonaActions.PersonaChanged(searchedPersona.id));
          }
        }
      )
    )
  }


}
