import {Moment} from "moment";
import * as moment from 'moment';
import {Covid} from "./covid";

export interface IParticipant {
  email: string;
  name: string;
  subscribedOn: Moment;
  userId: string
}

export class Participant {
  email: string;
  name: string;
  subscribedOn: Moment;
  userId: string;
  note?: string;
  covid?: Covid;
  presence: "UNKNOWN" | "PRESENT" | "ABSENT";


  public static fromModel(model: IParticipant): Participant {
    return Object.assign(new Participant(), model);
  }

  public static fromJson(data: any): Participant {
    let result = new Participant();
    result.email = data.email;
    result.name = data.name;
    result.subscribedOn = moment(data.subscribedOn);
    result.userId = data.userId;
    result.note = data.note;

    if (data.covid)
      result.covid = Covid.fromJson(data.covid);
    result.presence = data.presence;

    return result;
  }
}
