import * as moment from "moment";
import {Moment} from "moment";

export class Covid {
  state: "VACCINATED_3RD" | "VACCINATED_2ND" | "RECOVERED" | null;
  valid: string;
  warning: string;
  date: Moment | null;

  get stateStr(): string {
    if (this.valid == null)
      return "unbekannt";

    if (this.valid == "WARNING") {
      switch (this.warning) {
        case "vaccination.date.too_young":
          return "Impfdatum zu jung";
        case "recovery.date.too_old":
          return "Genesendatum zu alt";
        case "test.date.too_old":
          return "Testdatum zu alt";
        case "test.necessary":
          return "Test wird benötigt";
        default:
          return this.warning;
      }
    }

    switch (this.state) {
      case "VACCINATED_2ND":
        return "geimpft";
      case "RECOVERED":
        return "genesen"
      default:
        return "unbekannt";
    }
  }

  static fromJson(data: any): Covid {
    let result = new Covid();

    result.state = data.state;
    result.valid = data.valid;
    if (data.date)
      result.date = moment(data.date);
    if (data.warning)
      result.warning = data.warning;

    return result;
  }
}
