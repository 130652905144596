import {Component, OnDestroy, OnInit} from '@angular/core';
import {DetailDialogComponent} from "../detail-dialog/detail-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {Store} from "@ngxs/store";
import {ActivatedRoute} from "@angular/router";
import {AppointmentActions} from "../state/appointment.actions";
import {AppointmentState} from "../state/appointment.state";
import {Observable, Subscription} from "rxjs";
import {filter} from "rxjs/internal/operators";
import {Wod} from "../model/wod";

@Component({
  selector: 'app-direkt-link',
  templateUrl: './direkt-link.component.html',
  styleUrls: ['./direkt-link.component.scss']
})
export class DirektLinkComponent implements OnInit, OnDestroy {
  private readonly id: string;
  private sub1: Subscription;
  appointment$: Observable<Wod>;

  constructor(private dialog: MatDialog, private store: Store, private route: ActivatedRoute) {
    this.id = this.route.snapshot.paramMap.get('id');

    this.appointment$ = this.store.select(AppointmentState.currentAppointment).pipe(
      filter(it => it != undefined)
    );
  }

  ngOnInit(): void {
    this.store.dispatch(new AppointmentActions.DirectLinkToAppointment(this.id));
    this.dialog.open(DetailDialogComponent, { autoFocus: false, width: "100vw", maxWidth: '1024px', height:  "100vh"});
  }

  ngOnDestroy(): void {
    this.sub1?.unsubscribe();
  }
}
