<div class="section">
  <div class="header">Password setzen</div>
  <div class="body">
    <form [formGroup]="formGroup" autocomplete="off" novalidate>
      <div>
        <mat-form-field>
          <mat-label>neues Passwort</mat-label>
          <input id="new-password-1" type="password" matInput formControlName="new_password_1">
        </mat-form-field>

        <mat-form-field>
          <mat-label>neues Passwort (Wiederholung)</mat-label>
          <input id="new-password-2" type="password" matInput formControlName="new_password_2">
        </mat-form-field>
      </div>
      <div class="actions">
        <button id="submit" mat-stroked-button color="primary" (click)="send()" [disabled]="!this.formGroup.valid">
          Passwort setzen
        </button>
      </div>
    </form>
  </div>
</div>
