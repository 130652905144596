import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Store} from "@ngrx/store";
import {CustomerState} from "../../actions/customer.reducer";
import {CustomerActions} from "../../actions/action-types";
import {Subscription} from "rxjs";
import {nofiticationConfiguration} from "../../actions/customer.selectors";

@Component({
  selector: 'app-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.scss']
})
export class NotificationItemComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() description: string;
  @Input() key: string;
  mail: boolean = false;
  private sub: Subscription;

  constructor(private store: Store<CustomerState>) {
  }

  ngOnInit(): void {
    this.sub = this.store.select(nofiticationConfiguration)
      .subscribe(notifications => {
        let notification = notifications.find(it => it.key == this.key)
        this.mail = notification?.mail
      });
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }


  change() {
    this.store.dispatch(CustomerActions.changeNotificationSetting({key: this.key, mail: this.mail, telegram: false}))
  }
}
