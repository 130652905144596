<div class="item" routerLink="layout">
  <div class="icon"><mat-icon>palette</mat-icon></div>
  <div class="title">Darstellung</div>
  <div class="description">Anpassungen an der Darstellung der App</div>
</div>
<div class="item" routerLink="notifications">
  <div class="icon"><mat-icon>notifications</mat-icon></div>
  <div class="title">Benachrichtigungen</div>
  <div class="description">(De)aktivieren von E-Mails</div>
</div>
<div class="item" routerLink="change-email">
  <div class="icon"><mat-icon>email</mat-icon></div>
  <div class="title">E-Mail-Adresse</div>
  <div class="description">E-Mail-Adresse ändern</div>
</div>
<div class="item" routerLink="change-name">
  <div class="icon"><mat-icon>badge</mat-icon></div>
  <div class="title">Name</div>
  <div class="description">Name ändern</div>
</div>
<div class="item" routerLink="contract">
  <div class="icon"><mat-icon>payment</mat-icon></div>
  <div class="title">Vertrag / Guthaben</div>
  <div class="description">Übersicht über Deinen Vertrag und Dein Prepaidguthaben</div>
</div>
<div class="item" routerLink="ical-feed">
  <div class="icon"><mat-icon>event</mat-icon></div>
  <div class="title">Kalenderintegration</div>
  <div class="description">Url für Deinen persönlichen elektronischen Kalender im ICAL-Format</div>
</div>
<div class="item" routerLink="change-password">
  <div class="icon"><mat-icon>vpn_key</mat-icon></div>
  <div class="title">Passwort</div>
  <div class="description">Passwort ändern</div>
</div>
<div class="item" routerLink="remove-account">
  <div class="icon"><mat-icon>delete_forever</mat-icon></div>
  <div class="title">Konto löschen</div>
  <div class="description">Hier kannst Du Dein Konto löschen.</div>
</div>
