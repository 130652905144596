import {ApplicationRef, Injectable} from '@angular/core';
import {SwUpdate} from "@angular/service-worker";
import {first} from "rxjs/operators";
import {concat, interval} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CheckForUpdateService {
  constructor(private appRef: ApplicationRef, private updates: SwUpdate) {
  }

  init() {
    this.initUpdateHandler();
    this.initUpdateChecker();
  }

  private initUpdateHandler() {
    if (this.updates.isEnabled) {
      console.log(new Date(), "update handler enabled");

      this.updates.available.subscribe(() => {
        console.log(new Date(), "update available");
        window.location.reload();
        // if(confirm("New version available. Load New Version?")) {
        //   window.location.reload();
        // }
      });
    }
  }

  private initUpdateChecker() {
    if (this.updates.isEnabled) {
      let checkInterval = 5 * 60 * 1000;
      console.log(new Date(), "checking all ", checkInterval / 1000, "s for updates.");
      // Allow the app to stabilize first, before starting polling for updates with `interval()`.
      const appIsStable$ = this.appRef.isStable.pipe(first(isStable => {console.log(new Date(), "app is stable: ", isStable); return isStable === true}));
      const everySixHours$ = interval( checkInterval);
      const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);
      everySixHoursOnceAppIsStable$.subscribe(() => {
        console.log(new Date(), "check for updates");
        this.updates.checkForUpdate()
      });
    }
  }
}
