<div class="section">
  <div class="header">Password ändern</div>
  <div class="body">
    <form [formGroup]="form" autocomplete="off" novalidate>
      <div>
        <mat-form-field appearance="outline">
          <mat-label>neues Passwort</mat-label>
          <input id="password" matInput formControlName="password" [type]="hide ? 'password' : 'text'">
          <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div class="actions">
        <button id="submit" mat-stroked-button (click)="submit()" [disabled]="!this.form.valid">Passwort ändern</button>
      </div>
    </form>
  </div>
</div>
