<div class="title">ICAL-Feed</div>

<div class="description">Für die nahtlose Übernahme gebuchter Termine in Deinen Kalender, stellen
  wir Dir einen ICAL-Feed zur Verfügung. Diesen Feed kannst Du in fast jeder üblichen Kalendersoftware
  abonnieren. Danach wird jeder von Dir über Zeitfrei gebuchte Termin automatisch in Deinem Kalender angezeigt.
</div>

<div class="hint">
  Bitte beachte, dass es sich um Deinen persönlichen Kalender, mit all deinen Zeitfrei-Buchungen handelt,
  den Du nicht öffentlich zugänglich machen solltest.
</div>

<ng-container *ngIf="link$ | async as link">
  <div class="link">{{link}}</div>

  <div class="action">
    <button mat-stroked-button (click)="reset()">neuen Link erzeugen</button>
    <button mat-stroked-button color="primary" (click)="copy(link)">Link kopieren</button>
  </div>

</ng-container>
