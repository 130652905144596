import {Component, OnDestroy} from '@angular/core';
import {combineLatest, Observable, Subject} from "rxjs";
import {CustomerApi, PersonaState} from "./api";
import {PersonaModuleApi} from "../../persona/persona-module-api";
import * as moment from 'moment';
import {UserApi} from "../../services/user-api.service";
import {ServiceProvider} from "../../services/service-provider";
import {Session} from "../../services/session";
import {FormControl} from "@angular/forms";
import {takeUntil, tap} from "rxjs/operators";


@Component({
  selector: 'app-contract',
  templateUrl: './contract.component.html',
  styleUrls: ['./contract.component.scss']
})
export class ContractComponent implements OnDestroy {
  public personaState$: Observable<PersonaState>;
  serviceProviders$: Observable<ServiceProvider[]>;

  public spField: FormControl;
  private onDestroy$ = new Subject();

  constructor(private api: CustomerApi, private userApi: UserApi, private personaModuleApi: PersonaModuleApi, session: Session) {
    this.spField = new FormControl();

    const serviceProviderChanges$ = this.spField.valueChanges;
    const personaChanges$ = this.personaModuleApi.currentId();

    personaChanges$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(personaId => {
      this.reloadServiceProviders(session, personaId);
    });

    combineLatest([serviceProviderChanges$, personaChanges$]).pipe(
      takeUntil(this.onDestroy$)
    ).subscribe( ([spIdentifier, personaId]) =>
      this.reloadState(spIdentifier, personaId)
    );
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private reloadServiceProviders(session: Session, personaId: string) {
    if (personaId == undefined)
      return;

    this.serviceProviders$ = this.userApi.getRegisterdServiceProviders(personaId).pipe(
      tap(spList => {
        const sp = spList.find(sp => sp.identifier === session.serviceProviderIdentifier);
        if (sp) {
          this.spField.setValue(sp.identifier)
        }
      })
    )
  }

  private reloadState(spIdentifier: string, personaId: string) {
    if (personaId == undefined)
      return;

    if (spIdentifier == undefined)
      return;

    this.personaState$ = this.api.getPersonaState(personaId, spIdentifier);
  }

  contractType(contractType: string, units: number): string {
    switch (contractType) {
      case null:
        return 'kein Vertrag';
      case 'FLATRATE':
        return 'Flatrate';
      case 'CONTINGENT_WEEKLY':
        return units + ' Einheiten pro Woche';
      case 'CONTINGENT_MONTHLY':
        return units + ' Einheiten pro Monat';
      default:
        return contractType;
    }
  }

  contractUntil(contractUntil: string) {
    if (contractUntil == '' || contractUntil == null)
      return '';

    return moment(contractUntil).format("DD.MM.YYYY");
  }
}
