import {Component, OnInit} from '@angular/core';
import {CustomerState} from "../actions/customer.reducer";
import {Store} from "@ngrx/store";
import {loadNotificationSettings} from "../actions/customer.actions";

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  constructor(private store: Store<CustomerState>) {
    this.store.dispatch(loadNotificationSettings())
    // this.store.select(nofiticationConfiguration).subscribe(it => console.log(it));
  }

  ngOnInit(): void {
  }

}
