import {Injectable} from '@angular/core';
import {Api} from "./state/api";
import {Router} from "@angular/router";
import {PersonaModuleApi} from "../persona/persona-module-api";
import {Store} from "@ngxs/store";
import {ServiceProvider} from "../services/service-provider";
import {AppointmentActions} from "./state/appointment.actions";

@Injectable()
export class AppointmentModuleApi {
  constructor(private api: Api, private personaApi: PersonaModuleApi, private store: Store, private router: Router) {
  }

  showAppointment(id: string) {
    this.api.getAppointment(id).toPromise().then(
      appointment => this.router.navigate(["/appointments/detail", appointment.uid]).then()
    )
  }

  serviceProviderChanged(serviceProvider: ServiceProvider) {
    this.store.dispatch(new AppointmentActions.ServiceProviderChanged(serviceProvider))
  }
}
