import {Component, OnDestroy} from '@angular/core';
import {Subscription} from "rxjs";
import {CustomerModuleApi} from "../customer-module-api";

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnDestroy {
  appointmentLayout: string;
  private sub: Subscription;

  constructor(private api: CustomerModuleApi) {
    this.sub = this.api.getAppointmentLayout$().subscribe(it => this.appointmentLayout = it);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  appointmentLayoutChanged() {
    this.api.setAppointmentLayout(this.appointmentLayout);
  }
}
