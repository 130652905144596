  <div class="noData text-align-center">keine Termine gefunden</div>


  <button mat-stroked-button
          *ngIf="(hasNextAppointment$ | async) === true"
          (click)="jumpToNextAppointment()"
          class="next-appointment-link text-align-center" >
    Zum nächsten Termin
  </button>

  <div
    *ngIf="(hasNextAppointment$ | async) === false && (loadingNextAppointment$ | async) === false"
    class="text-align-center">
    Leider gibt es keine Termine in der Zukunft
  </div>
