import {Component, OnInit} from '@angular/core';
import {FormBuilder} from "@angular/forms";
import {UserApi} from "../../../services/user-api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ServiceProvider} from "../../../services/service-provider";
import * as Sentry from "@sentry/browser";
import {BehaviorSubject} from "rxjs";
import {Userstate} from "../../../services/invitation-infos";
import {UserAttribute} from "../../model/user-attribute";
import {RegistrationData} from "./model/registration-data";
import {MessageService} from "../../../shared/message.service";
import {ApiError} from "../../../services/error";
import {CustomerModuleApi} from "../../customer-module-api";
import {HttpResponse} from "@angular/common/http";

@Component({
  selector: 'app-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.scss']
})
export class ConfirmEmailComponent implements OnInit {
  private spIdentifier: string;
  private token: string;
  serviceProvider: ServiceProvider;
  userAttributes: UserAttribute[];

  /**
   * "invalid_link", "formular", "request", "attributes", "error"
   */
  status$: BehaviorSubject<string> = new BehaviorSubject("");
  isRegisterForServiceProvider = true;
  userExists = false;

  constructor(private fb: FormBuilder, private userApi: UserApi,
              private _route: ActivatedRoute, private _router: Router,
              private messageService: MessageService, private customerModuleApi: CustomerModuleApi) {
  }

  ngOnInit(): void {
    if (this.extractLinkParams()) {
      this.loadInvitationInfos();
    }
  }

  private extractLinkParams(): boolean {
    let result = true;

    this.token = this._route.snapshot.paramMap.get("token")
    if (!this.token) {
      result = false;
    }

    if (result == false) {
      this.sendSentryEvent("invalid email confirmation link");
      this.status$.next('invalid_link');
    }

    return result;
  }

  private loadInvitationInfos() {
    this.userApi.getInvitationInfos(this.token)
      .subscribe(
        invitationInfo => {
          this.spIdentifier = invitationInfo.serviceProvider
          this.userAttributes = invitationInfo.attributes;
          this.userExists = invitationInfo.userState == Userstate.Existing;
          if (this.userExists && this.userAttributes.length == 0) {
            this.activate();
          } else
            this.loadServiceProvider();
        },
        () => {
          this.status$.next("invalid_link")
        }
      );
  }

  private loadServiceProvider() {
    if (this.spIdentifier == undefined) {
      this.isRegisterForServiceProvider = false;
      this.status$.next('formular')
      return;
    }

    this.isRegisterForServiceProvider = true;
    this.userApi.getServiceProvider(this.spIdentifier)
      .subscribe(
        serviveProvider => {
          this.serviceProvider = serviveProvider
          this.status$.next('formular')
        },
        () => {
          this.status$.next("invalid_link")
        }
      );
  }

  activate(registrationData?: RegistrationData) {
    this.userApi
      .activateUser(this.token, registrationData)
      .subscribe(
        (response) => {
          this.handleSuccesfulActivation(response);
        },
        (error: ApiError) => {
          this.messageService.showError(error.getDisplayMessage());
        }
      )
  }

  private handleSuccesfulActivation(response: HttpResponse<any>) {
    this.customerModuleApi.login(response.headers.get("X-Auth-Token"));
  }

  sendSentryEvent(message: string) {
    Sentry.setExtra("params", this._route.snapshot.params);
    Sentry.setExtra("queryParams", this._route.snapshot.queryParams);
    Sentry.captureException(new Error(`message: ${message}`));
  }

}
