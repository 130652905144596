import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {ServiceProvider} from "../services/service-provider";
import {Router} from "@angular/router";
import {ServiceProviderModuleApi} from "../service-provider/service-provider-module-api";

@Component({
  selector: 'app-service-provider-detail',
  templateUrl: './service-provider-detail.component.html',
  styleUrls: ['./service-provider-detail.component.scss']
})
export class ServiceProviderDetailComponent implements OnInit {
  public serviceProvider: ServiceProvider;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {serviceProvider: ServiceProvider },
              private router: Router,
              private serviceProviderModuleApi: ServiceProviderModuleApi) {
    this.serviceProvider = data.serviceProvider;
  }

  ngOnInit(): void {
  }

  goToTimeslots(serviceProvider: ServiceProvider) {
    this.serviceProviderModuleApi.setServiceProvider(serviceProvider);
    this.router.navigate(["/wods"]);

  }
}
