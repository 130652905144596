import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NewsRoutingModule} from "./news-routing.module";
import { HomeComponent } from './home/home.component';
import { StoreModule } from '@ngrx/store';
import * as fromStore from './store/store.reducer';
import { EffectsModule } from '@ngrx/effects';
import { StoreEffects } from './store/store.effects';
import {NewsApi} from "./news.api";
import { NewsItemComponent } from './news-item/news-item.component';
import {MomentModule} from "ngx-moment";

@NgModule({
  declarations: [HomeComponent, NewsItemComponent],
  imports: [
    CommonModule,
    NewsRoutingModule,
    StoreModule.forFeature(fromStore.storeFeatureKey, fromStore.reducer),
    EffectsModule.forFeature([StoreEffects]),
    MomentModule
  ],
  providers: [
    NewsApi,
  ]
})
export class NewsModule { }
