import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {Wod} from '../../model/wod';
import {AppointmentState} from '../../state/appointment.state';
import {Store} from '@ngxs/store';
import {AppointmentActions} from '../../state/appointment.actions';
import ReloadAppointmentsForSelectedDate = AppointmentActions.ReloadAppointmentsForSelectedDate;
import NextDaySelected = AppointmentActions.NextDaySelected;
import PrevDaySelected = AppointmentActions.PrevDaySelected;
import {map} from "rxjs/internal/operators";

@Component({
  selector: 'app-day-list',
  templateUrl: './day-list.component.html',
  styleUrls: ['./day-list.component.scss']
})
export class DayListComponent implements OnInit, OnDestroy {
  timeslots$: Observable<Wod[]>;
  loading$: Observable<boolean>;
  hasNextAppointment$: Observable<boolean>;
  private subscriptions: Subscription[] = [];

  constructor(private store: Store) {
    this.loading$ = this.store.select(AppointmentState.loading);

    this.subscriptions.push(
      this.store.select(AppointmentState.persona).subscribe(it =>
        this.timeslots$ = this.store.select(AppointmentState.filteredAppointments).pipe(map(filterFn => filterFn(it)))
      )
    );

    this.hasNextAppointment$ = this.store.select(AppointmentState.hasNextAppointment);
  }

  ngOnInit(): void {
    this.store.dispatch(new ReloadAppointmentsForSelectedDate());
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(it => it.unsubscribe());
  }


  swipeDayForward() {
    this.store.dispatch(new NextDaySelected());
  }

  swipeDayBackward() {
    this.store.dispatch(new PrevDaySelected());
  }

}
