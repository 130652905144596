<div class="section" *ngIf="(status$ | async) == 'formular'">
  <div class="header">Einladung</div>
  <div class="body">
    <p>Du wurdest von <b>{{this.serviceProvider.name}}</b> eingeladen um mit Hilfe von Zeitfrei Deine
      Termine bei {{this.serviceProvider.name}}  buchen zu können.</p>
    <p>Wenn Du noch keinen Account bei Zeitfrei hast, dann gib bitte Deine E-Mailadresse ein,
      damit wir Dich registrieren können.</p>

    <form [formGroup]="registerForm" autocomplete="off" novalidate>
      <div>
        <mat-form-field appearance="outline">
          <mat-label>E-Mail</mat-label>
          <input id="email" matInput formControlName="email">
        </mat-form-field>
      </div>
      <div class="actions">
        <button id="submit" mat-stroked-button color="warn" (click)="register()" [disabled]="!this.registerForm.valid">
          Registrieren
        </button>
      </div>
    </form>
  </div>
</div>

<div class="section" *ngIf="(status$ | async) == 'invalid_link'">
  <div class="header">Einladung</div>
  <div class="body">
    <p>Du hast versucht einen Einladungslink zu öffnen. Leider ist dieser ungültig.</p>
    <p>Bitte prüfe, ob Du den kompletten Link kopiert hast. Wenn ja dann wende Dich bitte an uns,
    damit wir Dir helfen können.</p>
  </div>
</div>

<div class="section" *ngIf="(status$ | async) == 'request'">
  <div class="header">Einladung</div>
  <div class="body">
    <p>Wir registreren jetzt Deine E-Mail-Adresse.</p>
  </div>
</div>

<div class="section" *ngIf="(status$ | async) == 'success'">
  <div class="header">Einladung</div>
  <div class="body">
    <p>Herzlichen Glückwunsch. Deine E-Mail-Adresse wurde registriert. Wir haben Dir eine Email
      gesendet, um sicher zu gehen das wir Dich unter dieser auch erreichen.</p>
    <p>Bitte klicke den Link in der Email an um Dein Konto zu aktivieren.</p>
  </div>
</div>

<div class="section" *ngIf="(status$ | async) == 'error'">
  <div class="header">Einladung</div>
  <div class="body">
    <p>Leider trat beim registrieren Deiner E-Mail-Adresse ein Fehler auf.</p>
    <p>Versuche es doch bitte erneut. Sollte der Fehler weiterhin auftreten so teile uns dies
    bitte mit, damit wir Dir so schnell wie möglich helfen können.</p>
  </div>
</div>
