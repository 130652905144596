import {Action, createReducer, MetaReducer, on} from '@ngrx/store';
import {environment} from '../../environments/environment';
import {ApiActions, AppActions} from "./action-types";


export interface AppState {
  message: string;
}

export const initialState: AppState = {
  message: ''
}

export const appFeatureKey = 'app';
export const _appReducers = createReducer(
  initialState,

  on(ApiActions.changeNameSuccess, (state) => {
    return {...state, message: 'Dein Name wurde erfolgreich geändert.'}
  }),
  on(ApiActions.changeNameError, (state, action) => {
    return {...state, message: 'Beim ändern Deines Namen trat ein Fehler auf. [' + action.error.getDisplayMessage() + ']'}
  }),

  on(AppActions.showMessage, (state, action) => {

    return {...state, message: action.message}
  }),
  on(AppActions.messageDisplayed, (state) => {
    return {...state, message: ''}
  }),
  on(ApiActions.resetIcalFeedSuccess, (state, ) => {
    return {...state, message: 'Der neue Link für Deinen ICal-Feed wurde erzeugt.'}
  }),
  on(ApiActions.resetIcalFeedError, (state, action) => {
    return {...state, message: 'Beim erzeugen des Links für deinen ICal-Feed trat ein Fehler auf ['+action.error.getDisplayMessage()+'].'}
  })
)


export function appReducers(state: AppState | undefined, action: Action) {
  return _appReducers(state, action);
}

export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [] : [];
