import {Observable} from "rxjs";
import {NewsMessage} from "./model/newsMessage";
import {NEWS} from "./news.data";
import {NewsService} from "./news.service";
import {Injectable} from "@angular/core";

@Injectable()
export class NewsApi {
  news = NEWS;

  constructor(private newsService: NewsService) {
  }

  unreadNews() : Observable<NewsMessage[]> {
    return this.newsService.getUnreadMessages();
  }
}
