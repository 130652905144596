import {Component, OnDestroy, OnInit} from "@angular/core";
import {Router} from "@angular/router";

import * as moment from 'moment';
import {BehaviorSubject, Observable, of, Subscription} from "rxjs";
import {catchError, filter, map} from "rxjs/internal/operators";
import {Booking} from "../services/booking";
import {UserApi} from "../services/user-api.service";
import {ApiError} from "../services/error";
import {MatSnackBar} from "@angular/material/snack-bar";
import {AppointmentModuleApi} from "../appointment/appointment-module-api";
import {ServiceProviderModuleApi} from "../service-provider/service-provider-module-api";
import {PersonaModuleApi} from "../persona/persona-module-api";

@Component({
  selector: "app-booking-list",
  styleUrls: ['./booking-list.component.scss'],
  templateUrl: "./booking-list.component.html"
})
export class BookingListComponent implements OnInit, OnDestroy {
  open$: Observable<Booking[]>;
  closed$: Observable<Booking[]>;
  loadedBookings$ = new BehaviorSubject([]);
  bookingSubscription: Subscription;
  personaSubscription: Subscription;
  loading$ = new BehaviorSubject(true);


  constructor(private api: UserApi, private router: Router,
              private _snackBar: MatSnackBar,
              private appointmentModuleApi: AppointmentModuleApi,
              private serviceProviderModuleApi: ServiceProviderModuleApi,
              private personaModuleApi: PersonaModuleApi) {
  }

  ngOnInit(): void {
    this.loadBookings();
    this.personaSubscription = this.personaModuleApi.currentId().subscribe(it => this.reloadBookings(it));
  }

  ngOnDestroy(): void {
    if (this.bookingSubscription != null)
      this.bookingSubscription.unsubscribe();

    this.personaSubscription?.unsubscribe();
  }

  reloadBookings(personaId: string) {
    this.loading$.next(true);
    if (this.bookingSubscription != null) this.bookingSubscription.unsubscribe();
    this.bookingSubscription = this.api.getBookings(personaId).subscribe(
      bookings => {
        this.loading$.next(false);
        this.loadedBookings$.next(bookings)
      },
      (error: ApiError) => {
        this.loading$.next(false);
        this.onApiError(error)
      }
    );
  }

  loadBookings() {
    this.open$ =
      this.loadedBookings$
        .pipe(
          catchError(error => {
            this.onApiError(error);
            return of(error)
          }),
          filter(([data, date]) => !(data instanceof ApiError)),
          map((bookings: Booking[]) => bookings.filter(booking => booking.start.unix() > moment().unix())),
          map(bookings => bookings.sort((a, b) => a.start.unix() - b.start.unix())),
        )
    this.closed$ =
      this.loadedBookings$
        .pipe(
          catchError(error => {
            this.onApiError(error);
            return of(error)
          }),
          filter(([data, date]) => !(data instanceof ApiError)),
          map((bookings: Booking[]) => bookings.filter(booking => booking.start.unix() < moment().unix())),
          map(bookings => bookings.sort((a, b) => b.start.unix() - a.start.unix())),
        )
  }

  private onApiError(error: ApiError) {
    this._snackBar.open(error.getDisplayMessage(), "OK", {duration: 8000});
  }

  show(booking: Booking) {
    this.serviceProviderModuleApi.getServiceProvider(booking.serviceProviderIdentifier).toPromise().then(
      sp => {
        this.serviceProviderModuleApi.setServiceProvider(sp).then(
          () => this.appointmentModuleApi.showAppointment(booking.workoutId)
        )
      }
    )
  }
}
