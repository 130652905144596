<ng-container *ngIf="timeslot$ | async as timeslot">
  <ng-container *ngIf="personaId$ | async as personaId">
    <ng-container *ngIf="!timeslot.isSubscriptionDisabled(personaId)">
      <div class="detail-section">
        <div class="line">
          <div class="label">buchbar ab</div>
          <div class="value" [ngClass]="{value: true, 'inactive': !timeslot.isSubscriptionSlotStarted(personaId)}">
            <ng-container *ngIf="timeslot.subscriptionOpeningAt(personaId) != null">
              <span class="date">{{timeslot.subscriptionOpeningAt(personaId) | amDateFormat: 'dd, D. MMM YY'}}</span>
              <span class="time">{{timeslot.subscriptionOpeningAt(personaId) | amDateFormat: 'HH:mm'}}</span>
            </ng-container>
          </div>
        </div>

        <div class="line">
          <div class="label">buchbar bis</div>
          <div [ngClass]="{value: true, 'inactive': timeslot.isSubscriptionSlotClosed(personaId)}">
            <span class="date">{{timeslot.subscriptionClosingAt(personaId) | amDateFormat: 'dd, D. MMM YY'}}</span>
            <span class="time">{{timeslot.subscriptionClosingAt(personaId) | amDateFormat: 'HH:mm'}}</span>
          </div>
      </div>

      <div class="line">
        <div class="label">stornierbar bis</div>
        <div [ngClass]="{value: true, 'inactive': timeslot.isUnsubscriptionSlotClosed(personaId)}">
          <span class="date">{{timeslot.unsubscriptionClosingAt(personaId) | amDateFormat: 'dd, D. MMM YY'}}</span>
          <span class="time">{{timeslot.unsubscriptionClosingAt(personaId) | amDateFormat: 'HH:mm'}}</span>
        </div>
      </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
