<ng-container *ngFor="let item of items$ | async">
  <div class="item">
    <div class="name">{{item.firstname}} {{item.lastname}}</div>
    <div class="actions">
      <button mat-stroked-button (click)="delete(item)">Löschen</button>
      <button mat-stroked-button (click)="edit(item)" color="accent">Bearbeiten</button>
    </div>
  </div>
</ng-container>


<!--<button mat-raised-button (click)="add()" class="add">Persona hinzufügen</button>-->
