import {Component} from '@angular/core';
import {UserApi} from "../../../services/user-api.service";
import {Observable} from "rxjs";
import {MessageService} from "../../../shared/message.service";
import {ApiError} from "../../../services/error";
import {Router} from "@angular/router";

@Component({
  selector: 'app-update-password-page',
  templateUrl: './update-password-page.component.html',
  styleUrls: ['./update-password-page.component.scss']
})
export class UpdatePasswordPageComponent {
  hasPassword$: Observable<boolean>;

  constructor(private api: UserApi, private messageService: MessageService, private router: Router) {
    this.hasPassword$ = api.hasPassword();
  }

  changePassword(event: { oldPassword: string; newPassword: string }) {
    this.api.changePassword(event.oldPassword, event.newPassword).subscribe(
      () => {
        this.messageService.showSuccess("Dein Passwort wurde geändert.")
        this.router.navigateByUrl("/customer/settings");
      },
      (error: ApiError) => {
        this.messageService.showError("Beim ändern des Passwort's trat ein Fehler auf: " + error.getDisplayMessage())
      }
    )
  }

  setPassword(newPassword: string) {
    this.api.setPassword(newPassword).subscribe(
      () => {
        this.messageService.showSuccess("Dein Passwort wurde gesetzt.")
        this.router.navigateByUrl("/customer/settings");
      },
      (error: ApiError) => {
        this.messageService.showError("Beim setzen des Passwort's trat ein Fehler auf: " + error.getDisplayMessage())
      }
    )
  }
}
