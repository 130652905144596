<div class="participants">
    <table mat-table [dataSource]="items">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element" [class.multiline]="element.note">
          {{element.name}}
          <div class="note" *ngIf="element.note">{{element.note}}</div>
        </td>
      </ng-container>

      <ng-container matColumnDef="covid">
        <th mat-header-cell *matHeaderCellDef>COVID</th>
        <td mat-cell *matCellDef="let element">
          <mat-icon *ngIf="element.covid.valid == 'VALID'" class="valid">done</mat-icon>
          <mat-icon *ngIf="element.covid.valid == 'WARNING'" class="warning">warning</mat-icon>
          <mat-icon *ngIf="element.covid.valid == null" class="error">error</mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="presence">
        <th mat-header-cell *matHeaderCellDef>anwesend</th>
        <td mat-cell *matCellDef="let element">
          <mat-icon *ngIf="element.presence == 'PRESENT'" class="valid">done</mat-icon>
          <mat-icon *ngIf="element.presence == 'ABSENT'" class="error">highlight_off</mat-icon>
          <mat-icon *ngIf="element.presence == 'UNKNOWN'" class="error">help_outline</mat-icon>
        </td>
      </ng-container>

      <ng-container *ngIf="containsCovid()">
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      </ng-container>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="participantSelected.emit(row)"></tr>
    </table>
</div>
