<ng-container *ngIf="personas$ | async as personas">
  <mat-nav-list>
    <ng-container *ngFor="let persona of personas">
      <div class="item" (click)="select(persona, $event)" matRipple >
        <div class="icon">{{signature(persona)}}</div>
        <div class="name">{{persona.firstname}} {{persona.lastname}}</div>
      </div>
      <mat-divider></mat-divider>
    </ng-container>

    <a routerLink="/persona" (click)="adminPersonas()" mat-list-item>
      <mat-icon matListIcon>people</mat-icon>
      <span mat-line>Personas verwalten</span>
    </a>
  </mat-nav-list>
</ng-container>

