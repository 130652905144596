import {Component, EventEmitter, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {repeatPasswordValidator} from "../../repeat-password-listener";

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss']
})
export class SetPasswordComponent {
  formGroup: FormGroup;
  @Output() onSubmit = new EventEmitter<string>();

  constructor(fb: FormBuilder) {
    this.formGroup = fb.group({
      new_password_1: ['', [Validators.required]],
      new_password_2: ['', [Validators.required]],
    }, {validators: repeatPasswordValidator})
  }

  send() {
    this.onSubmit.emit(this.formGroup.get('new_password_1').value)
  }
}
