<div class="list-item mat-elevation-z5">
  <div class="name">{{bookingItem.name}}  </div>
  <div class="sp">{{bookingItem.serviceProviderName}}</div>

  <div class="detail-section">
    <div class="line" *ngIf="bookingItem.canceled">
      <div class="line"></div>
      <div class="value cancel-info">Abgesagt</div>
    </div>

    <div class="line">
      <div class="label">Start</div>
      <div class="value" [class.canceled]="bookingItem.canceled">
        <span class="date">{{bookingItem.start | amDateFormat: 'dd, D. MMM YY'}}</span>
        <span class="time">{{bookingItem.start | amDateFormat: 'HH:mm'}}</span>
      </div>
    </div>

    <div class="line">
      <div class="label">Ende</div>
      <div class="value" [class.canceled]="bookingItem.canceled">
        <span class="date">{{bookingItem.end | amDateFormat: 'dd, D. MMM YY'}}</span>
        <span class="time">{{bookingItem.end | amDateFormat: 'HH:mm'}}</span>
      </div>
    </div>
  </div>

  <div class="detail-section-no-border">
    <div class="line" [class.secondary]="bookingItem.hasBookingCode()" *ngIf="bookingItem.hasBookingReference()">
      <div class="label">Buchungsreferenz</div>
      <div class="value">{{bookingItem.bookingReference}}</div>
    </div>

    <div class="line" *ngIf="bookingItem.hasBookingCode() && bookingItem.canShowBookingCode()">
      <div class="label">Buchungscode</div>
      <div class="value">{{bookingItem.bookingCode}}</div>
    </div>
  </div>

  <div class="action">
    <button mat-stroked-button color="primary" (click)="show.emit()">Anzeigen</button>
  </div>
</div>
