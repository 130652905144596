import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ServiceProvider} from "../services/service-provider";
import {MatDialog} from "@angular/material/dialog";
import {ServiceProviderDetailComponent} from "../service-provider-detail/service-provider-detail.component";

@Component({
  selector: 'app-service-provider-item',
  templateUrl: './service-provider-item.component.html',
  styleUrls: ['./service-provider-item.component.scss']
})
export class ServiceProviderItemComponent implements OnInit {
  @Input() serviceProvider: ServiceProvider;
  @Input() member: boolean = false;
  @Input() public: boolean = false;
  @Output() selected =  new EventEmitter<ServiceProvider>();

  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
  }

  select(serviceProvider: ServiceProvider) {
    this.selected.emit(serviceProvider);
  }

  showDetails(serviceProvider: ServiceProvider) {
    this.dialog.open(ServiceProviderDetailComponent, {data: {serviceProvider: serviceProvider}});
  }
}
