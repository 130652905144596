<ng-container *ngIf="!readonly">
<mat-form-field>
  <mat-label>{{attribute.name}}</mat-label>
  <mat-select [formControl]="control" [required]="attribute.mandatory">
    <mat-option *ngFor="let opt of attribute.values" [value]="opt">{{opt}}</mat-option>
  </mat-select>
</mat-form-field>
</ng-container>

<ng-container *ngIf="readonly">
  <div class="line">
    <div class="label">{{attribute.name}}</div>
    <div class="value">{{attribute.value}}</div>
  </div>
</ng-container>
