<h2 mat-dialog-title>{{serviceProvider.name}}</h2>
<mat-dialog-content class="mat-typography">
  <div class="adress">
    <div>{{serviceProvider.address.line1}}</div>
    <div>{{serviceProvider.address.zip}} {{serviceProvider.address.town}}</div>
  </div>

  <mat-divider></mat-divider>

  <div class="contact">
    <div *ngIf="serviceProvider.phone?.readable">
      <a href="tel:{{serviceProvider.phone.normalized}}">{{serviceProvider.phone.readable}}</a>
    </div>

    <div *ngIf="serviceProvider.mail">
      <a href="mailto:{{serviceProvider.mail}}">{{serviceProvider.mail}}</a>
    </div>

    <div *ngIf="serviceProvider.website">
      <a href="{{serviceProvider.website}}">{{serviceProvider.website}}</a>
    </div>
  </div>

</mat-dialog-content>
<mat-dialog-actions>
  <button mat-stroked-button [mat-dialog-close]="true">Schliessen</button>
  <button mat-stroked-button color="warn" [mat-dialog-close]="true" (click)="goToTimeslots(serviceProvider)" cdkFocusInitial>Termine</button>
</mat-dialog-actions>
