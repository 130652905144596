import { Component, OnInit } from '@angular/core';
import {Store} from "@ngxs/store";
import {AppointmentState} from "../state/appointment.state";
import {Section} from "../model/section";
import {Observable} from "rxjs";
import {AppointmentActions} from "../state/appointment.actions";
import SectionChanged = AppointmentActions.SectionChanged;

@Component({
  selector: 'app-section-list',
  templateUrl: './section-list.component.html',
  styleUrls: ['./section-list.component.scss']
})
export class SectionListComponent {
  sections$: Observable<Section[]>;

  constructor(private store: Store) {
    this.sections$ = this.store.select(AppointmentState.sections);
  }

  selectSection(section: Section) {
    this.store.dispatch(new SectionChanged(section.id));
  }
}
