import {Component, Input} from '@angular/core';
import {Wod} from "../../model/wod";
import {MatDialog} from "@angular/material/dialog";
import {DetailDialogComponent} from "../../detail-dialog/detail-dialog.component";
import {AppointmentActions} from "../../state/appointment.actions";
import AppointmentSelected = AppointmentActions.AppointmentSelected;
import {Observable} from "rxjs";
import {PersonaModuleApi} from "../../../persona/persona-module-api";
import {Store} from "@ngxs/store";

@Component({
  selector: 'app-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss']
})
export class ListItemComponent {
  @Input("timeslot") timeslot: Wod;
  personaId$: Observable<string | undefined>;

  constructor(private dialog: MatDialog, private store: Store, private personaModuleApi: PersonaModuleApi) {
    this.personaId$ = this.personaModuleApi.currentId();
  }

  getIconClass(wod: Wod, personaId: string) {
    if (wod.informationalOnly)
      return "wod-icon-information";

    if (wod.isConfirmed(personaId))
      return "wod-icon-subscribed";

    if (wod.isFull())
      return "wod-icon-full";

    if (wod.hasParticipants())
      return "wod-icon-partial";

    return "wod-icon-empty";
  }

  getIcon() {
    return "fiber_manual_record";
  }

  clicked() {
    this.store.dispatch(new AppointmentSelected(this.timeslot.id));
    this.dialog.open(DetailDialogComponent, { autoFocus: false, width: "100vw", maxWidth: '1024px', height:  "100vh"});
  }
}
