<div class="main">
  <app-top-menu id="header"></app-top-menu>

  <app-message-display></app-message-display>

  <div id="content">
    <router-outlet></router-outlet>
  </div>

  <app-bottom-menu></app-bottom-menu>
</div>
