<h1 mat-dialog-title>Buchungsdetails</h1>
<div mat-dialog-content [formGroup]="form">
  <p>Um diesen Termin für Dich zu buchen, benötigen wir noch ein paar Angaben von Dir?</p>
  <ng-container *ngIf="data.isSubscriptionPersonsSupported">
        <mat-label>Personen (max {{data.maxAllowedPersons}})</mat-label>
        <mat-slider
          [min]="1"
          [max]="data.maxAllowedPersons"
          [step]="1"
          [thumbLabel]="true"
          [tickInterval]="1"
          formControlName="personen"
          [vertical]="false">
        </mat-slider>
  </ng-container>
  <booking-detail-attributes></booking-detail-attributes>
</div>
<div mat-dialog-actions>
  <button mat-stroked-button (click)="onNoClick()">Abbrechen</button>
  <button mat-stroked-button color="primary" (click)="onOkClick()" cdkFocusInitial [disabled]="form.invalid">Buchen</button>
</div>

