<ng-container *ngIf="(timeslot$ | async).attachments.length > 0">
  <div *ngFor="let attachment of (timeslot$ | async).attachments" class="item">
    <span class="filename" (click)="download(attachment)">{{attachment.name}}</span>
    <button mat-icon-button [matMenuTriggerFor]="itemMenu" [matMenuTriggerData]="{item: attachment}" tabindex="-1">
      <mat-icon>more_horiz</mat-icon>
    </button>
  </div>

  <mat-menu #itemMenu="matMenu">
    <ng-template matMenuContent let-item="item">
      <button mat-menu-item (click)="download(item)">
        <mat-icon>download</mat-icon>
        <span>Herunterladen</span></button>
    </ng-template>
  </mat-menu>
</ng-container>
