import {Injectable} from '@angular/core';
import {ToastrService} from "ngx-toastr";

@Injectable({providedIn: 'root'})
export class MessageService {

  constructor(private toastr: ToastrService) { }

  public showError(msg: string) {
    if (msg == null || msg == "")
      return;

    this.toastr.error(msg, '', {timeOut: 20*1000});
  }

  public showSuccess(msg: string) {
    if (msg == null || msg == "")
      return;

    this.toastr.success(msg);
  }

  public showInfo(msg: string) {
    if (msg == null || msg == "")
      return;

    this.toastr.info(msg, 'Hinweis', {timeOut: 20 * 1000});
  }
}
