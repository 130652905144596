<p>Um Deinen Konto zu aktivieren musst Du nur noch ein Passwort festlegen.
</p>

<form [formGroup]="form" autocomplete="off" novalidate>
  <div>
    <mat-form-field appearance="outline">
      <mat-label>Password</mat-label>
      <input matInput formControlName="password" [type]="hide ? 'password' : 'text'">
      <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="hide">
        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <div>
    <mat-checkbox formControlName="user_agreement">Ich akzeptiere die <a
      href="/src/assets/legal/nutzungsvereinbarung.pdf" target="_blank">Nutzungsvereinbarung</a>.
    </mat-checkbox>
  </div>
  <div class="actions">
    <button mat-stroked-button color="warn" (click)="activateNewUser()"
            [disabled]="!this.form.valid">
      Aktivieren
    </button>
  </div>
</form>
