<ng-container *ngIf="!readonly">
  <div [formGroup]="form">
    <mat-checkbox [formControlName]="attribute.identifier" [required]="attribute.mandatory">{{attribute.name}}
    </mat-checkbox>
  </div>
</ng-container>

<ng-container *ngIf="readonly">
  <div class="line">
    <div class="label">{{attribute.name}}</div>
<!--    <div class="value">{{attribute.value}}</div>-->
  </div>
</ng-container>
