import {Component} from '@angular/core';
import {Api} from "../state/api";
import {timer} from "rxjs";
import {Wod} from "../model/wod";
import {DetailDialogComponent} from "../detail-dialog/detail-dialog.component";
import {AppointmentActions} from "../state/appointment.actions";
import {MatDialog} from "@angular/material/dialog";
import {Store} from "@ngxs/store";
import {animate, state, style, transition, trigger} from "@angular/animations";
import AppointmentSelected = AppointmentActions.AppointmentSelected;

@Component({
  selector: 'app-teaser',
  templateUrl: './teaser.component.html',
  styleUrls: ['./teaser.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state('show', style({
        // height: '200px',
        opacity: 1,
      })),
      state('hide', style({
        // height: '200px',
        opacity: 0,
      })),
      transition('hide => show', [
        animate('1s')
      ]),
      transition('show => hide', [
        animate('1s')
      ])
    ])]
})
export class TeaserComponent {
  timeslots: Wod[];
  timeslot?: Wod;
  index: number = -1;
  state: string = 'show';

  constructor(private api: Api, private dialog: MatDialog, private store: Store) {
    this.api.getPromotedTimeslots().subscribe(it => {
      this.timeslots = it;
      if (this.timeslots.length > 0) {
        this.index = 0;
        this.timeslot = this.timeslots[this.index];
      }
    });
  }

  hasNext(): boolean {
    return this.timeslots.length > this.index + 1;
  }

  hasPrev(): boolean {
    return this.index > 0;
  }

  next(): void {
    this.changeTo(this.index = this.index + 1);
    // this.state = 'hide';
    // timer(1000).subscribe(() => {
    //   this.index++;
    //   this.timeslot = this.timeslots[this.index];
    //   this.state = 'show'
    // })
  }

  prev(): void {
    this.changeTo(this.index = this.index - 1);
    // this.state = 'hide';
    // timer(1000).subscribe(() => {
    //   this.index--;
    //   this.timeslot = this.timeslots[this.index];
    //   this.state = 'show'
    // })
  }

  changeTo(index) : void {
    this.state = 'hide';
    timer(1000).subscribe(() => {
      this.index = index;
      this.timeslot = this.timeslots[this.index];
      this.state = 'show'
    })
  }

  showTimeslot() {
    this.store.dispatch(new AppointmentSelected(this.timeslot.id));
    this.dialog.open(DetailDialogComponent, {autoFocus: false, width: "100vw", maxWidth: '1024px', height: "100vh"});
  }
}
