import { Component, OnInit } from '@angular/core';
import {Store} from "@ngxs/store";
import {PersonaState} from "../state/persona.state";
import {Observable} from "rxjs";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  state$: Observable<String>;

  constructor(private store: Store) {
    this.state$ = this.store.select(PersonaState.state);
  }

  ngOnInit(): void {
  }

}
