import {Moment} from "moment";

export class NewsMessage {
  constructor(
    public id: number,
    public from: Moment,
    public subject: string,
    public body: string,
    public route: string = null,
  ) {}
}
