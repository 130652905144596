import {Component, OnDestroy, OnInit} from '@angular/core';
import * as moment from 'moment';
import {Store} from "@ngxs/store";
import {AppointmentActions} from "../../state/appointment.actions";
import DaySelected = AppointmentActions.DaySelected;
import {AppointmentState} from "../../state/appointment.state";
import {Observable, Subscription} from "rxjs";
import NextWeekSelected = AppointmentActions.NextWeekSelected;
import PrevWeekSelected = AppointmentActions.PrevWeekSelected;
import {MatDialog} from "@angular/material/dialog";
import {DaySelectorComponent} from "../../day-selector/day-selector.component";
import {Location} from '@angular/common';
import {tap} from "rxjs/operators";
import {ActivatedRoute} from "@angular/router";
import LoadAppointmentsCountPerWeek = AppointmentActions.LoadAppointmentsCountPerWeek;

class Day {
  constructor(public date: moment.Moment) {
  }

  get label() {
    return this.date.format("D.M.");
  }
}

@Component({
  selector: 'app-day-bar',
  templateUrl: './day-bar.component.html',
  styleUrls: ['./day-bar.component.scss']
})
export class DayBarComponent implements OnInit, OnDestroy {
  days: Day[] = [];
  selectedDay$: Observable<moment.Moment>;
  appointmentPerDays: Map<string, number>;
  weekNumber: number;
  private subscriptions: Subscription[] = [];

  constructor(private store: Store, private dialog: MatDialog, location: Location, private route: ActivatedRoute) {
    this.selectedDay$ = this.store.select(AppointmentState.selectedDay).pipe(
      tap(day => {
        location.replaceState(`appointments/?day=${day.format("YYYY-MM-DD")}`);
      })
    );

    this.subscriptions.push(
      this.store.select(AppointmentState.selectedDay).subscribe(
        dayOld => {
          let day = moment(dayOld.toISOString())
          if (day.week() != this.weekNumber) {
            this.store.dispatch(new LoadAppointmentsCountPerWeek());
          }
          this.weekNumber = day.week()
          this.days = [];

          this.days.push(new Day(day.clone().weekday(0)));
          this.days.push(new Day(day.clone().weekday(1)));
          this.days.push(new Day(day.clone().weekday(2)));
          this.days.push(new Day(day.clone().weekday(3)));
          this.days.push(new Day(day.clone().weekday(4)));
          this.days.push(new Day(day.clone().weekday(5)));
          this.days.push(new Day(day.clone().weekday(6)));
        }
      ));

    this.subscriptions.push(
      this.route.queryParams.subscribe(params => {
        if (params['day'])
          this.store.dispatch(new DaySelected(moment(params['day'])))
      }));

    this.subscriptions.push(
      this.store.select(AppointmentState.appointmentsPerDay).subscribe(
        dayMap => {
          this.appointmentPerDays = new Map<string, number>();
          Object.keys(dayMap).forEach(key =>
            this.appointmentPerDays.set(key, dayMap[key])
          );
        }
      ));

  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(it => it.unsubscribe());
  }

  onDayClicked(day: Day) {
    this.store.dispatch(new DaySelected(day.date));
  }

  nextWeek() {
    this.store.dispatch(new NextWeekSelected());
  }

  prevWeek() {
    this.store.dispatch(new PrevWeekSelected());
  }

  showCalendar() {
    this.dialog.open(DaySelectorComponent);
  }

  jumpToToday() {
    this.store.dispatch(new DaySelected(moment()));
  }
}
