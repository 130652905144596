<div class="week">
  <mat-icon (click)="prevWeek()">first_page</mat-icon>
  <div class="day">
    <div class="label" (click)="jumpToToday()">{{selectedDay$ | async | amDateFormat: 'D.M.Y'}}</div>
    <div (click)="showCalendar()"><mat-icon>event</mat-icon></div>
  </div>
  <mat-icon (click)="nextWeek()">last_page</mat-icon>
</div>

<div class="days">
  <ng-container *ngIf="selectedDay$ | async as selectedDay">
    <div *ngFor="let day of days" (click)="onDayClicked(day)"
         [class.current]="day.date.startOf('day').isSame(selectedDay.startOf('day'))">
      <span class="dayname">{{day.date | amDateFormat:'dd'}}, </span>
      {{day.date | amDateFormat:'D.M.'}}
      <span class="appointmentsCount"
            *ngIf="appointmentPerDays.get(day.date | amDateFormat:'YYYY-MM-DD') > 0">
        {{appointmentPerDays.get(day.date | amDateFormat:'YYYY-MM-DD')}}
      </span>
    </div>
  </ng-container>
</div>
