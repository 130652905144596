import {Component} from "@angular/core";
import {UserApi} from "../services/user-api.service";
import {Session} from "../services/session";
import {ApiError} from "../services/error";
import {Router} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {filter, map} from "rxjs/operators";
import {AppActions} from "../actions/action-types";
import {AppState} from "../actions";
import {Store} from "@ngrx/store";
import {MessageService} from "../shared/message.service";
import {PersonaModuleApi} from "../persona/persona-module-api";
import {CustomerModuleApi} from "../customer/customer-module-api";
import {HttpResponse} from "@angular/common/http";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  loginForm: FormGroup;
  errorMessage: string;
  hide = true;

  private loginData: { email: string, password: string };

  constructor(fb: FormBuilder, private _router: Router, private session: Session, private api: UserApi,
              private messageService: MessageService, private store: Store<AppState>, private personaModuleApi: PersonaModuleApi,
              private customerModuleApi: CustomerModuleApi) {
    this.loginForm = fb.group(
      {
        email: ['', [Validators.required]],
        password: ['', [Validators.required]]
      }
    )

    this.loginForm.valueChanges
      .pipe(
        filter(() => this.loginForm.valid),
        map(value => {
          return {email: value.email, password: value.password}
        }),
      )
      .subscribe(
        login => {
          this.loginData = login;
        }
      );
  }

  submit() {
    this.store.dispatch(AppActions.logout());
    this.login();
  }

  login() {
    this.api.login(this.loginData.email, this.loginData.password)
      .subscribe(
        response => {
          this.onSuccess(response);
        },
        (error: ApiError) => {
          this.onFailure(error);
        }
      );
  }

  onSuccess(response: HttpResponse<any>) {
    this.customerModuleApi.login(response.headers.get("X-Auth-Token"));
  }

  onFailure(error: ApiError) {
    this.store.dispatch(AppActions.logout());
    this.messageService.showError(error.getDisplayMessage());
  }

  gotoRegister() {
    this._router.navigate(["/register"]).then()
  }
}

