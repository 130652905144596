import {Component, OnDestroy} from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {Wod} from "../../model/wod";
import {Store} from "@ngxs/store";
import {AppointmentState} from "../../state/appointment.state";
import {DomSanitizer} from "@angular/platform-browser";
import {ServiceProviderModuleApi} from "../../../service-provider/service-provider-module-api";
import {AppointmentDetailActions} from "../../state/appointment-detail.actions";
import {tap} from "rxjs/operators";
import {MatDialog} from "@angular/material/dialog";
import {BookingDetailDialogComponent} from "../../booking-detail-dialog/booking-detail-dialog.component";
import AppointmentChanged = AppointmentDetailActions.AppointmentChanged;

@Component({
  selector: 'appointment-detail-action-bar',
  templateUrl: './action-bar.component.html',
  styleUrls: ['./action-bar.component.scss']
})
export class ActionBarComponent implements OnDestroy {
  appointment$: Observable<Wod>;
  personaId$: Observable<string>;
  working: boolean;
  private subscriptions: Subscription[] = []
  private timeslot: Wod;

  constructor(private store: Store, private sanitizer: DomSanitizer, private serviceProviderModuleApi: ServiceProviderModuleApi, private bookingDetailDialog: MatDialog) {
    this.appointment$ = this.store.select(AppointmentState.currentAppointment).pipe(
      tap(it => this.timeslot = it)
    )
    this.personaId$ = this.store.select(AppointmentState.persona);
    this.subscriptions.push(
      this.store.select(AppointmentState.detailWorking).subscribe(it => this.working = it)
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(it => it.unsubscribe());
  }

  subscribe(wod: Wod, personaId: string) {
    if (this.timeslot.needsBookingDetails(personaId)) {
      let dialogRef = this.bookingDetailDialog.open(BookingDetailDialogComponent,
        {
          autoFocus: false,
          data: {
            isSubscriptionPersonsSupported: this.timeslot.isSubscriptionPersonsSupported(),
            personen: 1,
            maxAllowedPersons: Math.min(6, this.timeslot.maxMembers - this.timeslot.numberOfMembers)
          }
        }
      );

      dialogRef.componentInstance.onOkClicked.subscribe(
        result => {
          this.store.dispatch(new AppointmentDetailActions.Subscribe(result.personen)).subscribe(
            () => {
              this.store.dispatch(new AppointmentChanged(wod.id));
              dialogRef.close()
            }
          )
        }
      )
    } else {
      this.store.dispatch(new AppointmentDetailActions.Subscribe(1)).toPromise().then(
        () => this.store.dispatch(new AppointmentChanged(wod.id))
      )
    }
  }

  unsubscribe(wod: Wod) {
    this.store.dispatch(new AppointmentDetailActions.Unsubscribe()).toPromise().then(
      () => this.store.dispatch(new AppointmentChanged(wod.id))
    )
  }

  subscribeToWaitingList(wod: Wod) {
    this.store.dispatch(new AppointmentDetailActions.SubscribeToWaitingList()).toPromise().then(
      () => this.store.dispatch(new AppointmentChanged(wod.id))
    )
  }

  unsubscribeFromWaitingList(wod: Wod) {
    this.store.dispatch(new AppointmentDetailActions.UnsubscribeFromWaitingList()).toPromise().then(
      () => this.store.dispatch(new AppointmentChanged(wod.id))
    )
  }

  getIosCalendarLink(wod: Wod) {
    return this.sanitizer.bypassSecurityTrustUrl(wod.getIosCalendarLink());
  }

  isWaitingListSupported() {
    return this.serviceProviderModuleApi.isWaitingListSupported();
  }
}
