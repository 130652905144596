import { Component, OnInit } from '@angular/core';
import {Observable} from "rxjs";
import {Persona} from "../../persona/model/persona";
import {Store} from "@ngxs/store";
import {PersonaModuleApi} from "../../persona/persona-module-api";

@Component({
  selector: 'top-menu-persona',
  templateUrl: './persona.component.html',
  styleUrls: ['./persona.component.scss']
})
export class PersonaComponent implements OnInit {
  public personas$: Observable<Persona[]>;
  public persona$: Observable<Persona>;

  constructor(private store: Store, private personaModuleApi: PersonaModuleApi) {
    this.personas$ = this.personaModuleApi.personas$();
    this.persona$ = this.personaModuleApi.currentPersona();
  }

  ngOnInit(): void {
  }

  selectPersona() {
    this.personaModuleApi.displayPersonaSelectionDialog();
  }

  signature(persona: Persona) {
    return (persona.firstname[0]?.toUpperCase()??'') + "" + (persona.lastname[0]?.toUpperCase()??'')
  }
}
