import { Component, OnInit } from '@angular/core';
import {Store} from "@ngxs/store";
import {FormControl, FormGroup} from "@angular/forms";
import {PersonaActions} from "../state/persona.actions";

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponent implements OnInit {
  addForm = new FormGroup({
    firstname: new FormControl(),
    lastname: new FormControl()
  });

  constructor(private store: Store) { }

  ngOnInit(): void {
  }

  update() {
    this.store.dispatch(new PersonaActions.SubmitAdd())
  }

  cancel() {
    this.store.dispatch(new PersonaActions.CancelAdd())
  }
}
