import { Component } from '@angular/core';
import {combineLatest, Observable} from "rxjs";
import {Wod} from "../../model/wod";
import {Store} from "@ngxs/store";
import {AppointmentState} from "../../state/appointment.state";
import {TimeslotAttribute} from "../../model/timeslot_attribute";
import {map} from "rxjs/internal/operators";

@Component({
  selector: 'booking-detail-attributes',
  templateUrl: './booking-detail-attributes.component.html',
  styleUrls: ['./booking-detail-attributes.component.scss']
})
export class BookingDetailAttributesComponent {
  public timeslot$: Observable<Wod>;
  personaId$: Observable<string | undefined>;
  attributes$: Observable<TimeslotAttribute[]>;
  disabled = false;
  subscribed = false;

  constructor(private store: Store) {
    this.timeslot$ = this.store.select(AppointmentState.currentAppointment);
    this.personaId$ = this.store.select(AppointmentState.persona);

    this.attributes$ = combineLatest([this.timeslot$, this.personaId$]).pipe(
      map(([timeslot, personaId]) => {
        return timeslot.attributes(personaId);
      })
    )
  }

  getAttributeId(index : number, attribute : TimeslotAttribute) {
    return attribute.id;
  }
}
