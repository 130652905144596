import {Component, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {Clipboard} from '@angular/cdk/clipboard';
import {Store} from "@ngrx/store";
import {showMessage} from "../../actions/app.actions";
import {filter, map} from "rxjs/operators";
import {loadFeeds, resetIcalFeed} from "../actions/customer.actions";
import {feeds} from "../actions/customer.selectors";
import {ConfirmDialogComponent, ConfirmDialogModel} from "../../shared/confirm-dialog/confirm-dialog.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-ical-feed',
  templateUrl: './ical-feed.component.html',
  styleUrls: ['./ical-feed.component.scss']
})
export class IcalFeedComponent implements OnInit {
  link$: Observable<string>;

  constructor(private clipboard: Clipboard, private store: Store, private dialog: MatDialog) {
    this.link$ = this.store.select(feeds).pipe(
      filter(feeds => feeds != null),
      map(feeds => feeds.ical)
    );
  }

  ngOnInit(): void {
    this.store.dispatch(loadFeeds());
  }

  copy(link: string) {
    this.clipboard.copy(link);
    this.store.dispatch(showMessage({message: 'Der Link für Deinen Kalender wurde in die Zwischenablage kopiert.'}))
  }

  public reset() {
    const dialogData = new ConfirmDialogModel(
      "Hinweis",
      "Wenn Du einen neuen Link erzeugst, verliert der alte seine Gültigkeit.\n" +
      "Willst Du wirklich einen neuen Link erzeugen?"
    );
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult == false)
        return;

      this.store.dispatch(resetIcalFeed());
    });
  }

}
