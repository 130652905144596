<div class="dayview-container">
  <app-day-bar></app-day-bar>
  <app-filter-bar></app-filter-bar>
  <app-day-list></app-day-list>
</div>

<div class="refresh" *ngIf="(loading$ | async) == false" (click)="reloadAppointments()">
  aktualisieren
</div>
<div class="loading" *ngIf="(loading$ | async) == true"> Lade Daten</div>
