<div class="toolbar">
  <div class="item" matRipple [class.active]="(section$|async) == 'sp'" (click)="goto('/service-provider-select')">
      <mat-icon>store</mat-icon>
      <div class="label">Anbieter</div>
  </div>
  <div class="item" matRipple [class.active]="(section$|async) == 'ts'" (click)="goto('/ts')">
      <mat-icon>schedule</mat-icon>
    <div class="label">{{ 'TOP_MENU.WORKOUTS' | translate }}</div>
  </div>
  <div class="item" matRipple [class.active]="(section$|async) == 'bookings'" (click)="goto('/bookings')">
      <mat-icon>playlist_add_check</mat-icon>
    <div class="label">Buchungen</div>
  </div>
</div>
