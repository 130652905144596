<ng-container *ngIf="appointment$ | async as appointment">
  <ng-container *ngIf="personaId$ | async as personaId">
    <div class="detail-section" *ngIf="appointment.isFree(personaId)">
      <div class="line">
        <div class="label">Hinweis</div>
        <div class="value">
          Diese Veranstaltung ist <b>kostenfrei</b>. Wenn Du einen Vertrag mit Kontingent hast oder über Guthaben verfügst,
          wird durch die Teilnahme an dieser Veranstaltung weder Dein Kontingent noch Dein Guthaben belastet.
        </div>
      </div>
    </div>
    <div class="detail-section-no-border" *ngIf="appointment.needsConfirmation(personaId) && appointment.isNotSubscribed(personaId)">
      <div class="line">
        <div class="label">Hinweis</div>
        <div>Wir vermerken Deine Anmeldung in unserem System. Diese muss dann von einem Verantwortlichen bestätigt werden.</div>
      </div>
    </div>
    <div class="detail-section" *ngIf="appointment.informationalOnly">
      <div class="line">
        <div class="label">Hinweis</div>
        <div class="value">
          Dies ist eine Veranstaltung, bei der keine Buchungen möglichen sind.
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
