export interface IVideoLink {
  url: string;
  title: string;
  description: string;
  //VISIBLE, HIDDEN
  state: string;
}

export class VideoLink {
  url: string;
  title: string;
  description: string;
  state: string;

  public static fromModel(model: IVideoLink): VideoLink {
    return Object.assign(new VideoLink(), model);
  }

  public static fromJson(data: any): VideoLink {
    if (!data)
      return;

    let result = new VideoLink();
    result.state = data.state;
    result.url = data.url;
    result.title = data.title;
    result.description = data.desc;
    return result;
  }

  isEmpty() {
    return !(this.url || this.title || this.description);
  }

  isVisible() {
    return this.state == 'VISIBLE';
  }

  isHidden() {
    return this.state == 'HIDDEN';
  }
}
