import { Component, OnInit } from '@angular/core';
import {FormControl} from "@angular/forms";
import {Observable} from "rxjs";
import {AppointmentState} from "../../state/appointment.state";
import {Store} from "@ngxs/store";
import {AppointmentActions} from "../../state/appointment.actions";
import FilterChanged = AppointmentActions.FilterChanged;
import LoadResources = AppointmentActions.LoadResources;
import {map} from "rxjs/internal/operators";
import LoadSections = AppointmentActions.LoadSections;

@Component({
  selector: 'app-filter-bar',
  templateUrl: './filter-bar.component.html',
  styleUrls: ['./filter-bar.component.scss']
})
export class FilterBarComponent implements OnInit {
  filter = new FormControl();
  filterList: { value: string, label: string }[] = [
    {value: '', label: 'alle'},
    {value: 'bookable', label: 'buchbare (inkl. Warteliste)'}, //inkl. Warteliste
    {value: 'free', label: 'buchbare freie'},
    {value: 'booked', label: 'gebuchte'}
  ];

  resource = new FormControl();
  resources$: Observable<{ id: string; label: string, displayname: string }[]>;

  section = new FormControl();
  sections$: Observable<any>;

  constructor(private store: Store) {
    this.resources$ = this.store.select(AppointmentState.resources);
    this.sections$ = this.store.select(AppointmentState.sections);

    this.resource.setValue('');
    this.resource.valueChanges.subscribe(() => this.store.dispatch(new FilterChanged(this.filter.value, this.resource.value, this.section.value)));

    this.filter.setValue('');
    this.filter.valueChanges.subscribe(() => this.store.dispatch(new FilterChanged(this.filter.value, this.resource.value, this.section.value)));

    this.section.setValue(this.store.selectSnapshot(AppointmentState.selectedSection));
    this.section.valueChanges.subscribe(() => this.store.dispatch(new FilterChanged(this.filter.value, this.resource.value, this.section.value)));

    // this.store.dispatch(new FilterChanged('', '', ));
  }

  ngOnInit(): void {
    this.store.dispatch(new LoadResources());
    this.store.dispatch(new LoadSections());
  }

  get hasResources$() : Observable<boolean> {
    return this.resources$.pipe(map(it => it.length > 1));
  }

  get hasSections$() : Observable<boolean> {
    return this.sections$.pipe(map(it => it.length > 1));
  }
}
