import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {AppointmentRoutingModule} from './appointment-routing.module';
import {MomentModule} from "ngx-moment";
import {DetailDialogComponent} from './detail-dialog/detail-dialog.component';
import {WodListComponent} from "./list/wod-list.component";
import {ListItemComponent} from "./component/list-item/list-item.component";
import {AppMaterialModule} from "../material.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgxsModule} from "@ngxs/store";
import {AppointmentState} from "./state/appointment.state";
import {Api} from "./state/api";
import {DayViewComponent} from './container/day-view/day-view.component';
import {DayBarComponent} from './component/day-bar/day-bar.component';
import {FilterBarComponent} from './component/filter-bar/filter-bar.component';
import {DaySelectorComponent} from './day-selector/day-selector.component';
import {DayListComponent} from './container/day-list/day-list.component';
import {HomeComponent} from './home/home.component';
import {ParticipantsComponent} from './detail-dialog/participants/participants.component';
import {ActionBarComponent} from './detail-dialog/action-bar/action-bar.component';
import {TimeframesComponent} from './detail-dialog/timeframes/timeframes.component';
import {SubscriptionConditionHintComponent} from './detail-dialog/subscription-condition-hint/subscription-condition-hint.component';
import {VideoComponent} from './detail-dialog/video/video.component';
import {NameAndTimeComponent} from './detail-dialog/top-bar/name-and-time.component';
import {DataComponent} from './detail-dialog/data/data.component';
import {BookingDetailAttributesComponent} from './booking-detail-dialog/attributes/booking-detail-attributes.component';
import {EnumComponent} from './booking-detail-dialog/attributes/list/enum.component';
import {StringComponent} from './booking-detail-dialog/attributes/string/string.component';
import {BooleanComponent} from './booking-detail-dialog/attributes/boolean/boolean.component';
import {DateComponent} from './booking-detail-dialog/attributes/date/date.component';
import {TranslateModule} from "@ngx-translate/core";
import {DirektLinkComponent} from './direkt-link/direkt-link.component';
import {AppointmentModuleApi} from "./appointment-module-api";
import {NoDataComponent} from './no-data/no-data.component';
import {DataV1Component} from './detail-dialog/data/data-v1/data-v1.component';
import {DataV2Component} from './detail-dialog/data/data-v2/data-v2.component';
import {AllocationComponent} from './detail-dialog/allocation/allocation.component';
import {ShareComponent} from './detail-dialog/share/share.component';
import {PersonaComponent} from './detail-dialog/persona/persona.component';
import {AttachmentsComponent} from './detail-dialog/attachments/attachments.component';
import {AgreementComponent} from './booking-detail-dialog/attributes/agreement/agreement.component';
import {ParticipantBottomActionComponent} from './detail-dialog/participant-bottom-action/participant-bottom-action.component';
import {InstructorActionBarComponent} from './detail-dialog/instructor/instructor-action-bar/instructor-action-bar.component';
import {InstructorListComponent} from './detail-dialog/instructor/instructor-list/instructor-list.component';
import {BookingDetailDialogComponent} from './booking-detail-dialog/booking-detail-dialog.component';
import { DetailAttributesComponent } from './detail-dialog/detail-attributes/detail-attributes.component';
import { TeaserComponent } from './teaser/teaser.component';
import { SectionListComponent } from './section-list/section-list.component';

@NgModule({
  declarations: [DetailDialogComponent,
    WodListComponent,
    ListItemComponent,
    DayViewComponent,
    DayBarComponent,
    FilterBarComponent,
    DaySelectorComponent,
    DayListComponent,
    HomeComponent,
    ParticipantsComponent,
    ActionBarComponent,
    TimeframesComponent,
    SubscriptionConditionHintComponent,
    VideoComponent,
    NameAndTimeComponent,
    DataComponent,
    BookingDetailAttributesComponent,
    EnumComponent,
    StringComponent,
    BooleanComponent,
    DateComponent,
    DirektLinkComponent,
    NoDataComponent,
    DataV1Component,
    DataV2Component,
    AllocationComponent,
    ShareComponent,
    PersonaComponent,
    AttachmentsComponent,
    AgreementComponent,
    ParticipantBottomActionComponent,
    InstructorActionBarComponent,
    InstructorListComponent,
    BookingDetailDialogComponent,
    DetailAttributesComponent,
    TeaserComponent,
    SectionListComponent,
  ],
    imports: [
        CommonModule,
        AppointmentRoutingModule,
        AppMaterialModule,
        ReactiveFormsModule,
        MomentModule,
        NgxsModule.forFeature([AppointmentState]),
        TranslateModule,
        FormsModule,
    ],
  providers: [
    Api,
    AppointmentModuleApi
  ]
})
export class AppointmentModule {
}
