import {Component} from '@angular/core';
import {Store} from "@ngxs/store";
import {FormControl, FormGroup} from "@angular/forms";
import {PersonaActions} from "../state/persona.actions";

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent {
  editForm = new FormGroup({
    firstname: new FormControl(),
    lastname: new FormControl()
  });

  constructor(private store: Store) {
  }

  update() {
    this.store.dispatch(new PersonaActions.SubmitEdit())
  }

  cancel() {
    this.store.dispatch(new PersonaActions.CancelEdit())
  }
}
