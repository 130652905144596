export interface IWaitingList {
  subscribed: boolean;
  participants: number;
  canSubscribe: boolean;
  canUnsubscribe: boolean;
  position: number;
}

export class WaitingList implements IWaitingList{
  public subscribed: boolean;
  public participants: number;
  public canSubscribe: boolean;
  public canUnsubscribe: boolean;
  public position: number;

  public static fromModel(model: IWaitingList): WaitingList {
    return Object.assign(new WaitingList(), model)
  }

  public static fromJson(data: any): WaitingList {
    let result = new WaitingList();
    result.subscribed = data.subscribed;
    result.canSubscribe = data.canSubscribe;
    result.canUnsubscribe = data.canUnsubscribe;
    result.participants = data.participants;
    result.position = data?.position;
    return result;
  }

}
