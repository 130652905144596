import { Component, OnInit } from '@angular/core';
import * as Version from "../../environments/version";
import {SwUpdate} from "@angular/service-worker";

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {
  version: string;
  checking: boolean = false;

  constructor(private swUpdate: SwUpdate) { }

  ngOnInit(): void {
    this.version = Version.version;
  }

  checkForUpdate() {
    this.checking = true;
    this.swUpdate.checkForUpdate().then(() => this.checking = false);
  }
}
