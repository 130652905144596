import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ChangeMailComponent} from "./change-mail/change-mail.component";
import {SettingsComponent} from "./settings/settings.component";
import {NotificationsComponent} from "./notifications/notifications.component";
import {IcalFeedComponent} from "./ical-feed/ical-feed.component";
import {ChangeNameComponent} from "./change-name/change-name.component";
import {LayoutComponent} from "./layout/layout.component";
import {ContractComponent} from "./contract/contract.component";
import {RemoveAccountComponent} from "./remove-account/remove-account.component";
import {UpdatePasswordPageComponent} from "./update-password/update-password-page/update-password-page.component";


const routes: Routes = [
  {path: 'settings', component: SettingsComponent, data: {title: 'Einstellungen', back: '/'}},
  {path: 'settings/change-email', component: ChangeMailComponent,data: {title: 'E-Mail-Adresse ändern', back: '/customer/settings'}},
  {path: 'settings/change-name', component: ChangeNameComponent,data: {title: 'Name ändern', back: '/customer/settings'}},
  {path: 'settings/contract', component: ContractComponent,data: {title: 'Verträge / Guthaben', back: '/customer/settings'}},
  {path: 'settings/notifications', component: NotificationsComponent,data: {title: "Benachrichtigungen", back: '/customer/settings'}},
  {path: 'settings/change-password', component: UpdatePasswordPageComponent,data: {title: "Passwort ändern", back: '/customer/settings'}},
  {path: 'settings/ical-feed', component: IcalFeedComponent,data: {title: "Kalender abonnieren", back: '/customer/settings'}},
  {path: 'settings/layout', component: LayoutComponent,data: {title: "Darstellung", back: '/customer/settings'}},
  {path: 'settings/remove-account', component: RemoveAccountComponent,data: {title: "Konto löschen", back: '/customer/settings'}},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CustomerRoutingModule {
}
