import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PersonaRoutingModule } from './persona-routing.module';
import { ListComponent } from './list/list.component';
import {AppMaterialModule} from "../material.module";
import { EditComponent } from './edit/edit.component';
import {NgxsModule} from "@ngxs/store";
import {NgxsFormPluginModule} from "@ngxs/form-plugin";
import {PersonaState} from "./state/persona.state";
import {Api} from "./state/api";
import {ReactiveFormsModule} from "@angular/forms";
import { HomeComponent } from './home/home.component';
import {AddComponent} from "./add/add.component";
import {PersonaModuleApi} from "./persona-module-api";
import { SelectComponent } from './select/select.component';
import {NgxsStoragePluginModule} from "@ngxs/storage-plugin";


@NgModule({
  declarations: [ListComponent, EditComponent, HomeComponent, AddComponent, SelectComponent],
  imports: [
    CommonModule,
    PersonaRoutingModule,
    AppMaterialModule,
    NgxsModule.forFeature([PersonaState]),
    NgxsStoragePluginModule.forRoot({
      key: 'persona.persona'
    }),
    NgxsFormPluginModule,
    ReactiveFormsModule,
  ],
  providers: [
    Api, PersonaModuleApi
  ]
})
export class PersonaModule { }
