import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable, ReplaySubject} from "rxjs";
import {ServiceProvider} from "./service-provider";


@Injectable()
export class Session {
  private static SERVICE_PROVIDER_KEY = "service_provider_identifier";
  private static SERVICE_PROVIDER_NAME = "service_provider_name";
  private static SERVICE_PROVIDER_VARIANT = "service_provider_variant";
  private static DEEPLINK = "deeplink";
  private static CUSTOMER_APPOINTMENT_LAYOUT = "customer_appointment_layout";

  public isAuthenticated$: Observable<boolean>;
  private _isAuthenticated$ = new BehaviorSubject<boolean>(false);
  private _serviceProviderName$ = new BehaviorSubject<string>("");
  private _customerLayout$ = new ReplaySubject<string>();

  constructor() {
    this.setInitialAppointmentsLayout();
    this.isAuthenticated$ = this._isAuthenticated$.pipe();
    this._isAuthenticated$.next(this.hasApiToken());
    this._serviceProviderName$.next(this.getServiceProviderName());
    this.customerLayout$.next(this.getCustomerAppointmentLayout());
  }

  isLoggedIn(): boolean {
    return this.hasApiToken();
  }

  getApiToken(): string {
    let result = localStorage.getItem("api_token");
    if (result == null)
      result = "";

    return result;
  }

  login(token: string) {
    localStorage.setItem("api_token", token);
    this._isAuthenticated$.next(this.hasApiToken());
  }

  logout() {
    localStorage.removeItem("api_token");
    localStorage.removeItem(Session.SERVICE_PROVIDER_NAME);
    localStorage.removeItem(Session.SERVICE_PROVIDER_KEY);
    localStorage.removeItem(Session.SERVICE_PROVIDER_VARIANT);
    localStorage.removeItem(Session.CUSTOMER_APPOINTMENT_LAYOUT);
    this._isAuthenticated$.next(false);
    this._serviceProviderName$.next("");
  }

  private hasApiToken(): boolean {
    let token = this.getApiToken();
    return !!token;
  }

  setServiceProvider(serviceProivder: ServiceProvider) {
    localStorage.setItem(Session.SERVICE_PROVIDER_KEY, serviceProivder.identifier);
    localStorage.setItem(Session.SERVICE_PROVIDER_NAME, serviceProivder.name);
    localStorage.setItem(Session.SERVICE_PROVIDER_VARIANT, serviceProivder.variant);

    this._serviceProviderName$.next(serviceProivder.name);
  }

  get serviceProviderIdentifier() : string {
    let result = localStorage.getItem(Session.SERVICE_PROVIDER_KEY);
    if (result == null)
      result = "";
    return result;
  }

  private getServiceProviderVariant() : string {
    let result = localStorage.getItem(Session.SERVICE_PROVIDER_VARIANT);
    if (result == null)
      result = "";
    return result;
  }

  get serviceProviderName$() : Observable<string> {
    return this._serviceProviderName$.asObservable();
  }

  get serviceProviderVariant() : string {
    let result = localStorage.getItem(Session.SERVICE_PROVIDER_VARIANT);
    if (result == null)
      result = "";
    return result;
  }

  private getServiceProviderName() : string {
    let result = localStorage.getItem(Session.SERVICE_PROVIDER_NAME);
    if (result == null)
      result = "";
    return result;
  }

  hasServiceProdiver(): boolean {
    const item = localStorage.getItem(Session.SERVICE_PROVIDER_KEY);
    return item != null && item != '';
  }

  setDeeplink(url: string) {
    if (url != '/register')
      localStorage.setItem(Session.DEEPLINK, url);
  }

  get deepLink(): string {
    return localStorage.getItem(Session.DEEPLINK);
  }

  hasDeepLink() {
    let result = localStorage.getItem(Session.DEEPLINK);
    return !!result;
  }

  removeDeepLink() {
    localStorage.removeItem(Session.DEEPLINK);
  }

  hasCustomerAppointmentLayout() {
    return localStorage.getItem(Session.CUSTOMER_APPOINTMENT_LAYOUT) !== null;
  }

  setCustomerAppointmentLayout(layout:string) {
    localStorage.setItem(Session.CUSTOMER_APPOINTMENT_LAYOUT, layout);
    this._customerLayout$.next(layout);
  }

  getCustomerAppointmentLayout() {
    //Sicherheitsnetz falls der Eintrag aus versehen im localstorage weg ist
    if (!this.hasCustomerAppointmentLayout()) {
      this.setInitialAppointmentsLayout();
    }

    return localStorage.getItem(Session.CUSTOMER_APPOINTMENT_LAYOUT);
  }

  get customerLayout$()  {
    return this._customerLayout$;
  }

  private setInitialAppointmentsLayout() {
    console.log("set initial app layout: ",this.getServiceProviderVariant())
    if (this.hasCustomerAppointmentLayout())
      return;

    switch (this.getServiceProviderVariant()) {
      case "store":
      case "museum":
        this.setCustomerAppointmentLayout('day');
        break;
      default:
        this.setCustomerAppointmentLayout('list');
    }
  }

}
