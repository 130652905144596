import {Component} from '@angular/core';
import {Observable} from "rxjs";
import {Wod} from "../../model/wod";
import {Store} from "@ngxs/store";
import {AppointmentState} from "../../state/appointment.state";

@Component({
  selector: 'appointment-detail-name-and-time',
  templateUrl: './name-and-time.component.html',
  styleUrls: ['./name-and-time.component.scss']
})
export class NameAndTimeComponent {
  appointment$: Observable<Wod>;
  templateVersion: string;

  constructor(private store: Store) {
    this.appointment$ = this.store.select(AppointmentState.currentAppointment)
    this.templateVersion = this.store.selectSnapshot(AppointmentState.detailDialogVersion);
  }

}
