import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {ServiceProviderGuard} from "../guards/service-provider-guard.service";
import {HomeComponent} from "./home/home.component";
import {DirektLinkComponent} from "./direkt-link/direkt-link.component";


const routes: Routes = [
  {path: "", component: HomeComponent, canActivate: [ServiceProviderGuard]},
  {path: "detail/:id", component: DirektLinkComponent},
  {path: "wods", component: HomeComponent, canActivate: [ServiceProviderGuard]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppointmentRoutingModule {
}
