import {Injectable} from '@angular/core';
import {Session} from '../services/session';
import {TranslateService} from "@ngx-translate/core";
import {UserApi} from "../services/user-api.service";
import {Observable} from "rxjs";
import {ServiceProvider} from "../services/service-provider";

@Injectable()
export class ServiceProviderModuleApi {
  constructor(private session: Session, private translateService: TranslateService, private api: UserApi) {
  }

  public getServiceProvider(identifier: string): Observable<ServiceProvider> {
    return this.api.getServiceProvider(identifier);
  }

  public isWaitingListSupported(): boolean {
    return this.session.serviceProviderVariant != 'museum' && this.session.serviceProviderVariant != 'school';
  }

  public async setServiceProvider(serviceProvider: ServiceProvider) {
    if (serviceProvider == undefined)
      return;

    this.session.setServiceProvider(serviceProvider);
    this.translateService.use(serviceProvider.variant);
  }
}
