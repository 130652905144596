<ng-container *ngIf="!readonly">
<mat-form-field>
  <mat-label>{{attribute.name}}</mat-label>
  <input matInput [formControl]="control" [required]="attribute.mandatory">
</mat-form-field>
</ng-container>

<ng-container *ngIf="readonly">
  <div class="line">
    <div class="label">{{attribute.name}}</div>
    <div class="value">{{attribute.value}}</div>
  </div>
</ng-container>
