import {Component} from '@angular/core';
import {UserApi} from "../services/user-api.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ActivatedRoute, Router} from "@angular/router";
import {ApiError} from "../services/error";
import {AppActions} from "../actions/action-types";
import {AppState} from "../actions";
import {Store} from "@ngrx/store";

@Component({
  selector: 'app-confirm-email-token',
  templateUrl: './confirm-email-change-with-token.component.html',
  styleUrls: ['./confirm-email-change-with-token.component.scss']
})
export class ConfirmEmailChangeWithTokenComponent {
  private readonly token: string;

  constructor(private api: UserApi, private snackBar: MatSnackBar,
              private router: Router, route: ActivatedRoute,
              private store: Store<AppState>) {
    this.token = route.snapshot.paramMap.get('token');
  }

  send() {
    this.api.confirmEmailChange(this.token)
      .subscribe(
        () => {
          this.onSuccess();
        },
        (error: ApiError) => {
          this.onFailure(error);
        }
      );
  }

  onSuccess() {
    this.snackBar.open(`Deine E-Mail-Adresse wurde erfolgreich geändert.`, "OK", {duration: 8000});
    this.store.dispatch(AppActions.logout());
    this.router.navigate(["/login"]);
  }

  onFailure(error: ApiError) {
    this.snackBar.open(error.getDisplayMessage(), "OK", {duration: 8000});
  }
}
